import axios from 'axios';
import {RPP_API_SERVER_URL} from "@/constants/constants";

export async function fetchPartnerNames(creatorId) {
    try {
        const response = await axios.get(`${RPP_API_SERVER_URL}/partner-names`, {
            params: {
                page: 0,
                size: 50
            },
            headers: {
                'x-txn-auth-token': process.env.VUE_APP_RPP_USERS_CLOUD_FUNCTION_API_KEY,
                'uid': creatorId
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching partner names:', error);
        throw error; // Rethrow error to handle it in the component
    }
}