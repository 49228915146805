<template>
  <Breadcrumbs main="rydpay APIs" title="Testing & Simulation"/>

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">


        <!-- CARD START -->
        <div class="card card-absolute">
          <div class="card-header bg-primary">
            <h5 class="text-white">Purpose</h5>
          </div>
          <div class="card-body">
            <ul class="list-group">
              <li class="list-group-item">ryd offers virtual gas stations in STAGING environment, specifically designed for testing purposes.</li>
              <li class="list-group-item">These gas stations are not real and are intended to simulate various scenarios for API integration and testing.</li>
            </ul>
          </div>
        </div>
        <!-- CARD END -->


        <!-- CARD START -->
        <div class="card card-absolute">
          <div class="card-header bg-success">
            <h5 class="text-white">Locations and Types</h5>
          </div>
          <div class="card-body">
            <ul class="list-group">
              <li class="list-group-item">Two types of stations are available: V4 Automatic Noop and V4 Manual Noop, each in different states.</li>
              <li class="list-group-item">All test gas stations are virtually located near Blutenburgstr 18, Munich, Germany.</li>
            </ul>
          </div>
        </div>
        <!-- CARD END -->

        <!-- CARD START -->
        <div class="card card-absolute">
          <div class="card-header bg-primary">
            <h5 class="text-white">Automatic Station Details</h5>
          </div>
          <div class="card-body">
            <ul class="list-group">
              <li class="list-group-item"><b>Automatic Noop Stations</b>: Available in several countries (DE, BE, CH, AT, NL, LU, PT, ES) with respective currencies and tax rates. All pumps are automatic.</li>
            </ul>

            <br/>

            <div class="table-responsive product-table">
              <table class="table table-bordered table-striped">
                <thead class="bg-primary text-white">
                <tr>
                  <th class="bg-primary">POI ID</th>
                  <th class="bg-primary">POI Brand</th>
                  <th class="bg-primary">Country</th>
                  <th class="bg-primary">Currency</th>
                  <th class="bg-primary">Tax Rate</th>
                  <th class="bg-primary">Comments</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>5f7c5d5d09838cf9cbfd3ecf</td>
                  <td>NoOp</td>
                  <td>DE</td>
                  <td>EUR</td>
                  <td>19%</td>
                  <td></td>
                </tr>
                <tr>
                  <td>60c9bfe1f2d000d67561f178</td>
                  <td>BE Automatic Noop</td>
                  <td>BE</td>
                  <td>EUR</td>
                  <td>21%</td>
                  <td></td>
                </tr>
                <tr>
                  <td>60c9bfe0f2d000d67561f172</td>
                  <td>DE Automatic Noop</td>
                  <td>DE</td>
                  <td>EUR</td>
                  <td>19%</td>
                  <td>This station displays MTSK fuel prices</td>
                </tr>
                <tr>
                  <td>60c9bfe1f2d000d67561f174</td>
                  <td>CH Automatic</td>
                  <td>CH</td>
                  <td>CHF</td>
                  <td>8%</td>
                  <td></td>
                </tr>
                <tr>
                  <td>60c9bfe1f2d000d67561f176</td>
                  <td>AT Automatic Noop</td>
                  <td>AT</td>
                  <td>EUR</td>
                  <td>20%</td>
                  <td></td>
                </tr>
                <tr>
                  <td>60c9bfe1f2d000d67561f17a</td>
                  <td>NL Automatic Noop</td>
                  <td>NL</td>
                  <td>EUR</td>
                  <td>21%</td>
                  <td></td>
                </tr>
                <tr>
                  <td>60c9bfe1f2d000d67561f17c</td>
                  <td>LU Automatic Noop</td>
                  <td>LU</td>
                  <td>EUR</td>
                  <td>17%</td>
                  <td></td>
                </tr>
                <tr>
                  <td>60c9bfe1f2d000d67561f17e</td>
                  <td>PT Automatic Noop</td>
                  <td>PT</td>
                  <td>EUR</td>
                  <td>23%</td>
                  <td></td>
                </tr>
                <tr>
                  <td>60c9bfe1f2d000d67561f180</td>
                  <td>ES Automatic Noop</td>
                  <td>ES</td>
                  <td>EUR</td>
                  <td>21%</td>
                  <td></td>
                </tr>

                </tbody>
              </table>
            </div>

          </div>
        </div>
        <!-- CARD END -->

        <!-- CARD START -->
        <div class="card card-absolute">
          <div class="card-header bg-success">
            <h5 class="text-white">Manual Station Details</h5>
          </div>
          <div class="card-body">
            <ul class="list-group">
              <li class="list-group-item"><b>Manual Noop Stations</b>: Also available in multiple countries, including a special 'Manual-never-ready' station in DE. All pumps are manual.</li>
            </ul>

            <br/>

            <div class="table-responsive">
              <table class="table table-bordered">
                <thead class="bg-success text-white">
                <tr>
                  <th class="bg-success">POI ID</th>
                  <th class="bg-success">POI Brand</th>
                  <th class="bg-success">Country</th>
                  <th class="bg-success">Currency</th>
                  <th class="bg-success">Tax Rate</th>
                  <th class="bg-success">Comments</th>
                </tr>
                </thead>
                <tbody>

                <tr>
                  <td>5fa92c325bce72222dcde699</td>
                  <td>DE Manual Noop</td>
                  <td>DE</td>
                  <td>EUR</td>
                  <td>19%</td>
                  <td></td>
                </tr>

                <tr>
                  <td>60c9bfe1f2d000d67561f181</td>
                  <td>DE Manual-never-ready Noop</td>
                  <td>DE</td>
                  <td>EUR</td>
                  <td>19%</td>
                  <td>Gas station that will never have a transaction ready state</td>
                </tr>
                <tr>
                  <td>60c9bfe1f2d000d67561f179</td>
                  <td>BE Manual Noop</td>
                  <td>BE</td>
                  <td>EUR</td>
                  <td>21%</td>
                  <td></td>
                </tr>
                <tr>
                  <td>60c9bfe0f2d000d67561f173</td>
                  <td>DE Manual Noop</td>
                  <td>DE</td>
                  <td>EUR</td>
                  <td>19%</td>
                  <td></td>
                </tr>
                <tr>
                  <td>60c9bfe1f2d000d67561f175</td>
                  <td>CH Manual Noop</td>
                  <td>CH</td>
                  <td>CHF</td>
                  <td>8%</td>
                  <td></td>
                </tr>
                <tr>
                  <td>60c9bfe1f2d000d67561f177</td>
                  <td>AT Manual Noop</td>
                  <td>AT</td>
                  <td>EUR</td>
                  <td>20%</td>
                  <td></td>
                </tr>
                <tr>
                  <td>60c9bfe1f2d000d67561f17b</td>
                  <td>NL Manual Noop</td>
                  <td>NL</td>
                  <td>EUR</td>
                  <td>21%</td>
                  <td></td>
                </tr>
                <tr>
                  <td>60c9bfe1f2d000d67561f17d</td>
                  <td>LU Manual Noop</td>
                  <td>LU</td>
                  <td>EUR</td>
                  <td>17%</td>
                  <td></td>
                </tr>
                <tr>
                  <td>60c9bfe1f2d000d67561f17f</td>
                  <td>PT Manual Noop</td>
                  <td>PT</td>
                  <td>EUR</td>
                  <td>23%</td>
                  <td></td>
                </tr>

                </tbody>
              </table>
            </div>

          </div>
        </div>
        <!-- CARD END -->

        <!-- CARD START -->
        <div class="card card-absolute">
          <div class="card-header bg-primary">
            <h5 class="text-white">Pump Configurations</h5>
          </div>
          <div class="card-body">
            <ul class="list-group">
              <li class="list-group-item"><b>Pump 1 to 11</b>: Each pump is uniquely configured to test different scenarios like displaying fuel types with/without prices, transaction cancellations, response failures, and specific transaction states.</li>
            </ul>

            <br/>

            <div class="table-responsive product-table">
              <table class="table table-bordered table-striped">
                <thead class="bg-primary text-white">
                <tr>
                  <th  class="bg-primary">Pump ID</th>
                  <th  class="bg-primary">Description</th>
                  <th  class="bg-primary">Fuel Types</th>
                  <th class="bg-primary">Prices Displayed</th>
                  <th class="bg-primary">Transaction Behavior</th>
                  <th class="bg-primary">Additional Info</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>Pump_1</td>
                  <td>Offers two different fuel types</td>
                  <td>Super95, DieselVariant1</td>
                  <td>Yes</td>
                  <td>Normal</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td>Pump_2</td>
                  <td>Offers two different fuel types</td>
                  <td>Super95, DieselVariant1</td>
                  <td>No</td>
                  <td>Normal</td>
                  <td>-</td>
                </tr>
                <!-- Pump 3 -->
                <tr>
                  <td>Pump_3</td>
                  <td>Cancels all transactions</td>
                  <td>Super95, DieselVariant1</td>
                  <td>-</td>
                  <td>Zero fueling test</td>
                  <td>-</td>
                </tr>
                <!-- Pump 4 -->
                <tr>
                  <td>Pump_4</td>
                  <td>Rejects all transactions</td>
                  <td>Super95, DieselVariant1</td>
                  <td>-</td>
                  <td>Acquire call fails</td>
                  <td>-</td>
                </tr>
                <!-- Pump 5 -->
                <tr>
                  <td>Pump_5</td>
                  <td>Disabled (UNAVAILABLE)</td>
                  <td>-</td>
                  <td>-</td>
                  <td>Does not accept transactions</td>
                  <td>-</td>
                </tr>
                <!-- Pump 6 -->
                <tr>
                  <td>Pump_6</td>
                  <td>Offers two different fuel types with prices</td>
                  <td>Super95, DieselVariant1</td>
                  <td>Yes</td>
                  <td>Returns 'ACQUIRE_SUCCESS'</td>
                  <td>-</td>
                </tr>
                <!-- Pump 7 -->
                <tr>
                  <td>Pump_7</td>
                  <td>Rejects all transactions</td>
                  <td>Super95, DieselVariant1</td>
                  <td>-</td>
                  <td>Returns 'ACQUIRE_REJECTED'</td>
                  <td>Shows reason from the client</td>
                </tr>
                <!-- Pump 8 -->
                <tr>
                  <td>Pump_8</td>
                  <td>Cancels transactions</td>
                  <td>Super95, DieselVariant1</td>
                  <td>-</td>
                  <td>Returns 'PRODUCT_NOT_AVAILABLE'</td>
                  <td>Invalid pump status</td>
                </tr>
                <!-- Pump 9 -->
                <tr>
                  <td>Pump_9</td>
                  <td>Cancels transactions</td>
                  <td>Super95, DieselVariant1</td>
                  <td>-</td>
                  <td>Returns 'ACQUIRE_NOT_ANSWERED'</td>
                  <td>Long client timeout response</td>
                </tr>
                <!-- Pump 10 -->
                <tr>
                  <td>Pump_10</td>
                  <td>Order in PROBLEM state</td>
                  <td>-</td>
                  <td>-</td>
                  <td>Overshoot payment limit 250</td>
                  <td>UNAVAILABLE for Manual noop stations</td>
                </tr>
                <!-- Pump 11 -->
                <tr>
                  <td>Pump_11</td>
                  <td>Offers two different fuel types with prices</td>
                  <td>Super95, DieselVariant1</td>
                  <td>Yes</td>
                  <td>Returns 'ACQUIRE_SUCCESS' after 30s</td>
                  <td>Waits before changing status</td>
                </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
        <!-- CARD END -->

        <!-- CARD START -->
        <div class="card card-absolute">
          <div class="card-header bg-success">
            <h5 class="text-white">Car Wash Stations</h5>
          </div>
          <div class="card-body">
            <ul class="list-group">
              <li class="list-group-item"><b>Wash Station: </b>Station that only sells wash tickets.</li>
              <li class="list-group-item"><b>Combined Station: </b>Station that sells wash tickets and fuel.</li>
            </ul>

            <br>
            <div class="table-responsive product-table">
              <table class="table table-bordered table-striped">
                <thead class="bg-success text-white">
                  <tr>
                    <th class="bg-success">POI ID</th>
                    <th class="bg-success">Station Type</th>
                    <th class="bg-success">POI Brand</th>
                    <th class="bg-success">Country</th>
                    <th class="bg-success">Currency</th>
                    <th class="bg-success">Tax Rate</th>
                    <th class="bg-success">Comments</th>
                  </tr>
                </thead>
                <tbody>
                  <!-- WEAT -->
                  <tr>
                    <td>fddcf2ef-0a9d-4613-a943-7d592b4689c6</td>
                    <td>Combined</td>
                    <td>Weat Pre-PostPay.MIX.CARWASH 0900911002</td>
                    <td>DE</td>
                    <td>EUR</td>
                    <td>19%</td>
                    <td>-</td>
                  </tr>
                  <!-- Wash Noop -->
                  <tr>
                    <td>637cc8c50d6212c7886f5afc</td>
                    <td>Wash</td>
                    <td>Noop Car Wash</td>
                    <td>DE</td>
                    <td>EUR</td>
                    <td>19%</td>
                    <td>-</td>
                  </tr>
                  <!-- Wash&Gas Automatic Noop 01 -->
                  <tr>
                    <td>173adb65-36f5-4e79-a786-a2a894949ea0</td>
                    <td>Combined</td>
                    <td>DE Wash&Gas Automatic Noop 01</td>
                    <td>DE</td>
                    <td>EUR</td>
                    <td>19%</td>
                    <td>-</td>
                  </tr>
                  <!-- Wash&Gas Manual Noop 02 -->
                  <tr>
                    <td>9f085abd-e404-4115-8a70-98f2eefc61e0</td>
                    <td>Combined</td>
                    <td>DE Wash&Gas Manual Noop 02</td>
                    <td>DE</td>
                    <td>EUR</td>
                    <td>19%</td>
                    <td>-</td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
        <!-- CARD END -->

        <!-- CARD START -->
        <div class="card card-absolute">
          <div class="card-header bg-primary">
            <h5 class="text-white">Car Wash Configurations</h5>
          </div>
          <div class="card-body">
            <ul class="list-group">
              <li class="list-group-item"><b>Noop Stations</b>: Each wash program is uniquely configured to test different scenarios like successful or rejected transactions and different expiration dates for the purchased tickets.</li>
            </ul>

            <br/>
            <div class="table-responsive product-table">
              <table class="table table-bordered table-striped">
                <thead class="bg-primary text-white">
                  <tr>
                    <th class="bg-primary">Pump</th>
                    <th class="bg-primary">Name</th>
                    <th class="bg-primary">Description</th>
                    <th class="bg-primary">ID</th>
                    <th class="bg-primary">OTC</th>
                    <th class="bg-primary">Start Date</th>
                    <th class="bg-primary">Validity</th>
                    <th class="bg-primary">isRedeemed</th>
                    <th class="bg-primary">Currency</th>
                    <th class="bg-primary">Price</th>
                    <th class="bg-primary">Comments</th>
                  </tr>
                </thead>
                <tbody>
                  <!-- Nass Pflege -->
                  <tr>
                    <td>1</td>
                    <td>Nass Pflege</td>
                    <td>
                      <ul>
                        <li>Aktivschaum</li>
                        <li>Waschen</li>
                        <li>Trocknen</li>
                      </ul>
                    </td>
                    <td>10000014</td>
                    <td>80000001</td>
                    <td>Date of purchase</td>
                    <td>1 Year</td>
                    <td>Field Missing</td>
                    <td>EUR</td>
                    <td>5.99</td>
                    <td></td>
                  </tr>
                  <!-- Standard Pflege -->
                  <tr>
                    <td>2</td>
                    <td>Standard Pflege</td>
                    <td>
                      <ul>
                        <li>Aktivschaum</li>
                        <li>HD Wäsche</li>
                        <li>Felgen Rg</li>
                        <li>Trocknen</li>
                      </ul>
                    </td>
                    <td>10000015</td>
                    <td>80000002</td>
                    <td>Date of purchase</td>
                    <td>1 Day</td>
                    <td>Field Missing</td>
                    <td>EUR</td>
                    <td>9.00</td>
                    <td></td>
                  </tr>
                  <!-- Komfort Pflege -->
                  <tr>
                    <td>3</td>
                    <td>Komfort Pflege</td>
                    <td>
                      <ul>
                        <li>Chemische Vorwäsche</li>
                        <li>Aktivschaum</li>
                        <li>HD Wäsche</li>
                        <li>Felgen Reinigung</li>
                        <li>Trocknen</li>
                      </ul>
                    </td>
                    <td>10000016</td>
                    <td>600001</td>
                    <td>Date of purchase</td>
                    <td>1 Year</td>
                    <td>No</td>
                    <td>EUR</td>
                    <td>11.99</td>
                    <td></td>
                  </tr>
                  <!-- Glanz Pflege -->
                  <tr>
                    <td>4</td>
                    <td>Glanz Pflege</td>
                    <td>
                      <ul>
                        <li>Chemische Vorwäsche</li>
                        <li>Aktivschaum</li>
                        <li>HD Wäsche</li>
                        <li>Felgen Rg / Wachs</li>
                        <li>Trocknen</li>
                      </ul>
                    </td>
                    <td>10000017</td>
                    <td>600002</td>
                    <td>Date of purchase</td>
                    <td>1 Day</td>
                    <td>No</td>
                    <td>EUR</td>
                    <td>12.00</td>
                    <td></td>
                  </tr>
                  <!-- Premium Pflege -->
                  <tr>
                    <td>5</td>
                    <td>Premium Pflege</td>
                    <td>
                      <ul>
                        <li>Chemische Vorwäsche</li>
                        <li>Aktivschaum</li>
                        <li>HD Wäsche</li>
                        <li>FG Rg / Wachspolitur / Klarspülung</li>
                        <li>Trocknen</li>
                      </ul>
                    </td>
                    <td>10000018</td>
                    <td>600003</td>
                    <td>Date of purchase</td>
                    <td>1 Year</td>
                    <td>No</td>
                    <td>EUR</td>
                    <td>15.99</td>
                    <td>Order is always rejected</td>
                  </tr>
                </tbody>
              </table>

            </div>
            <br/>

            <ul class="list-group">
              <li class="list-group-item"><b>WEAT</b></li>
            </ul>

            <br/>
            <div class="table-responsive product-table">
              <table class="table table-bordered table-striped">
                <thead class="bg-primary text-white">
                  <tr>
                    <th class="bg-primary">Pump</th>
                    <th class="bg-primary">Name</th>
                    <th class="bg-primary">Description</th>
                    <th class="bg-primary">ID</th>
                    <th class="bg-primary">OTC</th>
                    <th class="bg-primary">Start Date</th>
                    <th class="bg-primary">Validity</th>
                    <th class="bg-primary">isRedeemed</th>
                    <th class="bg-primary">Currency</th>
                    <th class="bg-primary">Price</th>
                    <th class="bg-primary">Comments</th>
                  </tr>
                </thead>
                <tbody>
                  <!-- Autowaesche Pkw Praemium-->
                  <tr>
                    <td>1</td>
                    <td>Autowaesche Pkw Praemium</td>
                    <td>
                      <ul>
                        <li>Chemische Vorwäsche</li>
                        <li>Aktivschaum</li>
                        <li>HD Wäsche</li>
                        <li>FG Rg / Wachspolitur / Klarspülung</li>
                        <li>Trocknen</li>
                      </ul>
                    </td>
                    <td>10000019</td>
                    <td>variable</td>
                    <td>Date of purchase</td>
                    <td>13 Weeks</td>
                    <td>false</td>
                    <td>EUR</td>
                    <td>9.5</td>
                    <td></td>
                  </tr>

                  <!-- Autowaesche PKW einfach -->
                  <tr>
                    <td>2</td>
                    <td>Autowaesche Pkw Einfach</td>
                    <td>
                      <ul>
                        <li>Hochdruck-Vorwäsche</li>
                        <li>Aktivschaum</li>
                        <li>Waschen</li>
                        <li>Felgenwäsche</li>
                        <li>Trocknen</li>
                      </ul>
                    </td>
                    <td>10000009</td>
                    <td>variable</td>
                    <td>Date of purchase</td>
                    <td>13 Weeks</td>
                    <td>false</td>
                    <td>EUR</td>
                    <td>8.5</td>
                    <td></td>
                  </tr>

                  <!-- Lkw-Waesche -->
                  <tr>
                    <td>3</td>
                    <td>Lkw-Waesche</td>
                    <td>
                      <ul>
                        <li>LKW-Hochdruck-Vorwäsche</li>
                        <li>Aktivschaum</li>
                        <li>LKW-Waschen</li>
                        <li>Felgenwäsche</li>
                        <li>LKW-Trocknen</li>
                      </ul>
                    </td>
                    <td>10000010</td>
                    <td>variable</td>
                    <td>Date of purchase</td>
                    <td>13 Weeks</td>
                    <td>false</td>
                    <td>EUR</td>
                    <td>35.5</td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
        <!-- CARD END -->


      </div>
    </div>
  </div>
</template>
<script setup>
</script>