import { initializeApp } from 'firebase/app'; 
import {getAuth} from 'firebase/auth';
import { getFirestore } from 'firebase/firestore' 


const firebaseConfig = {
    apiKey: "AIzaSyCMG1KGtL6MYvT1ypk8X29GygIOJpNZApw",
    authDomain: "ryd-partner-portal.firebaseapp.com",
    projectId: "ryd-partner-portal",
    storageBucket: "ryd-partner-portal.appspot.com",
    messagingSenderId: "155408459899",
    appId: "1:155408459899:web:9436229b834257fb481ade",
    measurementId: "G-3WGGVS4C5Y"
};

const firebaseApp =  initializeApp(firebaseConfig);

const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);

export { db, auth };
