<template>
  <Breadcrumbs main="Platform Status" title="Past Incidents"/>

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">

        <!-- begin -->
        <div class="col-sm-12 col-md-12">
          <div class="card">
            <div class="card-header bg-primary">
              <h5><i class="icofont icofont-id"></i>&nbsp;Delay in Invoice Generation</h5>
            </div>
            <div class="card-body">
              <p class="mb-0"><i class="icofont icofont-ui-calendar bg-primary"></i>:
                <b>Date Range</b>: 2024-01-22 10:00 PM to 2024-01-24 09:00 AM (Berlin Time)
              </p>
              <br/>

              <p class="mb-0"><i class="icofont icofont-info-square bg-primary"></i>:
                <b>Summary</b>: The invoice generation service at Ryd was non-operational during the specified period.
              </p>
              <br/>

              <p class="mb-0"><i class="icofont icofont-info-square bg-primary"></i>:
                <b>Affected Services</b>: Invoice Generation for Ryd's API Partners
              </p>
              <br/>

              <p class="mb-0"><i class="icofont icofont-info-square bg-primary"></i>:
                <b>Impact</b>:
              </p>
              <ul class="list-group">
                <li class="list-group-item">
                  All API partners relying on Ryd for invoice PDF generation experienced delays.
                </li>
                <li class="list-group-item">
                  Invoice PDFs were not created immediately after order completion.
                </li>
              </ul>
              <br/>

              <p class="mb-0"><i class="icofont icofont-info-square bg-primary"></i>:
                <b>Incident Details</b>:
              </p>
              <ul class="list-group">
                <li class="list-group-item">
                  <b>Cause</b>: The invoice generation, which is triggered by Kafka events, was disrupted. The
                  consumption of events for invoice generation was halted due to maintenance activities by Ryd's data
                  center provider, Gridscale.
                </li>
              </ul>
              <br/>

              <p class="mb-0"><i class="icofont icofont-info-square bg-primary"></i>:
                <b>Resolution</b>:
              </p>
              <ul class="list-group">
                <li class="list-group-item">
                  Ryd restarted the relevant services and initiated the invoice generation process after the maintenance
                  was concluded, at approximately 09:00 AM on 2024-01-24.
                </li>
                <li class="list-group-item">
                  No data was lost during this incident.
                </li>
                <li class="list-group-item">
                  The generation of invoices for orders placed during the incident timeframe was successfully executed
                  post-maintenance.
                </li>
              </ul>
              <br/>

              <p class="mb-0"><i class="icofont icofont-info-square bg-primary"></i>:
                <b>Preventative Measures</b>:
              </p>
              <ul class="list-group">
                <li class="list-group-item">
                  <b>Alert System Upgrade</b>: Enhanced alerting for invoice generation disruptions, enabling faster
                  response and issue resolution.
                </li>
                <li class="list-group-item">
                  <b>Backup Process for Invoice Generation</b>: Implementation of a back-up system to ensure
                  uninterrupted invoice generation in the event of failure or maintenance at the data centre provider,
                  which doesn't affect the invoice service at ryd directly.
                </li>

              </ul>
              <br/>


            </div>

          </div>
        </div>
        <!-- end -->


      </div>
    </div>
  </div>
</template>
<script setup>
</script>