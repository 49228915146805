<template>
    <Breadcrumbs main="rydpay APIs" title="Introduction: Overview of Ryd's platform and rydpay APIs" />

    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">


                <div class="card">
                    <div class="card-header bg-primary">
                        <h5>Introduction to Ryd</h5>
                    </div>
                    <div class="card-body">
                        <p>Ryd is a revolutionary mobile payment platform designed to streamline and enhance the fueling and
                            car wash experience for customers across multiple countries. Our platform integrates seamlessly with
                            petrol stations and wash stations, offering a convenient and efficient service for end-users
                            while providing valuable tools for our partners.</p>
                        <br />
                    </div>
                </div>



                <div class="card">
                    <div class="card-header bg-success">
                        <h5>rydpay API: Bridging Customers
                            and Services</h5>
                    </div>
                    <div class="card-body">
                        Our APIs are the core of Ryd's platform, acting as a gateway between our partners and a network of
                        petrol and car wash stations. These APIs facilitate a range of services, from fuel purchases to car
                        wash bookings, all through a few simple API calls
                    </div>
                </div>



                <div class="card">
                    <div class="card-header bg-primary">
                        <h5>Key Features</h5>
                    </div>
                    <div class="card-body">
                        <ul class="list-group">
                            <li class="list-group-item font-primary"><b>Topup Flows</b></li>
                            <ul class="list-group">
                                <li class="list-group-item"><b>Prepay (Automatic):</b> Customers pre-authorize payment to
                                    unlock
                                    the pump and begin fueling. The pump
                                    automatically stops at the pre-authorized amount, ensuring a seamless transaction.</li>
                                <li class="list-group-item"><b>Postpay (Manual):</b> Customers fuel first and pay later,
                                    offering
                                    flexibility and convenience.
                                    </li>
                            </ul>
                            <li class="list-group-item font-primary"><b>Car Wash Services</b></li>
                            <ul class="list-group">
                                <li class="list-group-item">Integration with car wash stations to purchase digital codes for
                                    services, enhancing the customer experience.</li>
                            </ul>
                            <li class="list-group-item font-primary"><b>Real-Time Data</b></li>
                            <ul class="list-group">
                                <li class="list-group-item">Access to real-time data including fuel prices and station
                                    information, ensuring transparency and compliance with regional regulations.</li>
                            </ul>
                            <li class="list-group-item font-primary"><b>Cross-Country Compatibility</b></li>
                            <ul class="list-group">
                                <li class="list-group-item">Our APIs are designed to work across multiple countries,
                                    accommodating various forecourt systems and regional differences.</li>
                            </ul>
                        </ul>
                    </div>
                </div>


                <div class="card">
                    <div class="card-header bg-success">
                        <h5>Partner Benefits</h5>
                    </div>
                    <div class="card-body">
                        <ul class="list-group">
                            <li class="list-group-item font-primary"><b>Ease of Integration</b></li>
                            <ul class="list-group">
                                <li class="list-group-item">Our APIs are designed for easy integration, with comprehensive
                                    documentation and support.</li>
                            </ul>
                            <li class="list-group-item font-primary"><b>Customization and Control</b></li>
                            <ul class="list-group">
                                <li class="list-group-item">Tailor the services to fit your business needs while maintaining
                                    control over your customer data and payment methods.</li>
                            </ul>
                            <li class="list-group-item font-primary"><b>Scalability</b></li>
                            <ul class="list-group">
                                <li class="list-group-item">Whether you're a small startup or a large enterprise, our
                                    platform scales with your business.</li>
                            </ul>
                            <li class="list-group-item font-primary"><b>Innovative Solutions</b></li>
                            <ul class="list-group">
                                <li class="list-group-item">Stay ahead in the market by leveraging our cutting-edge
                                    technology.</li>
                            </ul>
                        </ul>
                    </div>
                </div>


            </div>
        </div>
    </div>
</template>