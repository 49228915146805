<template>
  <Breadcrumbs main="Guardian System and Alerts" title="Create MOC Partner Configuration"/>

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">

        <!-- Full Page Loading Overlay -->
        <div v-if="isLoading">
          <h6 class="sub-title mb-0 text-center">Loading ...</h6>
          <div class="loader-box">
            <div class="loader-19"></div>
          </div>
        </div>

        <!-- Main form content -->
        <div class="col-sm-12" v-if="!isLoading">
          <div class="card">
            <div class="card-header bg-primary">
              <h5>Create MOC Partner Configuration</h5>
            </div>
            <div class="card-body">
              <div class="form theme-form">
                <form @submit.prevent="createConfiguration">

                  <div class="row fw-bolder f-w-900">
                    <p v-if="error" class="error-message m-b-25">{{ error }}</p>
                  </div>

                  <!-- Cashier System Name Input -->
                  <div class="row border-bottom m-b-20">
                    <div class="col">
                      <div class="mb-3">
                        <label for="cashierSystemName" class="fw-bold">MOC Partner Name *</label>
                        <select class="form-control border border-primary min-width-max-content" id="mocPartnerName" v-model="newConfiguration.mocPartnerName"
                                required>
                          <option v-for="mocPartnerName in mocPartnerNames" :key="mocPartnerName" :value="mocPartnerName">
                            {{ mocPartnerName }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <!-- Email Configurations Array -->
                  <div class="row border-bottom m-b-20 m-t-20">
                    <div class="col">
                      <div class="mb-3">
                        <label class="fw-bold">Email Configurations</label>
                        <button type="button" class="btn btn-primary m-l-30" @click="addEmailConfiguration">Add Email</button>

                        <table class="table table-striped">
                          <thead class="bg-primary text-white">
                          <tr>
                            <th>Email</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Department</th>
                            <th>Note</th>
                            <th>Alert Type</th>
                            <th>Active</th>
                            <th>Actions</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(email, index) in newConfiguration.emailConfigurations" :key="index">
                            <td><input type="text" v-model="email.email" class="form-control" placeholder="Email"></td>
                            <td><input type="text" v-model="email.firstName" class="form-control" placeholder="First Name"></td>
                            <td><input type="text" v-model="email.lastName" class="form-control" placeholder="Last Name"></td>
                            <td><input type="text" v-model="email.department" class="form-control" placeholder="Department (optional)"></td>
                            <td><textarea v-model="email.note" class="form-control" placeholder="Note"></textarea></td>
                            <td>
                              <select v-model="email.alertType" class="form-control">
                                <option value="EscalationLevel1">Escalation Level 1</option>
                                <option value="EscalationLevel2">Escalation Level 2</option>
                                <option value="EscalationLevel3">Escalation Level 3</option>
                              </select>
                            </td>
                            <td>
                              <label :for="'active-checkbox-' + index">Active</label>
                              <br/>
                              <input type="checkbox" v-model="email.isActive" :id="'active-checkbox-' + index">
                            </td>
                            <td>
                              <button type="button" class="btn btn-danger" @click="removeEmailConfiguration(index)">Remove</button>
                            </td>
                          </tr>
                          </tbody>
                        </table>

                      </div>
                    </div>
                  </div>

                  <!-- Links Array -->
                  <div class="row border-bottom m-b-20 m-t-20">
                    <div class="col">
                      <div class="mb-3">
                        <label class="fw-bold">Links</label>
                        <button type="button" class="btn btn-primary m-l-30" @click="addLink">Add Link</button>

                        <table class="table table-striped">
                          <thead class="bg-primary text-white">
                          <tr>
                            <th>Description</th>
                            <th>Link Type</th>
                            <th>Link URL</th>
                            <th>Actions</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(link, index) in newConfiguration.links" :key="index">
                            <td>
                              <input type="text" v-model="link.description" class="form-control" placeholder="Description">
                            </td>
                            <td>
                              <input type="text" v-model="link.linkType" class="form-control" placeholder="Link Type">
                            </td>
                            <td>
                              <input type="text" v-model="link.link" class="form-control" placeholder="Link URL">
                            </td>
                            <td>
                              <button type="button" class="btn btn-danger" @click="removeLink(index)">
                                Remove
                              </button>
                            </td>
                          </tr>
                          </tbody>
                        </table>

                      </div>
                    </div>
                  </div>


                  <!-- Alert Disabled Until -->
                  <div class="row border-bottom m-b-20 m-t-20">
                    <div class="col">
                      <div class="mb-3">
                        <label for="alertDisabledUntilDate" class="fw-bold">Alert Disabled Until Date</label>
                        <input type="datetime-local" class="form-control border border-primary" id="alertDisabledUntilDate"
                               v-model="newConfiguration.alertDisabledUntil">
                      </div>
                    </div>
                  </div>

                  <!-- Email Language -->
                  <div class="row border-bottom m-b-20 m-t-20">
                    <div class="col">
                      <div class="mb-3">
                        <label for="emailLanguage" class="fw-bold">Email Language</label>
                        <select class="form-control border border-primary" id="emailLanguage"
                                v-model="newConfiguration.emailLanguage" required>
                          <option value="">Select a language...</option>
                          <option value="ENGLISH">English</option>
                          <option value="GERMAN">German</option>
                          <!-- Add other languages as necessary -->
                        </select>
                      </div>
                    </div>
                  </div>

                  <!-- Submit Button -->
                  <div class="row">
                    <div class="col">
                      <button class="btn btn-pill btn-success btn-air-success active btn-lg" type="submit"
                              title="Create configuration">
                        <i class="fa fa-send m-r-15"></i>Create
                      </button>
                    </div>
                  </div>
                </form>

              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {
  PARTNER_ANALYTICS_API_KEY,
  PARTNER_ANALYTICS_SERVER_URL,
  PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_KAY,
  PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_URL,
  RPP_API_SERVER_URL
} from "@/constants/constants";
import {getUserAddDataFromLocalStorage} from "@/utils/userUtils";


export default {
  name: 'CreateCashierSystemConfiguration',
  data() {
    return {
      newConfiguration: {
        mocPartnerName: '',
        emailConfigurations: [],
        links: [],
        downtimes: [],
        alertDisabledUntil: '',
        emailLanguage: '',
        additionalConfigurations: ''
      },
      isLoading: false,
      error: null,
      loggedInUserId: '',
      loggedInUserGroup: '',
      partnerMetricsFor: '',
      mocPartnerNames: [],
    };
  },
  mounted() {
    const {uid, isUserGroupRyd, userGroup} = getUserAddDataFromLocalStorage();
    this.loggedInUserId = uid;
    this.loggedInUserGroup = userGroup;
    if (isUserGroupRyd) {
      this.partnerMetricsFor = 'all';
      this.loadMocPartnerNames(this.loggedInUserId);
    } else {
      this.partnerMetricsFor = userGroup;
    }
  },
  methods: {
    createConfiguration() {
      this.isLoading = true;
      this.error = null;

      axios.post(`${PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_URL}/moc-partner-configurations`, this.newConfiguration, {
        headers: {
          'x-txn-auth-token': PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_KAY,
          'uid': this.loggedInUserId
        }
      }).then(() => {
            this.$router.push('/guardian-system-and-alerts/cashier-system-configuration/list'); //
          })
          .catch(error => {
            this.error = error.response && error.response.data.message ? error.response.data.message : 'Failed to create configuration';
          })
          .finally(() => {
            this.isLoading = false;
          });
    },
    async loadMocPartnerNames() {
      try {
        const response = await axios.get(`${PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_URL}/constants/moc-partners`, {
          headers: {
            'x-txn-auth-token': PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_KAY,
            'uid': this.loggedInUserId,
          }
        });

        this.mocPartnerNames =  response.data.data;
      } catch (error) {
        console.log("error", error);
      }
    },
    addEmailConfiguration() {
      this.newConfiguration.emailConfigurations.push({
        email: '',
        firstName: '',
        lastName: '',
        department: '',
        note: '',
        alertType: 'EscalationLevel1', // Default to Level 1
        isActive: false
      });
    },
    removeEmailConfiguration(index) {
      this.newConfiguration.emailConfigurations.splice(index, 1);
    },
    addLink() {
      this.newConfiguration.links.push({
        description: '',
        linkType: '',
        link: ''
      });
    },
    removeLink(index) {
      this.newConfiguration.links.splice(index, 1);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/utils/variables";
@import "@/assets/scss/components/datatable";
</style>
<style scoped>
@import "@/assets/css/productlist.css";
</style>
<style scoped>
.sortable {
  cursor: pointer;
}

.sortable.active {
  color: #0d6efd; /* Bootstrap primary color */
}
</style>