<template>
    <Breadcrumbs main="rydpay APIs" title="Test Scenarios" />

    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">


                <div class="card card-absolute">
                    <div class="card-header bg-primary">
                        <h5 class="text-white">Overview</h5>
                    </div>
                    <div class="card-body">
                        <p>
                            This section provides a comprehensive guide to the main test scenarios included in our Postman
                            collection. These scenarios are designed to help you understand and test the various
                            cases of our API in a real-world context.
                        </p>
                        <br />
                        <p>
                            To facilitate your testing process, we provide a Postman collection containing all the test
                            scenarios mentioned below. You can download the collection and import it into your Postman
                            application to start testing immediately.
                        </p>
                        <p>
                            <b>Download Link:</b>
                            <button class="m-l-20 btn-outline-primary" @click="downloadJson">Download Ryd APIs Partner
                                Postman
                                Collection</button>
                        </p>
                    </div>
                </div>

                <br />


                <div class="card card-absolute">
                    <div class="card-header bg-success">
                        <h5 class="text-white">How to Use the Postman Collection</h5>
                    </div>
                    <div class="card-body">
                        <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Importing the Collection:</h5>
                        <ul class="list-group">
                            <li class="list-group-item">After downloading, open Postman and import the collection by
                                selecting 'Import' and choosing the downloaded file.</li>
                        </ul>
                        <br />

                        <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Setting Up Collection Variables:
                        </h5>
                        <ul class="list-group">
                            <li class="list-group-item">Configure the necessary collection variable
                                <code>xTxnAuthToken</code> with the your api key for the staging environment.
                            </li>
                        </ul>
                        <br />

                        <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Executing Test Scenarios: </h5>
                        <ul class="list-group">
                            <li class="list-group-item">Select a test case from the collection and hit 'Send' to execute the
                                API requests. Observe the responses and validate them against the expected behavior outlined
                                on this page.</li>
                            <li class="list-group-item">The entire collection can be executed in Postman, which includes
                                specific test validations for each request and response.</li>
                        </ul>
                        <br />

                    </div>
                </div>


                <div class="card card-absolute">
                    <div class="card-header bg-info">
                        <h5>Test Case Index</h5>
                    </div>
                    <div class="card-body">
                        <div class="alert alert-info outline" role="alert">
                            <p>Select a test case to directly access its specific details.</p>
                        </div>
                        <ul class="list-group list-group-flush">
                            <li class="list-group-item">
                                <a @click.prevent="scrollToCard2('tc101')" class="text-primary text-decoration-none">TC 101:
                                    Postpay - Customer Selects a Pump, Doesn't Pick Up Nozzle</a>
                            </li>
                            <li class="list-group-item">
                                <a @click.prevent="scrollToCard2('tc102')" class="text-primary text-decoration-none">
                                    TC 102: Postpay - Successful Transaction
                                </a>
                            </li>
                            <li class="list-group-item">
                                <a @click.prevent="scrollToCard2('tc103')" class="text-primary text-decoration-none">
                                    TC 103: Postpay - Transaction Rejection
                                </a>
                            </li>
                            <li class="list-group-item">
                                <a @click.prevent="scrollToCard2('tc104')" class="text-primary text-decoration-none">
                                    TC 104: Postpay - Pump Disabled Or In Use
                                </a>
                            </li>
                            <li class="list-group-item">
                                <a @click.prevent="scrollToCard2('tc201')" class="text-primary text-decoration-none">
                                    TC 201: Prepay - Successful Transaction
                                </a>
                            </li>
                            <li class="list-group-item">
                                <a @click.prevent="scrollToCard2('tc202')" class="text-primary text-decoration-none">
                                    TC 202: Prepay - Zero Transaction
                                </a>
                            </li>
                            <li class="list-group-item">
                                <a @click.prevent="scrollToCard2('tc203')" class="text-primary text-decoration-none">
                                    TC 203: Prepay - Transaction Rejection
                                </a>
                            </li>
                            <li class="list-group-item">
                                <a @click.prevent="scrollToCard2('tc204')" class="text-primary text-decoration-none">
                                    TC 204: Prepay - Pump Disabled Or In Use
                                </a>
                            </li>
                            <li class="list-group-item">
                                <a @click.prevent="scrollToCard2('tc205')" class="text-primary text-decoration-none">
                                    TC 205: Prepay - Pump Overshoot
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>



                <!-- TC 101 -->
                <div class="card" id="tc101">
                    <div class="card-header bg-primary">
                        <h5>TC 101: Postpay - Customer Selects a Pump, Doesn't Pick Up Nozzle</h5>
                    </div>
                    <div class="card-body">
                        <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Description</h5>
                        <ul class="list-group">
                            <li class="list-group-item">This test case assesses the system's response when a customer
                                initiates a top-up at a gas station but does not engage with the pump (e.g., does not
                                pick
                                up the nozzle). It's crucial for ensuring the system correctly handles inactivity
                                post-initiation.
                            </li>
                        </ul>
                        <br />

                        <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Steps:</h5>
                        <ul class="list-group">
                            <li class="list-group-item">1. Customer selects the gas station with the poiId
                                <code>60c9bfe1f2d000d67561f181</code> and pump <code>1</code>.
                            </li>
                            <li class="list-group-item">2. Customer does not interact with pump 1 (no nozzle pickup, no
                                top-up initiation).</li>
                        </ul>
                        <br />

                        <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Expected Behavior:</h5>
                        <ul class="list-group">
                            <li class="list-group-item">1. The partner's system handles the timeout scenario correctly:
                                <ol class="list-group">
                                    <li class="list-group-item">No <code>v4/order/:orderId/authorize</code> API call
                                        will be
                                        triggered.</li>
                                    <li class="list-group-item">No <code>v4/order/:orderId/acquire</code> API call will
                                        be
                                        triggered.</li>
                                </ol>
                            </li>
                            <li class="list-group-item">2. The partner's system should present an appropriate message to
                                the
                                customer and stop polling the Ryd API after a set duration (5-10 minutes, as configured
                                by
                                the partner).</li>
                            <li class="list-group-item">3. The order is automatically canceled by Ryd after
                                approximately 60
                                minutes of inactivity.</li>
                        </ul>
                        <br />

                        <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Technical Verification Steps:
                        </h5>
                        <ol class="list-group" type="1">
                            <li class="list-group-item">1. Repeated calls to <code>/v4/order/:orderId</code> within the
                                polling duration return HTTP 200.</li>
                            <li class="list-group-item">2. The <code>stateHistory</code> in the response consistently
                                shows
                                the
                                last state as
                                <code>CREATED</code>, indicating no progression to <code>PAYMENT_PENDING</code> due to
                                customer inactivity.
                            </li>
                        </ol>
                        <br />
                    </div>
                </div>

                <!-- TC 102 -->
                <div class="card" id="tc102">
                    <div class="card-header bg-success">
                        <h5>TC 102: Postpay - Successful Transaction</h5>
                    </div>
                    <div class="card-body">
                        <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Description</h5>
                        <ul class="list-group">
                            <li class="list-group-item">This test case validates the successful completion of a transaction
                                in postpay mode, ensuring that the system accurately processes a transaction from initiation
                                to completion. It also tests the optional invoice generation feature based on the partner's
                                configuration.</li>
                        </ul>
                        <br />

                        <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Steps:</h5>
                        <ul class="list-group">
                            <li class="list-group-item">1. Select the gas station with poiId
                                <code>60c9bfe0f2d000d67561f173</code> and pump <code>1</code>.
                            </li>
                            <li class="list-group-item">2. Complete the fueling process and finalize the transaction.</li>
                        </ul>
                        <br />

                        <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Expected Behavior:</h5>
                        <ul class="list-group">
                            <li class="list-group-item">1. The transaction completes successfully without any errors or
                                interruptions.</li>
                            <li class="list-group-item">2. If configured by the partner, an invoice is generated upon
                                completion of the transaction, accurately reflecting the transaction details.</li>
                        </ul>
                        <br />

                        <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Technical Verification Steps:</h5>
                        <ol class="list-group" type="1">
                            <li class="list-group-item">1. Repeated calls to <code>/v4/order/:orderId</code> within the
                                polling duration return HTTP 200.</li>
                            <li class="list-group-item">2. The <code>stateHistory</code> array within the response must
                                include <code>PRODUCT_ACQUIRED</code> as a state, indicating the successful completion of
                                the transaction.</li>
                        </ol>
                        <br />
                    </div>
                </div>


                <!-- TC 103 -->
                <div class="card" id="tc103">
                    <div class="card-header bg-primary">
                        <h5>TC 103: Postpay - Transaction Rejection</h5>
                    </div>
                    <div class="card-body">
                        <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Description</h5>
                        <ul class="list-group">
                            <li class="list-group-item">This test case evaluates the partner's system response to
                                transaction
                                rejections. It simulates a scenario where the petrol station's
                                cashier system rejects the transaction initiated by ryd.</li>
                        </ul>
                        <br />

                        <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Steps:</h5>
                        <ul class="list-group">
                            <li class="list-group-item">1. Select the gas station with the poiId
                                <code>60c9bfe0f2d000d67561f173</code> and pump <code>4</code>.
                            </li>
                            <li class="list-group-item">2. Attempt to complete the transaction.</li>
                        </ul>
                        <br />

                        <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Expected Behavior:</h5>
                        <ul class="list-group">
                            <li class="list-group-item">1. The transaction is rejected by the petrol station's cashier
                                system.</li>
                            <li class="list-group-item">2. The partner's system correctly handles the rejection scenario,
                                providing appropriate feedback to the user.</li>
                        </ul>
                        <br />

                        <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Technical Verification Steps:</h5>
                        <ol class="list-group" type="1">
                            <li class="list-group-item">1. Upon attempting to acquire the order with the
                                <code>/v4/order/:orderId/acquire</code> API, the response returns an HTTP status code of
                                <code>400</code>, indicating a transaction rejection.
                            </li>
                        </ol>
                        <br />
                    </div>
                </div>



                <!-- TC 104 -->
                <div class="card" id="tc104">
                    <div class="card-header bg-success">
                        <h5>TC 104: Postpay - Pump Disabled Or In Use</h5>
                    </div>
                    <div class="card-body">
                        <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Description</h5>
                        <ul class="list-group">
                            <li class="list-group-item">This test case assesses the partner's system response when
                                attempting to interact with a pump that is either disabled or currently in use.</li>
                        </ul>
                        <br />

                        <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Steps:</h5>
                        <ul class="list-group">
                            <li class="list-group-item">1. Select the gas station with poiId
                                <code>60c9bfe0f2d000d67561f173</code>.
                            </li>
                            <li class="list-group-item">2. Attempt to interact with the pump 5.</li>
                        </ul>
                        <br />

                        <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Expected Behavior:</h5>
                        <ul class="list-group">
                            <li class="list-group-item">1. The pump is recognized by the system as disabled or in
                                use.</li>
                            <li class="list-group-item">2. The partner's system correctly handles the pump's status,
                                preventing the
                                customer from selecting a disabled or in-use pump.</li>
                        </ul>
                        <br />

                        <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Technical Case Identification:</h5>
                        <ol class="list-group" type="1">
                            <li class="list-group-item">1. A call to the <code>/v4/pois/:poiId</code> API returns an HTTP
                                status code of <code>200</code>.</li>
                            <li class="list-group-item">2. Within the API response, the status of pump 5 (an item in the
                                products array) is marked as <code>UNAVAILABLE</code>. The technical path to verify this
                                status is <code>products[index].attributes.status</code>.</li>
                        </ol>
                        <br />
                    </div>
                </div>




                <!-- TC 201 -->
                <div class="card" id="tc201">
                    <div class="card-header bg-primary">
                        <h5>TC 201: Prepay - Successful Transaction</h5>
                    </div>
                    <div class="card-body">
                        <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Description</h5>
                        <ul class="list-group">
                            <li class="list-group-item">This test case evaluates the successful completion of a transaction
                                using the prepay method, ensuring that the system accurately processes the transaction from
                                authorization to acquisition.</li>
                        </ul>
                        <br />

                        <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Steps:</h5>
                        <ul class="list-group">
                            <li class="list-group-item">1. Select a gas station with the poiId
                                <code>60c9bfe0f2d000d67561f172</code> and pump <code>1</code>.
                            </li>
                            <li class="list-group-item">2. Choose any available fuel type.</li>
                            <li class="list-group-item">3. Authorize and acquire the order.</li>
                        </ul>
                        <br />

                        <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Expected Behavior:</h5>
                        <ul class="list-group">
                            <li class="list-group-item">1. The order is successfully authorized and acquired.</li>
                            <li class="list-group-item">2. An invoice is generated for the correct amount, reflecting the
                                transaction details.</li>
                        </ul>
                        <br />

                        <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Technical Case Identification:</h5>
                        <ol class="list-group" type="1">
                            <li class="list-group-item">1. A call to the <code>/v4/order/:orderId</code> API after the
                                transaction should return an HTTP status code of 200.</li>
                            <li class="list-group-item">2. The <code>stateHistory</code> array within the response must
                                include <code>PRODUCT_ACQUIRED</code> as a state, indicating the successful completion of
                                the transaction.</li>
                        </ol>
                        <br />
                    </div>
                </div>


                <!-- TC 202 -->
                <div class="card" id="tc202">
                    <div class="card-header bg-success">
                        <h5>TC 202: Prepay - Zero Transaction</h5>
                    </div>
                    <div class="card-body">
                        <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Description</h5>
                        <ul class="list-group">
                            <li class="list-group-item">This test case simulates a scenario where the petrol station's
                                cashier system returns a positive status for a transaction, but the amount involved is zero.
                                It's crucial for ensuring the system correctly handles transactions with no financial
                                exchange.</li>
                        </ul>
                        <br />

                        <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Steps:</h5>
                        <ul class="list-group">
                            <li class="list-group-item">1. Select the gas station with poiId
                                <code>60c9bfe0f2d000d67561f172</code> and pump <code>3</code>.
                            </li>
                            <li class="list-group-item">2. Choose any available fuel type.</li>
                            <li class="list-group-item">3. Authorize and acquire the order.</li>
                        </ul>
                        <br />

                        <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Expected Behavior:</h5>
                        <ul class="list-group">
                            <li class="list-group-item">1. The partner's system successfully handles a zero ransaction.</li>
                        </ul>
                        <br />

                        <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Technical Case Identification:</h5>
                        <ol class="list-group" type="1">
                            <li class="list-group-item">1. Upon execution, the <code>/v4/order/:orderId</code> API returns
                                an HTTP response with a status code of 200.</li>
                            <li class="list-group-item">2. The <code>stateHistory</code> array within the response includes
                                <code>REJECTED</code> as one of its states, indicating the transaction was processed but
                                resulted in a zero amount.
                            </li>
                        </ol>
                        <br />
                    </div>
                </div>


                <!-- TC 203 -->
                <div class="card" id="tc203">
                    <div class="card-header bg-primary">
                        <h5>TC 203: Prepay - Transaction Rejection</h5>
                    </div>
                    <div class="card-body">
                        <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Description</h5>
                        <ul class="list-group">
                            <li class="list-group-item">This test case evaluates the system's response when a transaction
                                initiated by Ryd is rejected by the petrol station's cashier system. It simulates a
                                situation where Ryd attempts to establish a session with the cashier system for an order,
                                but the session creation is denied.</li>
                        </ul>
                        <br />

                        <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Steps:</h5>
                        <ul class="list-group">
                            <li class="list-group-item">1. Select the gas station with poiId
                                <code>60c9bfe0f2d000d67561f172</code> and pump <code>4</code>.
                            </li>
                            <li class="list-group-item">2. Choose any available fuel type.</li>
                            <li class="list-group-item">3. Attempt to authorize and acquire the order.</li>
                        </ul>
                        <br />

                        <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Expected Behavior:</h5>
                        <ul class="list-group">
                            <li class="list-group-item">1. The transaction is rejected by the petrol station's cashier
                                system.</li>
                            <li class="list-group-item">2. The partner's system appropriately handles the rejection,
                                providing necessary feedback and actions.</li>
                        </ul>
                        <br />

                        <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Technical Case Identification:</h5>
                        <ol class="list-group" type="1">
                            <li class="list-group-item">1. The <code>/v4/order/:orderId/acquire</code> API, upon execution,
                                returns an HTTP response with a status code of <code>400</code>, indicating the rejection of
                                the transaction.
                            </li>
                            <li class="list-group-item">
                                2. Upon repeatedly calling the <code>/v4/order/:orderId</code> endpoint within the
                                designated
                                polling
                                period, each response should consistently return an HTTP status code of 200. Crucially,
                                within these responses, the stateHistory array is expected to contain <code>CANCELLED</code>
                                as one of
                                its states. The presence of <code>CANCELLED</code> in the state history signifies a final
                                state for the
                                order, indicating that no further actions, or changes will occur for that particular order,
                                no repeated calling the <code>/v4/order/:orderId</code> is needed.
                            </li>
                        </ol>
                        <br />
                    </div>
                </div>



                <!-- TC 204 -->
                <div class="card" id="tc204">
                    <div class="card-header bg-success">
                        <h5>TC 204: Prepay - Pump Disabled Or In Use</h5>
                    </div>
                    <div class="card-body">
                        <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Description</h5>
                        <ul class="list-group">
                            <li class="list-group-item">This test case assesses the partner's system response when
                                attempting to interact with a pump that is either disabled or currently in use.</li>
                        </ul>
                        <br />

                        <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Steps:</h5>
                        <ul class="list-group">
                            <li class="list-group-item">1. Select the gas station with poiId
                                <code>60c9bfe0f2d000d67561f172</code>.
                            </li>
                            <li class="list-group-item">2. Attempt to interact with the pump 5.</li>
                        </ul>
                        <br />

                        <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Expected Behavior:</h5>
                        <ul class="list-group">
                            <li class="list-group-item">1. The pump is recognized by the system as disabled or in
                                use.</li>
                            <li class="list-group-item">2. The partner's system correctly handles the pump's status,
                                preventing the customer from selecting a disabled or in-use pump.</li>
                        </ul>
                        <br />

                        <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Technical Case Identification:</h5>
                        <ol class="list-group" type="1">
                            <li class="list-group-item">1. A call to the <code>/v4/pois/:poiId</code> API returns an HTTP
                                status code of <code>200</code>.</li>
                            <li class="list-group-item">2. Within the API response, the status of pump 5 (an item in the
                                products array) is marked as <code>UNAVAILABLE</code>. The technical path to verify this
                                status is <code>products[index].attributes.status</code>.
                            </li>
                        </ol>
                        <br />
                    </div>
                </div>

                <!-- TC 205 -->
                <div class="card" id="tc205">
                    <div class="card-header bg-primary">
                        <h5>TC 205: Prepay - Pump Overshoot</h5>
                    </div>
                    <div class="card-body">
                        <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Description</h5>
                        <ul class="list-group">
                            <li class="list-group-item">This test case examines how the partner's system manages instances
                                of pump overshoots, where the fuel dispensed exceeds the pre-authorized amount. Detailed
                                definition of overshoot is available at <a
                                    href="/integration-guide/rydpay-apis/terms-and-definitions#overshoot"
                                    target="_blank">here</a>.</li>
                        </ul>
                        <br />

                        <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Steps:</h5>
                        <ul class="list-group">
                            <li class="list-group-item">1. Select the gas station with poiId
                                <code>60c9bfe0f2d000d67561f172</code> and pump <code>10</code>.
                            </li>
                            <li class="list-group-item">2. Choose any available fuel type.</li>
                            <li class="list-group-item">3. Authorize and acquire the order.</li>
                        </ul>
                        <br />

                        <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Expected Behavior:</h5>
                        <ul class="list-group">
                            <li class="list-group-item">1. The partner's system effectively handles the overshoot scenario,
                                transitioning the transaction into a <code>PROBLEM</code> state.</li>
                            <li class="list-group-item">2. Ryd advises partners to treat overshoot cases as successful
                                transactions. Ryd will display a success screen to B2C customers and manage any excess
                                amounts internally.</li>
                        </ul>
                        <br />

                        <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Technical Case Identification:</h5>
                        <ol class="list-group" type="1">
                            <li class="list-group-item">1. Within the polling period, the <code>/v4/order/:orderId</code>
                                API returns
                                an HTTP response with a status code of 200.</li>
                            <li class="list-group-item">2. The <code>stateHistory</code> array in
                                these responses should include <code>PROBLEM</code> as a final state, indicating that Ryd
                                will undertake manual checks and actions, and no further polling of the order is necessary.
                            </li>
                        </ol>
                        <br />
                    </div>
                </div>



            </div>
        </div>
    </div>
</template>


<script>

export default {
    name: "RydpayApisTestScenarios",
    methods: {
        downloadJson() {
            // Import your JSON file
            import('./ryd-apis-partner.postman_collection-v-2023-12.json').then((data) => {
                const jsonStr = JSON.stringify(data, null, 2); // Stringify the JSON data
                const blob = new Blob([jsonStr], { type: 'application/json' });
                const url = URL.createObjectURL(blob);

                // Create a link and trigger the download
                const link = document.createElement('a');
                link.href = url;
                link.download = 'rydpay-apis-postman-collection-v-2023-12.json';
                document.body.appendChild(link);
                link.click();

                // Clean up
                document.body.removeChild(link);
                URL.revokeObjectURL(url);
            });
        },
        scrollToCard() {
            const hash = this.$route.hash;
            const offset = 75; // Height of your fixed header

            if (hash) {
                const element = document.querySelector(hash);

                if (element) {
                    const elementPosition = element.getBoundingClientRect().top + window.scrollY;
                    window.scrollTo({ top: elementPosition - offset, behavior: 'smooth' });
                }
            }
        },
        scrollToCard2(cardId) {
            const element = document.getElementById(cardId);
            const offset = 70; // Adjust this to the height of your fixed header

            if (element) {
                const elementPosition = element.getBoundingClientRect().top + window.scrollY;
                window.scrollTo({ top: elementPosition - offset, behavior: 'smooth' });
            }
        }
    },
    mounted() {
        this.scrollToCard();
    },
    watch: {
        '$route.hash': function () {
            this.scrollToCard();
        }
    }
}
</script>

<style>
.clickable {
    cursor: pointer;
}

a {
    color: #007bff;
    text-decoration: none;
    padding: 5px 10px;
    border: 1px solid transparent;
    transition: all 0.3s ease;
}

a:hover {
    text-decoration: underline;
    color: #0056b3;
    cursor: pointer;
    border-color: #0056b3;
    background-color: #e7f5ff;
}
</style>