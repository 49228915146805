<template>
  <Breadcrumbs main="rydpay APIs" title="Tutorials"/>

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">

        <div class="card card-absolute" id="overview">
          <div class="card-header bg-primary">
            <h5 class="text-white">Overview</h5>
          </div>
          <div class="card-body">
            <p>
              The Tutorials section focuses on practical, step-by-step instructions and hands-on examples. This section
              is more about the <b>'how'</b>.
            </p>
          </div>
        </div>
        <br/>

        <div class="card" id="client-implementation">
          <div class="card-header bg-success">
            <h5>Client Implementation Best Practices</h5>
          </div>
          <div class="card-body">

            <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i> Handling API Call Timeout</h5>
            <ul class="list-group">
              <li class="list-group-item">
                Some API calls may take longer to complete. If a client times out before an API call finishes, use the
                <code>GET /orders/:id</code> endpoint to get the order state.
              </li>
              <li class="list-group-item">
                <code>POST /v4/order/{orderId}/authorize</code> External systems like PSPs might cause delays. If using
                your own PSP, expect quick responses. Otherwise, set a timeout of at least 30 seconds.
              </li>
              <li class="list-group-item">
                <code>PUT /v4/order/{orderId}/acquire</code> Depending on external systems and onsite equipment, this
                call might be slow. Implement a minimum timeout of 60 seconds.
              </li>
            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i> Order Completion and Customer Departure
            </h5>
            <ul class="list-group">
              <li class="list-group-item">
                An order is considered successfully completed when it reaches the <code>PRODUCT_ACQUIRED</code> state.
                At this point, customers are cleared to leave the gas station.
              </li>
            </ul>
            <br/>


          </div>
        </div>


        <div class="card" id="enum-expansion">
          <div class="card-header bg-primary">
            <h5>Best Practice for Handling Enum Expansion and Validation in API Client Implementations</h5>
          </div>
          <div class="card-body">

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Context:</h5>
            <ul class="list-group">
              <li class="list-group-item">
                As an API provider, we regularly update our enums, such as the order <code>State</code> enumeration, to enhance
                functionality without disrupting existing integrations.
              </li>
              <li class="list-group-item">
                The recent addition of
                <code>PAYMENT_AUTHORIZATION_STARTED</code> and <code>PRODUCT_ACQUISITION_STARTED</code> to the order <code>State</code> enum is a typical
                example of such non-breaking changes. For API clients, adapting to these changes is crucial for seamless
                integration.
              </li>
            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Best Practices:</h5>
            <h6 class="m-t-15"><i class="fa fa-arrow-circle-right font-primary">&nbsp;</i>Expect Enum Expansion:</h6>
            <ul class="list-group">
              <li class="list-group-item">
                Enums, like order <code>State</code> , will evolve over time as the API grows. New states provide more detailed insights into the order process.
              </li>
              <li class="list-group-item">
                Design your application to gracefully handle known (e.g. <code>CREATED</code>, <code>PAYMENT_PENDING</code>, <code>PAYMENT_AUTHORIZED</code>) and new (<code>PAYMENT_AUTHORIZATION_STARTED</code>, <code>PRODUCT_ACQUISITION_STARTED</code>) enum values.
              </li>
            </ul>



            <h6 class="m-t-15"><i class="fa fa-arrow-circle-right font-primary">&nbsp;</i>Flexible Validation Over Strict Checks:</h6>
            <ul class="list-group">
              <li class="list-group-item">
                Implement flexible validation for enum values. Avoid hardcoding checks for each specific enum value.
              </li>
              <li class="list-group-item">
                Use logic that accommodates unforeseen enum values, ensuring that new additions don’t disrupt your application's functionality.
              </li>
            </ul>


            <h6 class="m-t-15"><i class="fa fa-arrow-circle-right font-primary">&nbsp;</i>Backward Compatibility:</h6>
            <ul class="list-group">
              <li class="list-group-item">
                Ensure your application logic remains backward compatible. Introducing new enum values, like <code>PAYMENT_AUTHORIZATION_STARTED</code>, should not break your application.
              </li>
              <li class="list-group-item">
                Handle unknown enum values with default or generic logic, maintaining application stability and user experience.
              </li>
            </ul>

            <h6 class="m-t-15"><i class="fa fa-arrow-circle-right font-primary">&nbsp;</i>Testing for Enum Changes:</h6>
            <ul class="list-group">
              <li class="list-group-item">
                Include scenarios in your testing environment that simulate both known and unknown (<code>PAYMENT_AUTHORIZATION_STARTED</code>, <code>PRODUCT_ACQUISITION_STARTED</code>) enum values.
              </li>
              <li class="list-group-item">
                Consider automated tests to detect changes in enum values and alert you to potential compatibility issues.
              </li>
            </ul>


          </div>
        </div>


      </div>
    </div>
  </div>
</template>

<script>
export default {
  watch: {
    '$route.hash': function () {
      this.scrollToCard();
    }
  },
  mounted() {
    this.scrollToCard();
  },
  methods: {
    scrollToCard() {
      const hash = this.$route.hash;
      const offset = 75; // Height of your fixed header

      if (hash) {
        const element = document.querySelector(hash);

        if (element) {
          const elementPosition = element.getBoundingClientRect().top + window.scrollY;
          window.scrollTo({ top: elementPosition - offset, behavior: 'smooth' });
        }
      }
    },

  }
}
</script>