<template>
  <Breadcrumbs main="Troubleshooting" title="Token Usage"/>

  <div class="container-fluid search-page">
    <div class="row">
      <div class="col-sm-12">

        <!-- CARD 1 START -->
        <div class="card">
          <div class="card-body">
            <div class="theme-form">

              <!-- INPUT GROUP 1 START -->
              <div class="input-group m-b-12">
                <div class="row align-items-center">
                  <div class="col text-center f-w-700">
                    Search Date Range:
                  </div>
                  <div class="col d-flex align-items-center"> <!-- Use d-flex for better alignment -->
                    <label class="me-2" for="fromTimestamp">From:</label>
                    <input v-model="fromTimestamp" type="date" class="form-control"
                           id="fromTimestamp" placeholder="From Timestamp">
                  </div>
                  <div class="col d-flex align-items-center"> <!-- Use d-flex for better alignment -->
                    <label class="me-2" for="fromTimestamp">To:</label>
                    <input v-model="toTimestamp" type="date" class="form-control" placeholder="To Timestamp">
                  </div>

                  <div class="col">
                    <button @click="refreshTokenUsageTable"
                            class="btn btn-pill btn-success btn-air-success active input-group-text">
                      <i class="fa fa-send m-r-10"></i> Refresh Token Usage Table
                    </button>
                  </div>

                  <div class="col"
                       v-if="!isLoadingTokenUsageResponse && tokenUsageResponse && tokenUsageResponse.numberOfResults > 0">
                    <b>{{ tokenUsageResponse.numberOfResults }} rows found.</b>
                  </div>
                </div>
              </div>
              <!-- INPUT GROUP 1 END -->
            </div>
          </div>
        </div>
        <!-- CARD 1 END -->


        <!-- Loading Indicator START -->
        <div v-if="isLoadingTokenUsageResponse" class="loading-indicator">
          <h6 class="sub-title mb-0 text-center">Loading 1... Token Usage</h6>
          <div class="loader-box">
            <div class="loader-17"></div>
          </div>
        </div>
        <!-- Loading Indicator END -->


        <!-- CARD 2 START -->
        <div class="card"
             v-if="!isLoadingTokenUsageResponse && tokenUsageResponse && tokenUsageResponse.numberOfResults > 0">
          <div class="card-body">
            <div class="table-responsive product-table">
              <table class="table table-bordernone table-striped display" id="basic-1">
                <thead class="bg-primary text-white">
                <tr>
                  <th class="bg-primary" scope="col">
                    Error Type
                  </th>
                  <th class="bg-primary" scope="col">
                    HTTP Request Timestamp
                  </th>
                  <th class="bg-primary" scope="col">
                    HTTP Request URI
                  </th>
                  <th class="bg-primary" scope="col">
                    Token Used by HTTP Request
                  </th>
                  <th class="bg-primary" scope="col">
                    HTTP Response Status
                  </th>
                  <th class="bg-primary" scope="col">
                    UUID used by HTTP Request
                  </th>
                  <th class="bg-primary" scope="col">
                    Order Creation Timestamp
                  </th>
                  <th class="bg-primary" scope="col">
                    Token used by Order Creation
                  </th>
                  <th class="bg-primary" scope="col">
                    Order Uuid at Ryd
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="tokenUseageItem in tokenUsageResponse.sortedResult" :key="tokenUseageItem">
                  <td>{{ tokenUseageItem.error_type }}</td>
                  <td>
                    {{ tokenUseageItem.http_request_timestamp }}
                  </td>
                  <td>{{ tokenUseageItem.http_request_uri }}</td>
                  <td>{{ tokenUseageItem.user_group_by_http_request }}</td>
                  <td>{{ tokenUseageItem.http_response_status }}</td>
                  <td>{{ tokenUseageItem.uuid_used_by_http_request }}</td>
                  <td>{{ tokenUseageItem.order_creation_timestamp }}</td>
                  <td>{{ tokenUseageItem.user_group_by_order_creation }}</td>
                  <td>{{ tokenUseageItem.order_uuid_at_ryd }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- CARD 2 END -->
        <div class="card" v-if="tokenUsageResponse && tokenUsageResponse.infoMessage">
          <div class="card-body">
            <div class="text-center fw-bold fs-6 font-success">
              {{ this.tokenUsageResponse.infoMessage }}
            </div>
          </div>
        </div>

        <div class="card" v-if="tokenUsageResponse && tokenUsageResponse.errorMessage">
          <div class="card-body">
            <div class="text-center fw-bold fs-6 font-danger">
              {{ this.tokenUsageResponse.errorMessage }}
            </div>
          </div>
        </div>

        <!-- card 3 start-->
        <div class="card card-absolute m-t-15">
          <div class="card-header bg-info">
            <h5 class="text-white">Important Notes</h5>
          </div>

          <div class="card-body">
            <ul class="list-group">

              <br/>
              <h5><i class="icofont icofont-hand-right font-info">&nbsp;</i> Valid Search Date Range:
              </h5>
              <ul class="list-group">
                <li class="list-group-item">Ensure you specify a valid search date range where
                  the interval between the 'From' and 'To' dates <b>does not exceed 7 days</b>, to maintain optimal
                  search performance.
                </li>
              </ul>

              <br/>
              <h5><i class="icofont icofont-hand-right font-info">&nbsp;</i> Unique Combinations with Latest Data:
              </h5>
              <ul class="list-group">
                <li class="list-group-item">Our system is designed to display
                  only the most recent instance of each unique API call.
                </li>
                <li class="list-group-item">
                  This means that if an API call with specific
                  parameters (<code>UUID used by HTTP Request, HTTP Request URI, Token Used by HTTP Request, and HTTP
                  Response Status </code>) has
                  been executed
                  multiple times, only the latest one (based on the execution timestamp) will be shown.
                </li>
                <li class="list-group-item">
                  This approach ensures that you're always looking at the most up-to-date information, providing a
                  clearer and more efficient way to monitor and review API activities on our portal.
                </li>
              </ul>


              <br/>
              <h5><i class="icofont icofont-hand-right font-info">&nbsp;</i> Example Scenario:</h5>
              <ul class="list-group">
                <li class="list-group-item">Suppose you make an API call to <code>/v4/invoices/:orderId</code> and it
                  fails.
                </li>
                <li class="list-group-item">If you retry the same call and it fails again, our system will only display
                  the result of the latest attempt.
                </li>
              </ul>

              <br/>
              <h5><i class="icofont icofont-hand-right font-info">&nbsp;</i> Latest 500 Failed Requests:</h5>
              <ul class="list-group">
                <li class="list-group-item">To ensure simplicity and ease of use, this page displays only the latest 500
                  failed HTTP requests associated with your account and within the selected date range. This approach
                  helps in focusing on the most recent
                  and potentially relevant API call issues.
                </li>
                <li class="list-group-item"><code>No Pagination</code>: In line with our goal to keep the interface
                  straightforward,
                  we do not provide pagination on this page. You can view a concise list of the most recent failed
                  requests without the need to navigate through multiple pages.
                </li>
              </ul>


            </ul>
          </div>
        </div>
        <!-- card 3 end-->


      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {getUserAddDataFromLocalStorage} from "@/utils/userUtils";
import {PARTNER_ANALYTICS_API_KEY, PARTNER_ANALYTICS_SERVER_URL} from "@/constants/constants";

let primary = localStorage.getItem('primary_color') || '#24695c';
let secondary = localStorage.getItem('secondary_color') || '#ba895d';

export default {
  data() {
    return {
      showAlertTimestamps: false,
      fromTimestamp: '',
      toTimestamp: '',
      loggedInUserId: '',
      isLoadingTokenUsageResponse: false,
      tokenUsageResponse: {
        numberOfResults: 0,
        sortedResult: [],
        errorMessage: null,
        infoMessage: null
      },
    }
  },
  mounted() {
    const {uid, userGroup, isUserGroupRyd} = getUserAddDataFromLocalStorage();
    this.loggedInUserId = uid;
    this.loggedInUserIsRyd = isUserGroupRyd;
    this.loggedInUserGroup = userGroup;
    this.partnerMetricsFor = userGroup;
    this.initializeParameters();
    this.checkAndTriggerSearch();
    this.fetchTokenUsageData(this.fromTimestamp, this.toTimestamp);
  },
  methods: {
    async refreshTokenUsageTable() {
      if (!this.validateTimestamps()) {
        this.showAlertTimestamps = true;
        return;
      }

      this.showAlertTimestamps = false;
      this.isLoadingTokenUsageResponse = true;
      await this.fetchTokenUsageData(this.fromTimestamp, this.toTimestamp);
    },
    validateTimestamps() {
      if (!this.fromTimestamp || !this.toTimestamp) {
        this.showAlertTimestamps = true;
        return false;
      }

      const from = new Date(this.fromTimestamp);
      const to = new Date(this.toTimestamp);

      if (to < from) {
        this.showAlertTimestamps = true;
        return false;
      }

      const sevenDays = 7 * 24 * 60 * 60 * 1000;
      if (to - from > sevenDays) {
        this.showAlertTimestamps = true;
        this.tokenUsageResponse.infoMessage = null;
        this.tokenUsageResponse.errorMessage = "Ensure you specify a valid search date range where the interval between the 'From' and 'To' dates does not exceed 7 days, to maintain optimal search performance.";
        return false;
      }

      this.showAlertTimestamps = false;
      return true;
    },
    convertDateToISOString(date) {
      return new Date(date).toISOString();
    },
    addXDayAndConvertDateToISOString(date, days) {
      if (date) {
        let dateInputValue = new Date(date);
        dateInputValue.setDate(dateInputValue.getDate() + days);
        return dateInputValue.toISOString();
      }
    },
    getDefaultFromDate() {
      const now = new Date();
      now.setDate(now.getDate() - 2);
      return this.formatDate(now.toISOString());
    },
    getDefaultToDate() {
      const now = new Date();
      now.setDate(now.getDate());
      return this.formatDate(now.toISOString());
    },
    formatDate(dateString) {
      return dateString.split('T')[0]; // Returns the date part in 'YYYY-MM-DD' format
    },
    initializeParameters() {
      const queryParams = this.$route.query;
      this.fromTimestamp = queryParams.from || this.getDefaultFromDate();
      this.toTimestamp = queryParams.to || this.getDefaultToDate();

      // Format dates to 'YYYY-MM-DD' if they are not empty
      this.fromTimestamp = this.fromTimestamp ? this.formatDate(this.fromTimestamp) : '';
      this.toTimestamp = this.toTimestamp ? this.formatDate(this.toTimestamp) : '';
    },
    checkAndTriggerSearch() {
      const queryParams = this.$route.query;
      if (queryParams.from && queryParams.to) {
        this.refreshTokenUsageTable();
      }
    },
    async fetchTokenUsageData(fromTimestamp, toTimestamp) {
      this.isLoadingTokenUsageResponse = true;
      this.tokenUsageResponse.errorMessage = null;
      this.tokenUsageResponse.infoMessage = null;

      try {
        const response = await axios.get(`${PARTNER_ANALYTICS_SERVER_URL}/pp-token-usage`, {
          params: {
            from: this.convertDateToISOString(fromTimestamp),
            to: this.addXDayAndConvertDateToISOString(toTimestamp, 1),
          },
          headers: {
            'x-txn-auth-token': PARTNER_ANALYTICS_API_KEY,
            'uid': this.loggedInUserId,
          }
        });

        let httpResponseStatus = response.status;
        if (httpResponseStatus > 399) {
          this.tokenUsageResponse = {};
          this.tokenUsageResponse.errorMessage = "A technical error occurred.";
        } else {
          this.tokenUsageResponse = response.data;
        }

        if (this.tokenUsageResponse && this.tokenUsageResponse.numberOfResults === 0) {
          this.tokenUsageResponse.infoMessage = "No entry was found.";
        }

      } catch (error) {
        this.tokenUsageResponse.errorMessage = "A technical error occurred.";
        console.error('Error fetchTokenUsageData:', error);
      } finally {
        this.isLoadingTokenUsageResponse = false;
      }
    },
  }
}
</script>


<style lang="scss" scoped>
@import "@/assets/scss/utils/variables";
@import "@/assets/scss/components/datatable";
</style>
<style scoped>
@import "@/assets/css/productlist.css";
</style>
<style>
.table-striped > tbody > tr:nth-child(odd) > td {
  background-color: #f2f2f2; /* Light gray for odd rows */
}

.table-striped > tbody > tr:nth-child(even) > td {
  background-color: #ffffff; /* White for even rows */
}
</style>
