<template>
  <Breadcrumbs main="Platform Status" title="ryd Platform: Current Status"/>

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <!-- CARD 1 START  -->
        <div class="card">
          <div class="card-header bg-success">
            <h5>ryd Services</h5>
          </div>
          <div class="card-body">
            <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Description</h5>
            <ul class="list-group">
              <li class="list-group-item">The "Ryd Services" section provides a comprehensive overview of the operational status
                and health of key services integral to the ryd platform. This category is crucial for partners and users
                who rely on the seamless functioning of our system for pay-at-pump services.
              </li>
            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Status (TODO)</h5>
            <div class="table-responsive product-table">
              <table class="display" id="basic-1">
                <thead>
                <tr>
                  <th class="sorting_asc" tabindex="0" aria-controls="basic-1" rowspan="1"
                      colspan="1" aria-sort="ascending"
                      aria-label="" style="width: 8%;">
                    Service Category
                  </th>
                  <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1"
                      aria-label="" style="width: 8%;">
                    Status
                  </th>
                  <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1"
                      aria-label="" style="width: 16%;">
                    Comment
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>POI Service</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Order Service</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Payment Service</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Invoice Service</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Webhook Service</td>
                  <td></td>
                  <td></td>
                </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
        <!-- CARD 1 START  -->

        <!-- CARD 2 START  -->
        <div class="card">
          <div class="card-header bg-danger">
            <h5>Gas Station System Status</h5>
          </div>
          <div class="card-body">
            <h5><i class="icofont icofont-hand-right font-danger">&nbsp;</i>Description</h5>
            <ul class="list-group">
              <li class="list-group-item">The "Gas Station System Status" section is dedicated to monitoring and
                reporting the operational health of cashier systems across our network of partner gas stations. This
                section is crucial for ryd and its partners, including gas station brands and operators, to ensure
                seamless transaction processing at the point of sale. Here, we provide real-time status updates on the
                cashier systems of various gas station brands in our network.
              </li>
            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-danger">&nbsp;</i>Status (TODO)</h5>
            <div class="table-responsive product-table">
              <table class="display" id="basic-1">
                <thead>
                <tr>
                  <th class="sorting_asc" tabindex="0" aria-controls="basic-1" rowspan="1"
                      colspan="1" aria-sort="ascending"
                      aria-label="" style="width: 8%;">
                    Gas Station Brand
                  </th>
                  <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1"
                      aria-label="" style="width: 8%;">
                    Status
                  </th>
                  <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1"
                      aria-label="" style="width: 16%;">
                    Comment
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>BP/ARAL</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Allguth</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>ESSO</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>...</td>
                  <td></td>
                  <td></td>
                </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
        <!-- CARD 2 START  -->

        <!-- CARD 3 START  -->
        <div class="card">
          <div class="card-header bg-success">
            <h5>ryd Infrastructure and Network</h5>
          </div>
          <div class="card-body">
            <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Description</h5>
            <ul class="list-group">
              <li class="list-group-item">The "Ryd Infrastructure and Network" section offers a detailed view of the
                current operational status of the fundamental infrastructure and network components that underpin the
                ryd platform. This category is vital for partners and stakeholders who depend on the robustness and
                reliability of our underlying technological framework.
              </li>
            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Status (TODO)</h5>
            <div class="table-responsive product-table">
              <table class="display" id="basic-1">
                <thead>
                <tr>
                  <th class="sorting_asc" tabindex="0" aria-controls="basic-1" rowspan="1"
                      colspan="1" aria-sort="ascending"
                      aria-label="" style="width: 8%;">
                    Category
                  </th>
                  <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1"
                      aria-label="" style="width: 8%;">
                    Status
                  </th>
                  <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1"
                      aria-label="" style="width: 16%;">
                    Comment
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>Network Connectivity</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Physical and Cloud Infrastructure</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Firewall and Security Systems</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Storage Systems</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Database Services</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Backup and Recovery Systems</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Third-Party Services</td>
                  <td></td>
                  <td></td>
                </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
        <!-- CARD 3 START  -->


        <!-- card 4 start-->
        <div class="card card-absolute m-t-15">
          <div class="card-header bg-info">
            <h5 class="text-white">Important Notes: Status Indicators</h5>
          </div>

          <div class="card-body">
            <div class="table-responsive product-table">
              <table class="display" id="basic-1">
                <thead>
                <tr>
                  <th class="sorting_asc" tabindex="0" aria-controls="basic-1" rowspan="1"
                      colspan="1" aria-sort="ascending"
                      aria-label="" style="width: 8%;">
                    Status Name
                  </th>
                  <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1"
                      aria-label="" style="width: 2%;">
                    Icon
                  </th>
                  <th class="sorting" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1"
                      aria-label="" style="width: 24%;">
                    Description
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>Operational</td>
                  <td></td>
                  <td>The service is fully functional without any interruptions or issues. All systems are performing as expected.</td>
                </tr>
                <tr>
                  <td>Degraded Performance</td>
                  <td></td>
                  <td>The service is operational but is experiencing some slowness or minor issues that could affect performance. This status indicates that the service is not operating at its optimal capacity but is still functional.</td>
                </tr>
                <tr>
                  <td>Partial Outage</td>
                  <td></td>
                  <td>A significant aspect of the service is not functioning properly. This status implies that while some parts of the service are operational, others are facing disruptions, leading to limited functionality.</td>
                </tr>
                <tr>
                  <td>Major Outage</td>
                  <td></td>
                  <td>The service is currently down or facing major issues, leading to a complete or near-complete interruption of service. This status is used for critical problems that require immediate attention.</td>
                </tr>
                <tr>
                  <td>Maintenance</td>
                  <td></td>
                  <td>Scheduled maintenance is in progress. This status is used to indicate planned service interruptions or updates, during which certain functionalities might be temporarily unavailable.</td>
                </tr>
                <tr>
                  <td>Under Investigation</td>
                  <td></td>
                  <td>There is a potential issue being investigated. This status is used when there are reports or signs of a problem, but the full scope and impact are still being determined.</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- card 4 end-->

      </div>
    </div>
  </div>
</template>
<script setup>
</script>