<template>
  <li class="profile-nav onhover-dropdown pe-0 py-0">
    <div class="media profile-media">
      <div class="media-body">
        <span>{{ firstName }}</span>
        <p class="mb-0 font-roboto">
          {{ lastName }} <i class="middle fa fa-angle-down"></i>
        </p>
      </div>
    </div>
    <ul class="profile-dropdown onhover-show-div">
      <li>
        <router-link :to="'/pages/account-settings'"><vue-feather type="settings"></vue-feather><span>Account Settings</span></router-link>
      </li>
      <li>
        <router-link :to="'/users/profile'"><vue-feather type="user"></vue-feather><span>User Profile</span></router-link>
      </li>
      <li>
        <a @click="logout"><vue-feather type="log-in"></vue-feather><span>Log out</span></a>
      </li>
    </ul>
  </li>
</template>

<script>

export default {
  name: 'Profile',
  data() {
    return {
      email: '',
    };
  },
  created() {
    // When the component is created, get the user's email from local storage and assign it
    const userAddData = JSON.parse(localStorage.getItem('userAddData'));
    if (userAddData && userAddData.firstName) {
      this.firstName = userAddData.firstName;
      this.lastName = userAddData.lastName;
    }
  },
  methods: {
    logout: function () {
      this.$router.replace('/auth/login');
      localStorage.removeItem('User');
      localStorage.removeItem('userAddData')
    }
  },
};
</script>
