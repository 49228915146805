<template>
    <Breadcrumbs main="Deeplinking Registration" title="API Reference" />

    <div class="container-fluid">
        <div class="col-sm-12 col-md-12 d-flex justify-content-center">
            <button @click="downloadJson">Download API Spec in json according to openAPI 3.0.0</button>
        </div>
        <div class="swagger" id="swagger"></div>
    </div>
</template>


<script>
import swaggerui from 'swagger-ui';
import 'swagger-ui/dist/swagger-ui.css';

export default {
    name: "swagger",
    mounted() {
        let meta = document.createElement('meta');
        meta.name = "referrer";
        meta.content = "no-referrer"; // or use "origin" or other policies as needed
        document.getElementsByTagName('head')[0].appendChild(meta);

        const spec = require('./ryd-api-dosc-v-20231115.json');
        swaggerui({
            spec: spec,
            dom_id: '#swagger',
            displayRequestDuration: true,  // Shows the request duration
            deepLinking: true,            // Enables deep linking for tags and operations
        })
    },
    methods: {
        downloadJson() {
            // Import your JSON file
            import('./ryd-api-dosc-v-20231115.json').then((data) => {
                const jsonStr = JSON.stringify(data, null, 2); // Stringify the JSON data
                const blob = new Blob([jsonStr], { type: 'application/json' });
                const url = URL.createObjectURL(blob);

                // Create a link and trigger the download
                const link = document.createElement('a');
                link.href = url;
                link.download = 'ryd-deeplinking-registration-api-spec.json';
                document.body.appendChild(link);
                link.click();

                // Clean up
                document.body.removeChild(link);
                URL.revokeObjectURL(url);
            });
        },
    },
}
</script>