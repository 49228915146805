<template>
  <Breadcrumbs main="Platform Status" title="Scheduled Maintenance" />

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        Platform Status - Scheduled Maintenance: coming soon
      </div>
    </div>
  </div>
</template>
<script setup>
</script>