import {USER_GROUP_RYD} from "@/constants/constants";

export function getUserAddDataFromLocalStorage() {
    try {
        const userAddDataAsStr = localStorage.getItem('userAddData');
        const userAddData = userAddDataAsStr ? JSON.parse(userAddDataAsStr) : null;
        const uid = userAddData ? userAddData.uid : null;
        const userGroup = userAddData ? userAddData.userGroup : null;
        const isUserGroupRyd = userAddData && userAddData.userGroup ? userAddData.userGroup === USER_GROUP_RYD : false;
        return {userAddData, uid, isUserGroupRyd, userGroup};
    } catch (e) {
        console.error('Error parsing user data from localStorage', e);
        return {userAddData: null, uid: null, isUserGroupRyd: false};
    }
}