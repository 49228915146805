<template>
  <!-- Full Page Loading Overlay -->
  <div v-if="isLoading">
    <h6 class="sub-title mb-0 text-center">Loading ...</h6>
    <div class="loader-box">
      <div class="loader-19"></div>
    </div>
  </div>

  <div class="col-sm-12" v-if="!isLoading">
    <div class="card">
      <div class="card-header pb-0 bg-primary">
        <h3>Your User Details</h3>
      </div>
      <div class="card-body">
        <div class="mb-3 row border-bottom">
          <label class="col-sm-3 col-md-2 col-form-label f-w-700" for="inputEmail3">Email</label>
          <div class="col-sm-9">
            <p>{{ userAddData.email }}</p>
          </div>
        </div>
        <div class="mb-3 row border-bottom">
          <label class="col-sm-3 col-md-2 col-form-label f-w-700" for="inputEmail3">Firstname</label>
          <div class="col-sm-9">
            <p>{{ userAddData.firstName }}</p>
          </div>
        </div>
        <div class="mb-3 row border-bottom">
          <label class="col-sm-3 col-md-2 col-form-label f-w-700" for="inputEmail3">Lastname</label>
          <div class="col-sm-9">
            <p>{{ userAddData.lastName }}</p>
          </div>
        </div>
        <div class="mb-3 row border-bottom">
          <label class="col-sm-3 col-md-2 col-form-label f-w-700" for="inputEmail3">User Group</label>
          <div class="col-sm-9">
            <p>{{ userAddData.userGroup }}</p>
          </div>
        </div>
        <div class="row mb-3 border-bottom">
          <label class="col-sm-3 col-md-2 col-form-label pb-0 f-w-700">Roles</label>
          <div class="col-sm-9">
            <ul class="list-group alternating-list">
              <li class="list-group-item col-sm-6 col-md-4 col-lg-3 border border-primary"
                  v-for="role in userAddData.roles" :key="role">
                {{ role }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import {auth} from '@/firebase/firebaseInit';
import axios from 'axios';
import {RPP_API_SERVER_URL} from "@/constants/constants";


export default {

  name: 'userDetails',
  data() {
    return {
      userAddData: {},
      isLoading: false,
    };
  },
  mounted() {
    this.fetchUserDetails();
  },
  methods: {
    async fetchUserDetails() {

      const user = auth.currentUser;
      const uid = user ? user.uid : null;
      this.currentUserUid = uid;

      try {
        this.isLoading = true;
        const response = await axios.get(`${RPP_API_SERVER_URL}?singleUserId=${uid}`, {
          headers: {
            'x-txn-auth-token': process.env.VUE_APP_RPP_USERS_CLOUD_FUNCTION_API_KEY
          }
        });
        this.userAddData = response.data;
      } catch (error) {
        console.error('API fetch error:', error);
      } finally {
        this.isLoading = false;
      }

    }
  }
};

</script>
