<template>
  <div class="container-fluid search-page">
    <div class="row">
      <div class="col-sm-12">

        <!-- CARD 1 START -->
        <div class="card">
          <div class="card-body">
            <div class="theme-form">

              <!-- INPUT GROUP 1 START -->
              <div class="input-group m-b-12">
                <div class="row align-items-center">
                  <div class="col text-center f-w-700">
                    Histogram Parameters:
                  </div>
                  <div class="col d-flex align-items-center"> <!-- Use d-flex for better alignment -->
                    <label class="me-2" for="fromTimestamp">From:</label>
                    <input v-model="fromTimestamp" type="date" class="form-control"
                           id="fromTimestamp" placeholder="From Timestamp">
                  </div>
                  <div class="col d-flex align-items-center"> <!-- Use d-flex for better alignment -->
                    <label class="me-2" for="toTimestamp">To:</label>
                    <input v-model="toTimestamp" type="date" class="form-control" placeholder="To Timestamp">
                  </div>
                  <div class="col d-flex align-items-center" v-if="loggedInUserIsRyd"> <!-- Use d-flex for better alignment -->
                    <label class="me-2" for="partnerMetricsFor">For Partner:</label>
                    <select class="form-control border border-primary min-width-max-content" id="partnerMetricsFor" v-model="partnerMetricsFor"
                            required>
                      <option v-for="partnerName in partnerNames" :key="partnerName" :value="partnerName">
                        {{ partnerName }}
                      </option>
                    </select>
                  </div>
                  <div class="col d-flex align-items-center"> <!-- Use d-flex for better alignment -->
                    <label class="me-2" for="histogramInterval">Histogram Interval:</label>
                    <select class="form-control border border-primary min-width-max-content" id="histogramInterval" v-model="histogramInterval"
                            required>
                      <option value="day">day</option>
                      <option value="hour">hour</option>
                    </select>
                  </div>

                  <div class="col">
                    <button @click="refreshHistogram" class="btn btn-pill btn-success btn-air-success active input-group-text">
                      <i class="fa fa-send m-r-10"></i> Refresh Histogram
                    </button>
                  </div>

                  <div class="col">
                    <button @click="copyShareableLink" class="btn btn-info rounded-pill m-l-5"
                            data-bs-toggle="popover"
                            data-bs-placement="left"
                            data-bs-title="Information"
                            title="Click this button to generate a full URL, including your input fields, making it easy to share with others."
                            data-bs-content="Click this button to generate a full URL, including your input fields, making it easy to share with others.">
                      <i class="fa fa-share"></i> Share
                    </button>
                  </div>


                </div>
              </div>
              <!-- INPUT GROUP 1 END -->
            </div>
          </div>
        </div>
        <!-- CARD 1 END -->


        <!-- Loading Indicator START -->
        <div v-if="isLoadingHistogramApiGatewayHttpRequests" class="loading-indicator">
          <h6 class="sub-title mb-0 text-center">Loading 1... Histogram of HTTP Requests</h6>
          <div class="loader-box">
            <div class="loader-17"></div>
          </div>
        </div>
        <!-- Loading Indicator END -->

        <!-- CARD 2 START -->
        <div class="card" v-if="!isLoadingHistogramApiGatewayHttpRequests && termsHistogramHttpResponseStatus">
          <div class="card-body">
            <!-- Check if series data is empty -->
            <div v-if="termsHistogramHttpResponseStatus.labels && termsHistogramHttpResponseStatus.labels.length === 0">
              <!-- Display only the title or a placeholder -->
              <h6 class="chart-title">HTTP Request Distribution: Ryd Partner to Ryd API Gateway</h6>
              <span>Comparative Analysis of Error Rate - HTTP Requests with Status ≥ 400 vs. Total Requests</span>
              <br/><br/>
              <div class="error-message">No data is available.</div>
            </div>
            <div v-else id="basic-apex-1">
              <!-- Display the chart if series data is not empty -->
              <apexchart type="area" height="350" ref="chart" :options="chartOptions" :series="series"></apexchart>
            </div>



            <!-- BUTTON START-->
            <br/>
            <div class="row" v-if="loggedInUserIsRyd">
              <div class="col">
                <div
                    v-if="termsHistogramHttpResponseStatus && termsHistogramHttpResponseStatus.graylogQueryUrl">
                  <a :href="termsHistogramHttpResponseStatus.graylogQueryUrl" target="_blank"
                     class="btn btn-pill btn-success btn-lg" title="Go To Graylog Query">
                    Go To Graylog Query to See More Details
                  </a>
                </div>
              </div>
            </div>
          <!-- BUTTON END-->

          </div>
        </div>
        <!-- CARD 2 END -->

        <!-- Loading Indicator 2 START -->
        <div v-if="isLoadingHistogramRequestUrlNormalised" class="loading-indicator">
          <h6 class="sub-title mb-0 text-center">Loading 2 ... Histogram of Request URL</h6>
          <div class="loader-box">
            <div class="loader-18"></div>
          </div>
        </div>
        <!-- Loading Indicator 2 END -->


        <!-- CARD 3 START -->
        <div class="card" v-if="!isLoadingHistogramRequestUrlNormalised && termsHistogramRequestUrlNormalised">
          <div class="card-body">
            <!-- Check if series data is empty -->
            <div v-if="termsHistogramRequestUrlNormalised.labels && termsHistogramRequestUrlNormalised.labels.length === 0">
              <!-- Display only the title or a placeholder -->
              <h6 class="chart-title">Failed HTTP Request Histogram: Ryd Partner to Ryd API Gateway</h6>
              <span>Analysis of Failed HTTP Requests and Their Frequencies</span>
              <br/><br/>
              <div class="error-message">No data is available.</div>
            </div>
            <div v-else id="basic-apex-2">
              <!-- Display the chart if series data is not empty -->
              <apexchart type="area" height="350" ref="chart" :options="chartOptions2" :series="series2"></apexchart>
            </div>

            <!-- BUTTON START-->
            <br/>
            <div class="row" v-if="loggedInUserIsRyd">
              <div class="col">
                <div
                    v-if="termsHistogramRequestUrlNormalised && termsHistogramRequestUrlNormalised.graylogQueryUrl">
                  <a :href="termsHistogramRequestUrlNormalised.graylogQueryUrl" target="_blank"
                     class="btn btn-pill btn-success btn-lg" title="Go To Graylog Query">
                    Go To Graylog Query to See More Details
                  </a>
                </div>
              </div>
            </div>
            <!-- BUTTON END-->

          </div>

        </div>
        <!-- CARD 3 END -->

      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import {getUserAddDataFromLocalStorage} from "@/utils/userUtils";
import {PARTNER_ANALYTICS_API_KEY, PARTNER_ANALYTICS_SERVER_URL} from "@/constants/constants";
import getParamsFromHash from "@/utils/urlUtils";

let primary = localStorage.getItem('primary_color') || '#24695c';
let secondary = localStorage.getItem('secondary_color') || '#ba895d';

export default {
  props: {
    partnerNames: [],
  },
  data() {
    return {
      showAlertTimestamps: false,
      fromTimestamp: '',
      toTimestamp: '',
      loggedInUserId: '',
      loggedInUserIsRyd: false,
      loggedInUserGroup: null,
      partnerMetricsFor: '',
      isLoadingHistogramApiGatewayHttpRequests: false,
      isLoadingHistogramRequestUrlNormalised: false,
      histogramInterval: 'hour',
      termsHistogramHttpResponseStatus: {
        totalRequests: [],
        failedRequests: [],
        errorRates: [],
        labels: [],
      },
      termsHistogramRequestUrlNormalised: {
        series: [],
        labels: [],
        colors: [],
        graylogQueryUrl: null
      }
    }
  },
  computed: {
    series() {
      if (this.termsHistogramHttpResponseStatus) {
        return [{
          name: "Error Rate",
          data: this.termsHistogramHttpResponseStatus.errorRates,
        }];
      }
      return [];
    },

    chartOptions() {
      const isHourInterval = this.histogramInterval === "hour"; // Accessing Vue instance property
      return {
        chart: {
          height: 350,
          type: 'area',
          zoom: { enabled: false },
          toolbar: { show: false },
        },
        dataLabels: { enabled: false },
        stroke: { curve: 'straight' },
        title: {
          text: 'HTTP Request Distribution: Ryd Partner to Ryd API Gateway',
          align: 'left',
        },
        subtitle: {
          text: 'Comparative Analysis of Error Rate - HTTP Requests with Status ≥ 400 vs. Total Requests',
          align: 'left',
        },
        labels: this.termsHistogramHttpResponseStatus ? this.termsHistogramHttpResponseStatus.labels : [],
        xaxis: {
          type: 'datetime',
          labels: {
            formatter: function(value, timestamp) {
              // Convert timestamp to Date object and format it
              const date = new Date(timestamp);
              const formattedDate = date.getFullYear() + '-' +
                  ('0' + (date.getMonth() + 1)).slice(-2) + '-' +
                  ('0' + date.getDate()).slice(-2);
              return formattedDate;
            }
          }
        },
        yaxis: {
          opposite: true,
          labels: {
            formatter: (value) => `${value.toFixed(3)}%`
          }
        },
        tooltip: {
          enabled: true,
          x: {
            formatter: (value) => {
              const date = new Date(value);
              let formattedDate = date.getFullYear() + '-' +
                  ('0' + (date.getMonth() + 1)).slice(-2) + '-' +
                  ('0' + date.getDate()).slice(-2);

              if (isHourInterval) {
                // Format as 'yyyy-MM-dd HH:00' for hourly interval
                let hours = ('0' + date.getHours()).slice(-2);
                return `${formattedDate} ${hours}:00`;
              } else {
                // Format as 'yyyy-MM-dd' for daily interval
                return formattedDate;
              }
            }
          },
          y: {
            formatter: (value, { seriesIndex, dataPointIndex }) => {
              const total = this.termsHistogramHttpResponseStatus.totalRequests[dataPointIndex];
              const failed = this.termsHistogramHttpResponseStatus.failedRequests[dataPointIndex];
              return `${value}% (Total Count: ${total}, Failed Count: ${failed})`;
            }
          },
          marker: { show: true }
        },
        colors: [primary] // Ensure 'primary' is defined in data or as a computed property
      };
    }
    ,
    series2() {
      if (this.termsHistogramRequestUrlNormalised) {
        return this.termsHistogramRequestUrlNormalised.series;
      }
      return [];
    },
    chartOptions2() {
      const isHourInterval = this.histogramInterval === "hour"; // Accessing Vue instance property
      return {
        chart: {
          height: 350,
          type: "area",
          toolbar: {
            show: false,
          },
        },
        title: {
          text: 'Failed HTTP Request Histogram: Ryd Partner to Ryd API Gateway',
          align: 'left',
        },
        subtitle: {
          text: 'Analysis of Failed HTTP Requests and Their Frequencies',
          align: 'left',
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          type: "datetime",
          categories: this.termsHistogramRequestUrlNormalised ? this.termsHistogramRequestUrlNormalised.labels : [],
          labels: {
            formatter: function(value, timestamp) {
              // Convert timestamp to Date object and format it
              const date = new Date(timestamp);
              const formattedDate = date.getFullYear() + '-' +
                  ('0' + (date.getMonth() + 1)).slice(-2) + '-' +
                  ('0' + date.getDate()).slice(-2);
              return formattedDate;
            }
          }
        },
        tooltip: {
          enabled: true,
          x: {
            formatter: (value) => {
              const date = new Date(value);
              let formattedDate = date.getFullYear() + '-' +
                  ('0' + (date.getMonth() + 1)).slice(-2) + '-' +
                  ('0' + date.getDate()).slice(-2);

              if (isHourInterval) {
                // Format as 'yyyy-MM-dd HH:00' for hourly interval
                let hours = ('0' + date.getHours()).slice(-2);
                return `${formattedDate} ${hours}:00`;
              } else {
                // Format as 'yyyy-MM-dd' for daily interval
                return formattedDate;
              }
            }
          },
          y: {
            formatter: (value, { seriesIndex, dataPointIndex }) => {
              //const total = this.termsHistogramRequestUrlNormalised.data[dataPointIndex];
              //return `${value}% (Total Count: ${total})`;
              return `${value}`;
            }
          },
        },
        colors: this.termsHistogramRequestUrlNormalised ? this.termsHistogramRequestUrlNormalised.colors : [],
      };
    }
  },
  mounted() {
    const {uid, userGroup, isUserGroupRyd} = getUserAddDataFromLocalStorage();
    this.loggedInUserId = uid;
    this.loggedInUserIsRyd = isUserGroupRyd;
    this.loggedInUserGroup = userGroup;
    this.partnerMetricsFor = userGroup;
    this.initializeParameters();
    this.checkAndTriggerSearch();
    this.fetchHistogramApiGatewayHttpRequests(this.fromTimestamp, this.toTimestamp);
    this.fetchHistogramRequestUrlNormalised(this.fromTimestamp, this.toTimestamp);
  },
  methods: {
    async refreshHistogram() {
      if (!this.validateTimestamps()) {
        this.showAlertTimestamps = true;
        return;
      }

      this.showAlertTimestamps = false;

      try {
        await Promise.all([
          this.fetchHistogramApiGatewayHttpRequests(this.fromTimestamp, this.toTimestamp),
          this.fetchHistogramRequestUrlNormalised(this.fromTimestamp, this.toTimestamp)
        ]);
      } catch (error) {
        console.error("An error occurred while fetching data:", error);
        // Handle the error appropriately
      }

    },
    validateTimestamps() {
      if (!this.fromTimestamp || !this.toTimestamp) {
        this.showAlertTimestamps = true;
        return false;
      }

      const from = new Date(this.fromTimestamp);
      const to = new Date(this.toTimestamp);

      if (to < from) {
        this.showAlertTimestamps = true;
        return false;
      }

      const sixMonths = 2 * 30 * 24 * 60 * 60 * 1000; // Approximately 2 months in milliseconds
      if (to - from > sixMonths) {
        this.showAlertTimestamps = true;
        return false;
      }

      this.showAlertTimestamps = false;
      return true;
    },
    async fetchHistogramApiGatewayHttpRequests(fromTimestamp, toTimestamp) {
      this.isLoadingHistogramApiGatewayHttpRequests = true;
      try {
        const response = await axios.get(`${PARTNER_ANALYTICS_SERVER_URL}/terms-histogram/api-gateway-http-requests/http-response-status-code`, {
          params: {
            from: this.convertDateToISOString(fromTimestamp),
            to: this.addXDayAndConvertDateToISOString(toTimestamp, 1),
            histogramInterval: this.histogramInterval,
            size: 100,
            termsHistogramField: 'http_response_status',
            partnerMetricsFor: this.partnerMetricsFor
          },
          headers: {
            'x-txn-auth-token': PARTNER_ANALYTICS_API_KEY,
            'uid': this.loggedInUserId,
          }
        });

        let httpResponseStatus = response.status;
        if (httpResponseStatus > 399) {
          this.termsHistogramHttpResponseStatus = null;
        } else {
          this.termsHistogramHttpResponseStatus = response.data;
        }

        //console.log("termsHistogramHttpResponseStatus", this.termsHistogramHttpResponseStatus);

      } catch (error) {
        this.termsHistogramHttpResponseStatus = null;
        console.error('Error fetching API Gateway HTTP Requests:', error);
      } finally {
        this.isLoadingHistogramApiGatewayHttpRequests = false;
      }
    },
    convertDateToISOString(date) {
      return new Date(date).toISOString();
    },
    addXDayAndConvertDateToISOString(date, days) {
      if (date) {
        let dateInputValue = new Date(date);
        dateInputValue.setDate(dateInputValue.getDate() + days);
        return dateInputValue.toISOString();
      }
    },
    getDefaultFromDate() {
      const now = new Date();
      now.setDate(now.getDate() - 2);
      return this.formatDate(now.toISOString());
    },
    getDefaultToDate() {
      const now = new Date();
      now.setDate(now.getDate());
      return this.formatDate(now.toISOString());
    },
    formatDate(dateString) {
      return dateString.split('T')[0]; // Returns the date part in 'YYYY-MM-DD' format
    },
    initializeParameters() {
      let paramsFromHash = getParamsFromHash(window.location.hash);


      this.fromTimestamp = paramsFromHash.from || this.getDefaultFromDate();
      this.toTimestamp = paramsFromHash.to || this.getDefaultToDate();

      // Format dates to 'YYYY-MM-DD' if they are not empty
      this.fromTimestamp = this.fromTimestamp ? this.formatDate(this.fromTimestamp) : '';
      this.toTimestamp = this.toTimestamp ? this.formatDate(this.toTimestamp) : '';

      this.partnerMetricsFor = paramsFromHash.partnerMetricsFor || this.loggedInUserGroup;
      this.histogramInterval = paramsFromHash.histogramInterval || 'hour';
    },
    checkAndTriggerSearch() {
      const queryParams = this.$route.query;
      if (queryParams.from && queryParams.to) {
        this.refreshHistogram();
      }
    },
    async fetchHistogramRequestUrlNormalised(fromTimestamp, toTimestamp) {
      this.isLoadingHistogramRequestUrlNormalised = true;
      try {
        const response = await axios.get(`${PARTNER_ANALYTICS_SERVER_URL}/terms-histogram/api-gateway-http-requests/request-url-normalized`, {
          params: {
            from: this.convertDateToISOString(fromTimestamp),
            to: this.addXDayAndConvertDateToISOString(toTimestamp, 1),
            histogramInterval: this.histogramInterval,
            size: 100,
            termsHistogramField: 'requestUrlNormalized',
            partnerMetricsFor: this.partnerMetricsFor
          },
          headers: {
            'x-txn-auth-token': PARTNER_ANALYTICS_API_KEY,
            'uid': this.loggedInUserId,
          }
        });

        let httpResponseStatus = response.status;
        if (httpResponseStatus > 399) {
          this.termsHistogramRequestUrlNormalised = null;
        } else {
          this.termsHistogramRequestUrlNormalised.series = response.data.series;
          this.termsHistogramRequestUrlNormalised.labels = response.data.labels;
          this.termsHistogramRequestUrlNormalised.graylogQueryUrl = response.data.graylogQueryUrl;
        }

        //console.log("termsHistogramRequestUrlNormalised", this.termsHistogramRequestUrlNormalised);

      } catch (error) {
        this.termsHistogramRequestUrlNormalised = null;
        console.error('Error fetchHistogramRequestUrlNormalised:', error);
      } finally {
        this.isLoadingHistogramRequestUrlNormalised = false;
      }
    },

    copyShareableLink() {
      const baseUrl = window.location.origin + window.location.pathname + window.location.hash;
      const queryParams = new URLSearchParams({
        from: this.fromTimestamp || '',
        to: this.toTimestamp || '',
        partnerMetricsFor: this.partnerMetricsFor?.trim() || 'ryd',
        histogramInterval: this.histogramInterval
      }).toString();

      const shareableLink = `${baseUrl}?${queryParams}`;

      navigator.clipboard.writeText(shareableLink)
          .then(() => {
            this.$toast.show('It has been successfully copied to the clipboard as a complete link and can now be shared.', { theme: 'outline',position: 'top', type: 'success', duration: 5000 });
          })
          .catch(err => {
            this.$toast.show('Failed to copy the ', { theme: 'outline',position: 'top', type: 'danger', duration: 8000 });
          });
    },

  }
}

</script>

