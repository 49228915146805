<template>
  <Breadcrumbs main="rydpay APIs" title="Webhooks"/>

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">

        <div class="card card-absolute">
          <div class="card-header bg-primary">
            <h5 class="text-white">Overview of Webhooks</h5>
          </div>
          <div class="card-body">
            <p>
              Webhooks are automated messages sent from apps when something happens. In the context of our services,
              webhooks are used to notify your systems about various events, such as <b>order state changes, invoice
              generation</b>, and more.
            </p>
          </div>
        </div>
        <br/>

        <div class="card card-absolute">
          <div class="card-header bg-success">
            <h5 class="text-white">Configuring Callbacks for Order State Changes</h5>
          </div>
          <div class="card-body">
            <p>
              As a partner, you can request us to configure a callback URL in our system. This URL will be invoked every time there is a state change in an order, ensuring that you receive real-time updates.
            </p>
          </div>
        </div>
        <br/>

        <div class="card card-absolute">
          <div class="card-header bg-primary">
            <h5 class="text-white">Receiving Notifications for Generated Invoices and Receipts</h5>
          </div>
          <div class="card-body">
            <p>
              To receive notifications for newly generated invoices and receipts, you can set up a webhook that targets a specific URL of your system. This allows our system to send you critical information as soon as it's available.            </p>
          </div>
        </div>
        <br/>


        <div class="card card-absolute">
          <div class="card-header bg-success">
            <h5 class="text-white">Setting Up Webhooks</h5>
          </div>
          <div class="card-body">
            <ul class="list-group">
              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-success"></i>To enable this feature, please contact us to configure the callback URL for your account.
              </li>
            </ul>
          </div>
        </div>
        <br/>



        <div class="card card-absolute">
          <div class="card-header bg-primary">
            <h5 class="text-white">Webhook Request Details: Callback URL Requirements</h5>
          </div>
          <div class="card-body">
            <ul class="list-group">
              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-primary"></i>Your callback URL should support HTTPS POST requests.
              </li>
              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-primary"></i>Expect JSON payload in the request body.
              </li>
              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-primary"></i>Ensure that your endpoint responds with an HTTP success status code (e.g., 200 OK) to indicate successful receipt of the webhook notification.
              </li>
              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-primary"></i>Example Payload:
                <br/>
                <pre><code>
                    {
                      "downloadUrl": "https://tt4.thinxcloud.de/v4/invoices/9150f006-f75c-4744-b44f-49872d99f136",
                      "orderUid": "9150f006-f75c-4744-b44f-49872d99f136"
                    }
                </code></pre>
              </li>

            </ul>
          </div>
        </div>
        <br/>


        <div class="card card-absolute">
          <div class="card-header bg-success">
            <h5 class="text-white">Security Considerations</h5>
          </div>
          <div class="card-body">
            <ul class="list-group">
              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-success"></i>Use HTTPS for your callback URL to ensure secure transmission of data.
              </li>
              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-success"></i>Consider implementing a secret token or signature mechanism to validate the authenticity of incoming requests.
              </li>
              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-success"></i>Limit the number of retries and timeout periods to protect your system from potential denial of service attacks.
              </li>
            </ul>
          </div>
        </div>
        <br/>


        <div class="card card-absolute">
          <div class="card-header bg-primary">
            <h5 class="text-white">Best Practices: Reliability and Scalability</h5>
          </div>
          <div class="card-body">
            <ul class="list-group">
              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-primary"></i>Design your webhook handling service to be idempotent to handle potential duplicate messages.
              </li>
              <li class="list-group-item">
                <i class="fa fa-arrow-circle-right font-primary"></i>Ensure your system can handle the expected load and frequency of webhook calls.
              </li>
            </ul>
          </div>
        </div>
        <br/>



      </div>
    </div>
  </div>
</template>

<script setup>
</script>