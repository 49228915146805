<template>
  <Breadcrumbs main="Users" title="User List"/>

  <div class="container-fluid">
    <div class="row project-cards">
      <topMenu/>
    </div>
    <div class="row">
      <!-- Loading Indicator START -->
      <div v-if="isUsersLoading" class="loading-indicator">
        <h6 class="sub-title mb-0 text-center">Loading ...</h6>
        <div class="loader-box">
          <div class="loader-19"></div>
        </div>
      </div>
      <!-- Loading Indicator END -->
    </div>

    <div class="row">
      <div class="card">
        <div class="card-header bg-primary">
          <h5> All your users at a glance </h5>
        </div>
        <div class="card-body">
          <div class="theme-form">
            <div class="row">
              <div class="col-12">
                <div class="input-group">
                  <input v-model="searchKey" class="form-control form-control-plaintext ryd-search-input"
                         type="search" id="rpp-users-search"
                         placeholder="Enter text to search across first name, last name, email, and userGroup (partner name) in all accounts linked to your account."
                         @keyup.enter="searchKey ? fetchUsersAndReset() : null">
                  <button @click="fetchUsersAndReset"
                          class="btn btn-pill btn-success btn-air-success active input-group-text">
                    <i class="fa fa-search m-r-15"></i>Search
                  </button>
                  <button @click="resetSearch"
                          class="btn btn-pill btn-secondary btn-air-secondary input-group-text m-l-20">
                    <i class="fa fa-times m-r-15"></i>Reset Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- User List and Pagination -->
    <div v-if="!isUsersLoading && users.length > 0">
      <!-- second card: found users -->
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12">
              <div class="table-responsive product-table">
                <table class="display table-striped" id="basic-1">
                  <thead class="bg-primary text-white">
                  <tr>
                    <th @click="sortUsers('FIRSTNAME')" :class="getSortClass('firstName')" aria-controls="basic-1"
                        rowspan="1" colspan="1"
                        aria-label=""
                        class="bg-primary sortable"
                        style="width: 9%;">Firstname
                      <i v-if="sortColumn === 'FIRSTNAME'"
                         :class="sortOrder === 'asc' ? 'fa fa-sort-up' : 'fa fa-sort-down'"></i>
                    </th>
                    <th @click="sortUsers('LASTNAME')" :class="getSortClass('lastName')" aria-controls="basic-1"
                        rowspan="1" colspan="1"
                        aria-label=""
                        class="bg-primary sortable"
                        style="width: 9%;">Lastname
                      <i v-if="sortColumn === 'LASTNAME'"
                         :class="sortOrder === 'asc' ? 'fa fa-sort-up' : 'fa fa-sort-down'"></i>
                    </th>
                    <th @click="sortUsers('EMAIL')" :class="getSortClass('email')" aria-controls="basic-1" rowspan="1"
                        colspan="1"
                        aria-label=""
                        class="bg-primary sortable"
                        style="width: 16%;">Email
                      <i v-if="sortColumn === 'EMAIL'"
                         :class="sortOrder === 'asc' ? 'fa fa-sort-up' : 'fa fa-sort-down'"></i>
                    </th>
                    <th @click="sortUsers('USER_GROUP')" :class="getSortClass('userGroup')" aria-controls="basic-1"
                        rowspan="1" colspan="1"
                        aria-label=""
                        class="bg-primary sortable"
                        style="width: 10%;">User Group
                      <i v-if="sortColumn === 'USER_GROUP'"
                         :class="sortOrder === 'asc' ? 'fa fa-sort-up' : 'fa fa-sort-down'"></i>
                    </th>
                    <th tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1"
                        aria-label=""
                        class="bg-primary"
                        style="width: 20%;">
                      Roles
                    </th>
                    <th class="bg-primary" tabindex="0" aria-controls="basic-1" rowspan="1" colspan="1"
                        aria-label="" style="width: 25%;">
                      Actions
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="userItem in users" :key="userItem">
                    <td>{{ userItem.firstName }}</td>
                    <td>
                      {{ userItem.lastName }}
                    </td>
                    <td>{{ userItem.email }}</td>
                    <td>{{ userItem.userGroup }}</td>
                    <td>

                      <ul class="list-group">
                        <li v-for="role in userItem.roles" :key="role">{{ role }}</li>
                      </ul>

                    </td>

                    <td>
                      <button class="btn btn-success me-3 m-t-10" type="button" title="Edit user's data"
                              @click="editUser(userItem.uid, userItem.email, userItem.firstName, userItem.lastName)">
                        Edit
                      </button>

                      <button class="btn btn-warning me-3  m-t-10" type="button"
                              @click="sendPasswordResetV2(userItem.uid, userItem.email)">Reset Password
                      </button>


                      <button class="btn btn-info me-3 m-t-10" type="button" data-bs-toggle="modal"
                              @click="selectUserForShowingActivity(userItem.uid, userItem.email)"
                              data-bs-target="#userActivityModalCenter">View Activity
                      </button>

                      <button class="btn btn-danger m-t-10" type="button" data-bs-toggle="modal"
                              @click="selectUserForDeletion(userItem.uid, userItem.email)"
                              data-bs-target="#userDeletionModalCenter">Delete
                      </button>


                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>

          </div>

          <!-- Pagination Controls -->
          <nav aria-label="User list pagination" v-if="totalPages > 0" class="m-t-15">
            <ul class="pagination">
              <li class="page-item" :class="{ disabled: currentPage === 1 }">
                <button class="page-link" @click="prevPage">Previous</button>
              </li>

              <!-- Example: Simple pagination buttons -->
              <li class="page-item" v-for="n in totalPages" :key="n" :class="{ active: n === currentPage }">
                <button class="page-link" @click="goToPage(n)">{{ n }}</button>
              </li>

              <li class="page-item" :class="{ disabled: currentPage * pageSize >= totalUsers }">
                <button class="page-link" @click="nextPage">Next</button>
              </li>
            </ul>
          </nav>

          <!-- Total Results Display -->
          <div v-if="totalPages > 0" class="total-results m-t-15 fw-bold">
            <p>{{ totalUsers }} user<span v-if="totalUsers !== 1">s</span> found.</p>
          </div>

          <br/><br/>
        </div>
      </div>
    </div>

    <div v-if="!isUsersLoading && users.length === 0">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="alert alert-danger dark" role="alert"><p>No matching user found. Please refine your search
              criteria. </p></div>
            <div class="alert alert-info dark" role="alert"><p><b>Note:</b> Search is case-insensitive and looks for
              partial matches.</p></div>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="card card-absolute">
    <div class="card-header bg-info">
      <h5>Important Notes</h5>
    </div>

    <div class="card-body">
      <div class="alert" role="alert">
        <h5><i class="icofont icofont-hand-right font-info">&nbsp;</i>Four key functionalities:</h5>
        <p>are available for users with administrative privileges on their company's
          Ryd account:</p>
        <hr>

        <h6 class="m-t-15"><i class="fa fa-arrow-circle-right font-info">&nbsp;</i>Create a New User:</h6>
        <ul class="list-group">
          <li class="list-group-item">
            To add a new user, simply click the <code>Create New User</code> button above.
          </li>
          <li class="list-group-item">
            Please note that email addresses must be unique within the Ryd partner portal's user management.
          </li>
        </ul>

        <h6 class="m-t-15"><i class="fa fa-arrow-circle-right font-info">&nbsp;</i>Delete a User:</h6>
        <ul class="list-group">
          <li class="list-group-item">
            Deleting a user will permanently erase their data, and recovery
            will not be possible
          </li>
        </ul>

        <h6 class="m-t-15"><i class="fa fa-arrow-circle-right font-info">&nbsp;</i>Edit a User:</h6>
        <ul class="list-group">
          <li class="list-group-item">
            You can freely edit all user details, such as first name, last name, and
            roles.
          </li>
          <li class="list-group-item">
            Please note, however, that email addresses are not editable.
          </li>
        </ul>

        <h6 class="m-t-15"><i class="fa fa-arrow-circle-right font-info">&nbsp;</i>View a User' Activity Information:</h6>
        <p>Detailed Activity Information Includes:</p>
        <ul class="list-group">
          <li class="list-group-item">
            <b>Account Creation Date</b>
            <br/>
            Displays the date and time when the user's account was created on the ryd partner portal.

          </li>
          <li class="list-group-item">
            <b>Most Recent Sign-In</b>:
            <br/>
            Indicates the last time the user signed into their account.
            <br/>
            indicates additionally whether a newly created user has successfully completed their registration process.
          </li>
          <li class="list-group-item">
            <b>Last Data Refresh</b>:
            <br/>
            Represents the most recent instance when the user's authentication token was refreshed.
            <br/>
            Refreshing authentication tokens is a critical security measure. It prevents potential misuse of compromised tokens and ensures that the user's session information remains current.
          </li>
        </ul>

      </div>
      <br/>


    </div>
  </div>


  <div class="modal fade" id="userDeletionModalCenter" tabindex="-1" role="dialog" ref="deletionModal"
       aria-labelledby="userDeletionModalCenter">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Delete an user</h5>
        </div>
        <div class="modal-body">
          <p>Deleting a user permanently deletes their data and recovery will not be possible. </p>
          <p>If you delete your own user/account, your data will be permanently deleted and you will be
            logged out.</p>
          <br/>
          <p>The user with the following email address will be deleted: <br/>
            <span class="text-danger">{{ this.userEmailToDelete }}</span>
          </p>
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary" type="button" data-bs-dismiss="modal">Cancel</button>
          <button class="btn btn-secondary" type="button" @click="confirmDelete">Confirm Deletion</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="userActivityModalCenter" tabindex="-1" role="dialog" ref="userActivityModal"
       aria-labelledby="userActivityModalCenter">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">User's Activity Information</h5>
        </div>
        <div class="modal-body">
          <p class="m-t-10">The following is the activity information for the user: <br/>
            <span class="text-success">{{ this.userEmailToShowActivity }}</span>
          </p>
          <div v-if="this.singleUserDetails && this.singleUserDetails.metadata" class="m-t-30">

            <div class="row border-top mt-2">
              <label class="col-sm-5 col-md-4 col-form-label f-w-800">Account Creation Date:</label>
              <div class="col-sm-7 mt-1">
                <span class="text-success"> {{ this.singleUserDetails.metadata.creationTime}}</span>
              </div>
            </div>

            <div class="row border-top mt-2">
              <label class="col-sm-5 col-md-4 col-form-label f-w-800">Most Recent Sign-In:</label>
              <div class="col-sm-7 mt-1">
                <span v-if="this.singleUserDetails.metadata.lastSignInTime" class="text-success">
                  {{ this.singleUserDetails.metadata.lastSignInTime}}
                </span>
                <span v-else class="text-danger">
                  <b>Pending</b> - Registration has not been completed following the invitation.
                </span>
              </div>
            </div>

            <div class="row border-top mt-2">
              <label class="col-sm-5 col-md-4 col-form-label f-w-800">Last Data Refresh:</label>
              <div class="col-sm-7 mt-1">
                <p v-if="this.singleUserDetails.metadata.lastRefreshTime" class="text-success">
                  {{ this.singleUserDetails.metadata.lastRefreshTime}}
                </p>
                <p v-else class="text-danger">
                  <b>Unavailable</b> - Registration must be completed for data refresh functionality.
                </p>
              </div>
            </div>

          </div>
          <div v-if="this.isUserActivityLoading">
            <h6 class="sub-title mb-0 text-center">Loading User's Activity Information ...</h6>
            <div class="loader-box">
              <div class="loader-19"></div>
            </div>
          </div>
          <div v-if="!this.isUserActivityLoading && this.singleUserDetails && !this.singleUserDetails.metadata">
            <span class="text-danger">
              There is no activity information for the given user. please contact the Ryd support team to get more information.
            </span>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary" type="button" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>

  <div v-if="isLoading" class="loading-spinner">
    <h6 class="sub-title mb-0 text-center">Loading ...</h6>
    <div class="loader-box">
      <div class="loader-19"></div>
    </div>
  </div>

</template>

<script>
import topMenu from './topMenu.vue';
import axios from 'axios';
import {auth} from '@/firebase/firebaseInit';
import {Modal} from 'bootstrap';
import {sendPasswordResetEmail} from 'firebase/auth';
import {RPP_API_SERVER_URL} from "@/constants/constants";


export default {
  name: 'userList',
  components: {
    topMenu
  },
  data() {
    return {
      users: [],
      userIdToDelete: null, // UID of the user to delete
      userEmailToDelete: null, // UID of the user to delete
      currentUserUid: null,
      isModalVisible: false,
      userIdToShowActivity: null,
      userEmailToShowActivity: null,
      isUserActivityModalVisible: false,
      isUserActivityLoading: false,
      theModalUserActivity: null,
      theModal: null,
      isLoading: false,
      isUsersLoading: false,
      currentPage: 1,
      pageSize: 20,
      totalUsers: 0,
      searchKey: '',
      sortColumn: 'FIRSTNAME',
      sortOrder: 'asc', // or 'desc'
      singleUserDetails : {}
    };
  },
  created() {
    //this.fetchUsers();
  },
  mounted() {
    this.theModal = new Modal('#userDeletionModalCenter');
    this.theModalUserActivity = new Modal('#userActivityModalCenter');
    this.fetchUsers();
  },
  computed: {
    totalPages() {
      if (!this.totalUsers || !this.pageSize) {
        return 0; // Default to 0 if totalUsers or pageSize is not set or is 0
      }
      return Math.ceil(this.totalUsers / this.pageSize);
    }
  },
  methods: {
    async fetchUsersAndReset() {
      this.currentPage = 1;
      this.fetchUsers();
    },
    resetSearch() {
      this.searchKey = ''; // Clear the search key
      this.currentPage = 1;
      this.sortColumn = "FIRSTNAME";
      this.sortOrder = "asc";
      this.fetchUsers();   // Fetch users without search filter
    },
    async fetchUsers() {

      this.isUsersLoading = true;

      const user = auth.currentUser;
      //console.log("userListItemUser", user);
      const uid = user ? user.uid : null;
      this.currentUserUid = uid;

      try {
        const response = await axios.get(`${RPP_API_SERVER_URL}`, {
          params: {
            page: this.currentPage,
            pageSize: this.pageSize,
            searchKey: this.searchKey,
            sortBy: this.sortColumn,
            order: this.sortOrder,
          },
          headers: {
            'x-txn-auth-token': process.env.VUE_APP_RPP_USERS_CLOUD_FUNCTION_API_KEY,
            'uid': uid
          }
        });

        this.users = response.data.users;
        this.totalUsers = response.data.total;
      } catch (error) {
        console.error('Error fetching users:', error);
        // Handle error
      } finally {
        this.isUsersLoading = false;
      }


    },
    selectUserForDeletion(uid, email) {
      this.userIdToDelete = uid;
      this.userEmailToDelete = email;
      this.isModalVisible = true;
    },
    selectUserForShowingActivity(uid, email) {
      this.isUserActivityLoading = true;
      this.userIdToShowActivity = uid;
      this.isUserActivityModalVisible = true;
      this.userEmailToShowActivity = email;
      this.singleUserDetails = {};
      this.isModalVisible = true;
      this.showUserActivity();
    },
    handleDeletion() {
      this.$router.push('/users/list');
    },
    confirmDelete2() {
      window.location.href = '/auth/login';
    },
    confirmDelete() {
      console.log("this.userIdToDelete", this.userIdToDelete);
      if (this.userIdToDelete) {
        // Show deletion animation (implementation depends on your setup)

        const apiKey = process.env.VUE_APP_RPP_USERS_CLOUD_FUNCTION_API_KEY;

        axios.delete(`${RPP_API_SERVER_URL}?uid=${this.userIdToDelete}`, {
          headers: {
            'x-txn-auth-token': process.env.VUE_APP_RPP_USERS_CLOUD_FUNCTION_API_KEY,
          }
        })
            .then(response => {
              // Handle the successful deletion
              console.log('User deletion successful', response);

              // Check if the deleted user is the logged-in user
              const user = auth.currentUser;
              const loggedInUser = user ? user.uid : null;

              if (this.userIdToDelete === loggedInUser) {
                // Remove user data from localStorage and redirect to login
                localStorage.removeItem('userAddData');
                window.location.href = '/auth/login';
              } else {
                // Refresh the user list and show success message
                window.location.href = '/users/list';
              }
            })
            .catch(error => {
              console.error('Error deleting user:', error);
              window.location.href = '/users/list';
            });
      }
    },
    showUserActivity() {
      if (this.userIdToShowActivity) {

        axios.get(`${RPP_API_SERVER_URL}?singleUserId=${this.userIdToShowActivity}`, {
          headers: {
            'x-txn-auth-token': process.env.VUE_APP_RPP_USERS_CLOUD_FUNCTION_API_KEY
          }
        }).then(response => {
              console.log('Fetch User Details successful', response);
              this.singleUserDetails = response.data;
              this.isUserActivityLoading = false;
            })
            .catch(error => {
              console.error('Error deleting user:', error);
              this.singleUserDetails = {};
              this.isUserActivityLoading = false;
            });
      }
    },

    sendPasswordReset(email) {
      this.isLoading = true; // Show loading animation
      sendPasswordResetEmail(auth, email)
          .then(() => {
            this.isLoading = false;
            // Show SweetAlert for success
            this.$swal({
              title: "Success",
              text: "Password reset email sent successfully to " + email,
              icon: "success",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK"
            });
          })
          .catch((error) => {
            this.isLoading = false; // Hide loading animation
            alert('Error sending password reset email: ' + error.message);
            console.error("Error sending password reset email:", error);
          });
    },
    sendPasswordResetV2(uid, email) {

      this.isLoading = true; // Show loading animation

      // Retrieve the API key from environment variables
      const apiKey = process.env.VUE_APP_RPP_USERS_CLOUD_FUNCTION_API_KEY;

      // Request to your backend to create the user
      axios.post(RPP_API_SERVER_URL + "/reset-password-email", {}, {
        headers: {
          'x-txn-auth-token': apiKey,
          'uid': uid
        }
      }).then(() => {

        this.isLoading = false;
        // Show SweetAlert for success
        this.$swal({
          title: "Success",
          text: "Password reset email sent successfully to " + email,
          icon: "success",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK"
        });
      }).catch((error) => {
        this.isLoading = false;
        alert('Error sending password reset email: ' + error.message);
        console.error("Error sending password reset email:", error);
      });
    },

    editUser(userId, email, firstName, lastName) {
      this.$router.push({
        path: '/users/edit',
        query: {
          uid: userId,
          email: email,
          firstName: firstName,
          lastName: lastName
        }
      });
    },


    goToPage(page) {
      this.currentPage = page;
      this.fetchUsers();
    },

    nextPage() {
      if (this.currentPage * this.pageSize < this.totalUsers) {
        this.currentPage++;
        this.fetchUsers();
      }
    },

    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetchUsers();
      }
    },
    sortUsers(column) {
      if (this.sortColumn === column) {
        // Toggle sort order
        this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortColumn = column;
        this.sortOrder = 'asc';
      }
      this.fetchUsers();
    },

    getSortClass(column) {
      if (this.sortColumn !== column) {
        return '';
      }
      return this.sortOrder === 'asc' ? 'sorting_asc' : 'sorting_desc';
    },
  }

}


</script>


<style lang="scss" scoped>
@import "@/assets/scss/utils/variables";
@import "@/assets/scss/components/datatable";
</style>
<style scoped>
@import "@/assets/css/productlist.css";
</style>
<style scoped>
.sortable {
  cursor: pointer;
}

.sortable.active {
  color: #0d6efd; /* Bootstrap primary color */
}
</style>