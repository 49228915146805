<template>
    <Breadcrumbs title="Edit User" main="Users" />
    <div class="container-fluid">
        <div class="edit-profile">
            <div class="row">
                <userEditForm />
            </div>
        </div>
</div>
</template>
<script>
import sidePage from './sidePage.vue';
import userEditForm from "./UserEditForm.vue";

import '@vuepic/vue-datepicker/dist/main.css'
export default {
    components: {
        sidePage,
        userEditForm,
    }
}
</script>