<template>
  <div class="search-links tab-pane fade" :class="{'show active': showtab == 'errorLogMessages' }"
       id="all-links"
       role="tabpanel" aria-labelledby="errorLogMessages">
    <!-- ... TABLE START... -->
    <div
        v-if="apiResponseErrorLogMessages && apiResponseErrorLogMessages.totalNumberOfResults && apiResponseErrorLogMessages.totalNumberOfResults > 0">
      <div class="order-history table-responsive">
        <table class="table table-bordernone table-striped display" id="basic-2">
          <thead class="bg-primary text-white">
          <tr>
            <th class="bg-primary" scope="col">Timestamp</th>
            <th class="bg-primary" scope="col">Requested Action</th>
            <th class="bg-primary" scope="col">Level</th>
            <th class="bg-primary" scope="col">Source/Area</th>
            <th class="bg-primary" scope="col">Message</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="message in paginatedMessages" :key="message._id">
            <td>{{ message[0] }}</td>
            <td>{{ message[1] }}</td>
            <td>{{ message[2] }}</td>
            <td>{{ message[3] }}</td>
            <td>{{ message[4] }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <!-- ... TABLE END... -->

      <!-- Pagination Controls -->
      <nav aria-label="User list pagination" v-if="totalPages > 0" class="m-t-15">
        <ul class="pagination">
          <li class="page-item" :class="{ disabled: currentPage === 1 }">
            <button class="page-link" @click="prevPage">Previous</button>
          </li>
          <li class="page-item" v-for="n in totalPages" :key="n" :class="{ active: n === currentPage }">
            <button class="page-link" @click="goToPage(n)">{{ n }}</button>
          </li>
          <li class="page-item" :class="{ disabled: currentPage === totalPages }">
            <button class="page-link" @click="nextPage">Next</button>
          </li>
        </ul>
      </nav>

      <div v-if="this.apiResponseErrorLogMessages.totalNumberOfResults > 0" class="total-results m-t-15 fw-bold">
        <p>Total results: {{ this.apiResponseErrorLogMessages.totalNumberOfResults }}</p>
        <p v-if="this.apiResponseErrorLogMessages.totalNumberOfResults === 1000"><b>Note</b>: A maximum of 1000 results are shown.</p>
      </div>

      <!-- BUTTON START-->
      <br/>
      <div class="row" v-if="loggedInUserIsRyd">
        <div class="col">
          <div
              v-if="apiResponseErrorLogMessages && apiResponseErrorLogMessages.graylogQueryUrl">
            <a :href="apiResponseErrorLogMessages.graylogQueryUrl" target="_blank"
               class="btn btn-pill btn-success btn-lg" title="Go To Graylog Query">
              Go To Graylog Query
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- BUTTON END-->

    <div v-else>
      <!-- Loading Indicator START -->
      <div v-if="isLoadingErrorLogMessages" class="loading-indicator">
        <h6 class="sub-title mb-0 text-center">Loading ... Error Log Messages</h6>
        <div class="loader-box">
          <div class="loader-4"></div>
        </div>
      </div>
      <!-- Loading Indicator END -->

      <div class="text-center fw-bold fs-6 font-danger"
           v-if="apiResponseErrorLogMessages && apiResponseErrorLogMessages.errorMessage">
        {{ this.apiResponseErrorLogMessages.errorMessage }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TabErrorLogMessages',
  data() {
    return {
      currentPage: 1,
      pageSize: 50
    };
  },
  props: {
    apiResponseErrorLogMessages: {},
    showtab: {},
    isLoadingErrorLogMessages: {},
    loggedInUserIsRyd: {}
  },
  computed: {
    paginatedMessages() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.apiResponseErrorLogMessages.messages.slice(start, end);
    },
    totalPages() {
      const totalPages = Math.ceil(this.apiResponseErrorLogMessages.totalNumberOfResults / this.pageSize);
      console.log("totalPages", totalPages);
      return totalPages;
    }
  },
  methods: {
    goToPage(page) {
      this.currentPage = page;
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    }
  },

}
</script>