<template>
  <Breadcrumbs main="rydpay APIs" title="Guides" />

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">


        <div class="card card-absolute">
          <div class="card-header bg-primary">
            <h5 class="text-white">Overview</h5>
          </div>
          <div class="card-body">
            <p>
              The Guides section contains comprehensive and detailed information about the rydpay API, its
              features, and theoretical concepts. <br />
              This section is more about understanding the <b>'why'</b> and<b>'what'</b>.
            </p>
          </div>
        </div>
        <br />


        <div class="card">
          <div class="card-header bg-success">
            <h5>General Information about Prepay and Postpay</h5>
          </div>
          <div class="card-body">
            <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Description:</h5>
            <ul class="list-group">
              <li class="list-group-item">An overview of the prepay and postpay functionalities, explaining
                how they work, their differences, and their applications.
              </li>
            </ul>
            <br />

            <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Purpose</h5>
            <ul class="list-group">
              <li class="list-group-item">To provide partners with a clear understanding of these two key
                features of the Ryd API.
              </li>
            </ul>
            <br />
          </div>
        </div>

        <div class="card">
          <div class="card-header b-l-success border-3">
            <h5>Prepaid Transactions (Automatic Flow)</h5>
          </div>
          <div class="card-body">
            <p>Prepaid transactions, also known as the "automatic flow," are designed for convenience and
              efficiency in product acquisition, such as fueling at a pump. Here's how it works:</p>

            <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Upfront Payment Reservation:</h5>
            <ul class="list-group">
              <li class="list-group-item">In this flow, payments are reserved upfront. This reservation is
                crucial as it unlocks the product outlet (for example, a fuel pump at a gas station).
              </li>
            </ul>
            <br />

            <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Product Serving Limit:</h5>
            <ul class="list-group">
              <li class="list-group-item">The product outlet will dispense the product - be it fuel or any
                other item - up to the amount that has been pre-authorized. This ensures that the dispensing
                is controlled and within the expected limit.
              </li>
            </ul>
            <br />

            <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Pricing and Acquisition:</h5>
            <ul class="list-group">
              <li class="list-group-item">At the initial stage, only the maximum possible price is known. The
                final price and the total amount of the product acquired are determined only after the user
                has completed the acquisition process, such as finishing fueling.
              </li>
            </ul>
            <br />

            <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Order State Transition:</h5>
            <ul class="list-group">
              <li class="list-group-item">The order status in the system automatically changes from
                <code>PRODUCT_PENDING</code> to <code>PRODUCT_ACQUIRED</code>. This transition occurs as soon as the
                user takes the
                product from the outlet, like when fueling is completed.
              </li>
            </ul>
            <br />

            <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Sequence Diagram:</h5>
            <ul class="list-group">
              <li class="list-group-item">For a more detailed understanding, a full sequence diagram for the
                automatic flow is available below:
              </li>
            </ul>

            <div class="container">
              <div class="row justify-content-center">
                <div class="col-md-6">
                  <div class="row my-gallery gallery" id="aniimated-thumbnials-1" itemscope="" data-pswp-uid="1">
                    <!-- Display only the first image in the gallery1 array -->
                    <figure class="col-md-3 col-6 img-hover hover-15" @click="() => showImg(0)" itemprop="associatedMedia"
                      itemscope="">
                      <div>
                        <img :src="require('../../../assets/images/ryd/prepay-sequence-diagram-full.png')"
                          itemprop="thumbnail1" alt="Image description" class="img-fluid">
                      </div>
                    </figure>
                  </div>
                  <vue-easy-lightbox :visible="visible" :index="index" :imgs="lightBoxImages" @hide="handleHide">
                  </vue-easy-lightbox>
                </div>
              </div>
            </div>
            <br />
          </div>
        </div> <!-- END of Prepay CARD -->

        <div class="card">
          <div class="card-header b-l-success border-3">
            <h5>Postpaid Transactions (Manual Flow)</h5>
          </div>
          <div class="card-body">
            <p>Postpaid transactions, or the "manual flow," offer a more traditional approach to purchasing.
              This method is characterized by the following steps:</p>

            <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Product Acquisition Before Payment:
            </h5>
            <ul class="list-group">
              <li class="list-group-item">Unlike the prepaid method, the manual flow allows users to first
                take the product, such as performing a fuel top-up, and then authorize the payment
                afterward.
              </li>
            </ul>
            <br />

            <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Known Price and Amount:</h5>
            <ul class="list-group">
              <li class="list-group-item">In this flow, both the final price and the exact amount of the
                product are known at the time the user decides to acquire it. This provides clarity and
                certainty in terms of cost and quantity.
              </li>
            </ul>
            <br />

            <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>User-Initiated Order Completion:
            </h5>
            <ul class="list-group">
              <li class="list-group-item"> The order status changes from PRODUCT_PENDING to PRODUCT_ACQUIRED
                manually. This change is user-initiated, typically done by clicking a 'purchase now' button
                or completing a similar action..
              </li>
            </ul>
            <br />


            <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Sequence Diagram:</h5>
            <ul class="list-group">
              <li class="list-group-item">To visualize this process, a full sequence diagram for the manual
                flow is available below:
              </li>
            </ul>

            <div class="container">
              <div class="row justify-content-center">
                <div class="col-md-6">
                  <div class="row my-gallery gallery" id="aniimated-thumbnials-2" itemscope="" data-pswp-uid="1">
                    <!-- Display only the first image in the gallery1 array -->
                    <figure class="col-md-3 col-6 img-hover hover-15" @click="() => showImg(1)" itemprop="associatedMedia"
                      itemscope="">
                      <div>
                        <img :src="require('../../../assets/images/ryd/postpay-sequence-diagram-full.png')"
                          itemprop="thumbnail2" alt="Image description" class="img-fluid">
                      </div>
                    </figure>
                  </div>
                  <vue-easy-lightbox :visible="visible" :index="index" :imgs="lightBoxImages" @hide="handleHide">
                  </vue-easy-lightbox>
                </div>
              </div>
            </div>
            <br />
          </div>
        </div> <!-- END of Postpay CARD -->

        <div class="card">
          <div class="card-header bg-primary">
            <h5>Working with the Order Statemachine</h5>
          </div>
          <div class="card-body">
            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Description:</h5>
            <ul class="list-group">
              <li class="list-group-item">Practical examples and scenarios demonstrating how to handle
                different order states within an application.
              </li>
            </ul>
            <br />

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Purpose:</h5>
            <ul class="list-group">
              <li class="list-group-item">To provide partners with hands-on knowledge of managing order states
                effectively in real-world scenarios.
              </li>
            </ul>
            <br />

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Overview:</h5>
            <ul class="list-group">
              <li class="list-group-item">The Ryd API utilizes a comprehensive order state machine to manage
                transactions. This state machine is crucial for both the Prepaid (Automatic Flow) and
                Postpaid (Manual Flow) transactions. Understanding each state and its implications is
                essential for API consumers to ensure smooth integration and transaction handling.
              </li>
            </ul>
            <br />
          </div>

          <div class="container">
            <div class="row justify-content-center">
              <div class="col-md-12">
                <div class="row my-gallery gallery" id="aniimated-thumbnials-2" itemscope="" data-pswp-uid="1">
                  <!-- Display only the first image in the gallery1 array -->
                  <figure class="col-md-12   col-6 img-hover hover-15" @click="() => showImg(2)" itemprop="associatedMedia"
                    itemscope="">
                    <div>
                      <img :src="require('../../../assets/images/ryd/ryd-order-state-simple-0415.png')"
                        itemprop="thumbnail2" alt="Image description" class="img-fluid">
                    </div>
                  </figure>
                </div>
                <vue-easy-lightbox :visible="visible" :index="index" :imgs="lightBoxImages" @hide="handleHide">
                </vue-easy-lightbox>
              </div>
            </div>
          </div>
          <br />
        </div>

        <div class="card" id="order-state-details">
          <div class="card-header b-l-primary border-3">
            <h5>Order State Details</h5>
          </div>
          <div class="card-body">
            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>IMPORTANT: Final and not final states</h5>
            <ul class="list-group">
              <li class="list-group-item"><b>Final states:</b> Please are aware that while an order progresses through the state engine it can be INTERMEDIATE states or can reach one of x FINAL states.<br/>
                Final states are exclusively <b>COMPLETED, COMPLETED_MANUALLY, FAILED, CANCELLED</b>
              </li>
            </ul>
            <br />
            
            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>CREATED</h5>
            <ul class="list-group">
              <li class="list-group-item"><b>Description:</b> This is the very first state of the order, upon order
                creation with the <code>POST /v4/orders</code> endpoint. When an order is in the <code>CREATED</code>
                state, it
                signifies that some initial product details have been established for that order. For instance, if a
                customer is purchasing fuel, they have selected the pump number.
              </li>
              <ul class="list-group">
                <li class="list-group-item"><b>[MANUAL FLOW]</b> The order won't remain in this state for an extended
                  period. As soon as the customer completes their interaction with the product (e.g., finishes filling
                  their vehicle's gas tank), the order will transition to the <code>PAYMENT_PENDING</code> state. At
                  this point, it is required for the API client to refrain from making an <code>/authorize</code>
                  request immediately; instead, they should wait until the order advances to the
                  <code>PAYMENT_PENDING</code> state.
                </li>
                <li class="list-group-item"><b>[AUTOMATIC FLOW]</b> If the API client does not initiate a request to the
                  <code>/authorize</code> endpoint at this stage, the order will remain in the <code>CREATED</code>
                  state. This is why it is imperative for the API client to promptly make an <code>/authorize</code>
                  request as soon as it detects the order in the <code>CREATED</code> state within the AUTOMATIC flow.
                </li>
              </ul>
            </ul>
            <br />

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>PAYMENT_PENDING</h5>
            <ul class="list-group">
              <li class="list-group-item"><b>[MANUAL FLOW]</b> In the MANUAL flow, the <code>PAYMENT_PENDING</code>
                state is very
                important. It signifies that the customer currently possesses the product. This implies that at this
                specific moment, we possess information regarding both the price of the product and the quantity
                obtained by the customer. This characteristic is unique to the MANUAL flow, as it enables us to
                precisely determine the precise amount to charge the customer. You can employ the charge amount
                obtained during this step as the authorization amount in your request to the <code>/authorize</code>
                endpoint.
              </li>
              <li class="list-group-item"><b>[AUTOMATIC FLOW]</b> Unlike the MANUAL flow, if the order is in the
                <code>PAYMENT_PENDING</code> state, the customer is not in possession of the product. In this flow, it's
                important
                to note that the <code>PAYMENT_PENDING</code> state does not directly concern the API clients. While you
                might see
                this state in the state history, its significance primarily lies within our internal services.
                Specifically, it serves as a trigger for our internal systems to decide when to initiate the
                authorization for charging with a specific Payment Service Provider. API clients need not be directly
                concerned with this state, as its role is primarily internal for payment processing purposes.
              </li>
            </ul>
            <br />

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>PAYMENT_AUTHORIZATION_STARTED</h5>
            <ul class="list-group">
              <li class="list-group-item">When the order reaches this state, it means that the payment authorization
                flow was triggered and initiated.
              </li>
            </ul>
            <br />

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>PAYMENT_AUTHORIZED</h5>
            <ul class="list-group">
              <li class="list-group-item"><b>Description:</b> TWhen the order is in this state, it signifies that the
                user has given us permission to charge them a specific amount of money. At this point, the API client
                should send a request to the <code>POST /orders/{orderId}/acquire</code> endpoint. This request
                instructs our system to proceed with the charging process.
              </li>
              <ul class="list-group">
                <li class="list-group-item"><b>[MANUAL FLOW]</b> In the MANUAL flow, we have already obtained
                  authorization for the precise amount to be charged through the <code>POST
                          /order/{orderId}/authorize</code>
                  endpoint. We have the exact value because, as previously explained, in the MANUAL flow, the customer
                  already possesses the product when the order reaches the <code>PAYMENT_PENDING</code> state.
                </li>
                <li class="list-group-item"><b>[AUTOMATIC FLOW]</b> In the AUTOMATIC flow, the authorized amount may not
                  necessarily match the final charge to the customer, primarily because the customer has not yet
                  received the product. In this scenario, the authorized amount serves as an upper limit, indicating the
                  maximum amount the customer has granted us permission to charge. However, our system ensures that the
                  total charge will not surpass this maximum value. This way, the customer cannot acquire a product
                  exceeding the authorized limit.
                </li>
              </ul>
            </ul>
            <br />


            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>PRODUCT_PENDING</h5>
            <ul class="list-group">
              <li class="list-group-item"><b>[MANUAL FLOW]</b> In the MANUAL flow, this state holds no relevance for the
                API client. While you might see this state in the order's state history, its significance primarily lies
                within our internal services.
              </li>
              <li class="list-group-item"><b>[AUTOMATIC FLOW]</b> In the AUTOMATIC flow, the
                <code>PRODUCT_PENDING</code> state is
                very important. It signifies that the user must physically proceed with obtaining the product. For
                instance, this might involve taking out a nozzle and filling the vehicle's gas tank. During this step,
                the customer doesn't need to engage with any graphical user interfaces (GUI) or digital apps. The
                interaction occurs solely in the physical world.
              </li>
            </ul>
            <br />

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>PRODUCT_ACQUISION_STARTED</h5>
            <ul class="list-group">
              <li class="list-group-item">When the order reaches this state, it means that the product acquistion flow
                was triggered and initiated.
              </li>
            </ul>
            <br />

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>PRODUCT_ACQUIRED</h5>
            <ul class="list-group">
              <li class="list-group-item">When the order reaches this state, it means the customer has completed all
                necessary interactions with the app or physical product and is allowed to leave the location. In case we
                encounter any issues preventing us from charging the customer later, the customer is still permitted to
                leave, because our system has all the necessary information to address the situation later.
              </li>
            </ul>
            <br />

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>READY_FOR_INVOICE</h5>
            <ul class="list-group">
              <li class="list-group-item">This state holds no relevance for the API client. It's important not to build
                your application's logic around it. Please keep in mind that its meaning and how it works may change in
                the future, or it might be removed altogether. When the order reaches this state, we start the payment
                charge with third-party payment providers, a step often referred to as "transaction capturing." The
                charge amount is determined by subtracting any applicable discounts from the products' value. If the
                customer had previously authorized a higher amount than the charge, we release the difference between
                these two values.
              </li>
            </ul>
            <br />

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>COMPLETED - [FINAL STATE]</h5>
            <ul class="list-group">
              <li class="list-group-item">A stable state that marks the successful finalization of the order. The money
                has been charged and there is nothing to do anymore.
              </li>
            </ul>
            <br />

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>COMPLETED_MANUALLY - [FINAL STATE]</h5>
            <ul class="list-group">
              <li class="list-group-item">This special state is specifically utilized to indicate an order that has
                undergone manual adjustments by an administrator. Such adjustments are infrequent and are only employed
                in situations where manual intervention is necessary to rectify an order that is in a problematic state.
              </li>
            </ul>
            <br />

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>REJECTED</h5>
            <ul class="list-group">
              <li class="list-group-item">internal and transient state towards <code>CANCELLED</code>, money will be
                released when
                transitioning to <code>CANCELLED</code>.
              </li>
            </ul>
            <br />

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>PROBLEM</h5>
            <ul class="list-group">
              <li class="list-group-item">semi-stable state which requires manual intervention as the system does not
                know if the product has been handed over to a customer or not (money stays reserved).
              </li>
              <li class="list-group-item"><b>[MANUAL FLOW]</b>: client needs to send the user into the shop to clarify if
                he paid or not!
              </li>
            </ul>
            <br />

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>CANCELLED - [FINAL STATE]</h5>
            <ul class="list-group">
              <li class="list-group-item">user or client cancelled the order.
              </li>
            </ul>
            <br />

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>FAILED [FINAL STATE]</h5>
            <ul class="list-group">
              <li class="list-group-item">for some reason (no funds, pump already reserved, ...) the purchase failed. No
                further action required. User(client) might retry by creating a new order.
              </li>
            </ul>
            <br />

            <br />
          </div>
        </div> <!-- END of Prepay CARD -->


      </div>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      lightBoxImages: [],
      visible: false,
      gallery1: [
        {
          image: 'prepay-sequence-diagram-full.png'
        },
        {
          image: 'postpay-sequence-diagram-full.png'
        },
        {
          image: 'ryd-order-state-simple-0415.png'
        }
      ],
    }
  },
  watch: {
    '$route.hash': function () {
      this.scrollToCard();
    }
  },
  methods: {
    showImg(index) {
      this.index = index
      this.visible = true
    },
    handleHide() {
      this.visible = false;
      this.$nextTick(() => {
        document.body.style.overflow = '';
      });
    },
    scrollToCard() {
      const hash = this.$route.hash;
      const offset = 75; // Height of your fixed header

      if (hash) {
        const element = document.querySelector(hash);

        if (element) {
          const elementPosition = element.getBoundingClientRect().top + window.scrollY;
          window.scrollTo({ top: elementPosition - offset, behavior: 'smooth' });
        }
      }
    },
  },
  mounted() {
    this.scrollToCard();
    this.gallery1.forEach(item => {
      this.lightBoxImages.push(require('@/assets/images/ryd/' + item.image))
    });

  }
}
</script>