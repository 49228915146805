import { db } from '../../firebase/firebaseInit';

export default {
  state: {
    users: [],
    user: null,
  },
  getters: {
    userPermissions: state => state.user?.permissions || []
  },
  actions: {

  },
  mutations: {
    SET_USERS(state, users) {
      state.users = users;
    },
    setUser(state, userData) {
      state.user = userData;
    },
    // ...
  },
  // ...
};
