<template>
  <Breadcrumbs main="webapp Integration" title="Top-up Flow Steps" />

  <div class="container-fluid">
    <div class="row">
        <div class="card">
          <div class="card-body">
            <div class="tabbed-card">
              <ul class="pull-right nav nav-pills nav-primary" id="pills-clrtab" role="tablist">
                <li class="nav-item" v-on:click="changetab('topUpFlowStepsPostpay')"><a class="nav-link"
                                                                                     :class="{ 'show active': showtab == 'topUpFlowStepsPostpay' }"
                                                                                     id="topup-flow-steps-postpay-tab"
                                                                                     data-bs-toggle="pill"
                                                                                     href="#topup-flow-steps-postpay" role="tab"
                                                                                     aria-controls="topup-flow-steps-postpay"
                                                                                     aria-selected="true"><i
                    class="fa icofont icofont-chart-flow"></i>Top-Up Flow Steps: Postpay</a>
                </li>
                <li class="nav-item" v-on:click="changetab('topUpFlowStepsPrepay')"><a class="nav-link"
                                                                                             :class="{ 'show active': showtab == 'topUpFlowStepsPrepay' }"
                                                                                             id="topup-flow-steps-prepay-tab"
                                                                                             data-bs-toggle="pill"
                                                                                             href="#topup-flow-steps-prepay"
                                                                                             role="tab"
                                                                                             aria-controls="topup-flow-steps-prepay"
                                                                                             aria-selected="false"><i
                    class="fa icofont icofont-chart-flow"></i>Top-Up Flow Steps: Prepay</a>
                </li>

              </ul>
            </div>


            <!-- TAB CONTENT BEGIN -->
            <div class="tab-content" id="pills-clrtabContent">
              <div class="tab-pane fade " :class="{'show active': showtab === 'topUpFlowStepsPostpay' }"
                   id="topup-flow-steps-postpay"
                   role="tabpanel" aria-labelledby="topup-flow-steps-postpay-tab">

                <!-- CARD START -->
                <div class="card card-absolute m-t-50">
                  <div class="alert alert-warning inverse alert-dismissible fade show" role="alert"><i class="icon-bell"></i>
                    <p>The steps outlined here primarily illustrate the successful sequence of actions ('happy path') of a <b>postpay</b> top-up process. </p>
                  </div>
                  <div class="alert alert-warning inverse alert-dismissible fade show" role="alert"><i class="icon-bell"></i>
                    <p>Please note that alternative steps and screens will be presented to handle various error scenarios that might occur during the process.</p>
                  </div>
                  <section class="cd-container" id="cd-timeline">
                    <div class="cd-timeline-block">
                      <div class="cd-timeline-img cd-picture bg-primary"><i class="icofont icofont-hand-drag1"></i></div>
                      <div class="cd-timeline-content">
                        <h4>1. Map View</h4>
                        <p class="m-0">Select a gas station from the map to start the top-up process. This step is optional as the top-up flow can also be initiated without using the map.</p>
                        <img class="img-fluid p-t-20" src="@/assets/images/topup-flow-steps/postpay/01.png" alt="" >
                        <span class="cd-date">Postpay Step 1</span>
                      </div>
                    </div>

                    <div class="cd-timeline-block">
                      <div class="cd-timeline-img cd-picture bg-primary"><i class="icofont icofont-hand-drag1"></i></div>
                      <div class="cd-timeline-content">
                        <h4>2. Pump Selection</h4>
                        <p class="m-0">Select a pump</p>
                        <img class="img-fluid p-t-20" src="@/assets/images/topup-flow-steps/postpay/02.png" alt="">
                        <span class="cd-date">Postpay Step 2</span>
                      </div>
                    </div>

                    <div class="cd-timeline-block">
                      <div class="cd-timeline-img cd-picture bg-primary"><i class="icofont icofont-eye"></i></div>
                      <div class="cd-timeline-content">
                        <h4>3. Pump is Ready</h4>
                        <p class="m-0">Pump is ready</p>
                        <img class="img-fluid p-t-20" src="@/assets/images/topup-flow-steps/postpay/03.png" alt="">
                        <span class="cd-date">Postpay Step 3</span>
                      </div>
                    </div>

                    <div class="cd-timeline-block" v-animate-onscroll.repeat="{ down: 'animated zoomIn' }">
                      <div class="cd-timeline-img cd-picture bg-primary"><i class="icofont icofont-glue-oil"></i></div>
                      <div class="cd-timeline-content">
                        <h4>4. Customer Topping Up</h4>
                        <p>Customer can begin topping up their vehicle.</p>
                        <span class="cd-date">Postpay Step 4</span>
                      </div>
                    </div>

                    <div class="cd-timeline-block">
                      <div class="cd-timeline-img cd-picture bg-primary"><i class="icofont icofont-eye"></i></div>
                      <div class="cd-timeline-content">
                        <h4>5. Confirm Payment Details</h4>
                        <p class="m-0">Ryd successfully receives the payment details from the gas station's cashier system and presents them to the customer for confirmation. This ensures that the customer can review and verify the transaction before finalizing it.</p>
                        <img class="img-fluid p-t-20" src="@/assets/images/topup-flow-steps/postpay/04.png" alt="">
                        <span class="cd-date">Postpay Step 5</span>
                      </div>
                    </div>

                    <div class="cd-timeline-block" v-animate-onscroll.repeat="{ down: 'animated zoomIn' }">
                      <div class="cd-timeline-img cd-picture bg-primary"><i class="icofont icofont-external-link"></i></div>
                      <div class="cd-timeline-content">
                        <h4>6. Interaction with Payment Wallet/Provider</h4>
                        <p>Based on the selected payment method, the customer may be required to complete additional security steps, such as Two-Factor Authentication or Strong Customer Authentication. This ensures enhanced security and compliance with payment processing standards.</p>
                        <span class="cd-date">Postpay Step 6</span>
                      </div>
                    </div>

                    <div class="cd-timeline-block">
                      <div class="cd-timeline-img cd-movie bg-primary"><i class="fa fa-check-square-o"></i></div>
                      <div class="cd-timeline-content">
                        <h4>7. Transaction Outcome Confirmation</h4>
                        <p class="m-0">
                          Upon successful completion of the order, a confirmation screen is displayed, detailing all payment information. Conversely, if the transaction is unsuccessful, an error screen will be shown to inform the customer of the issue.</p>
                        <img class="img-fluid p-t-20" src="@/assets/images/topup-flow-steps/postpay/05.png" alt="">
                        <span class="cd-date">Postpay Step 7</span>
                      </div>
                    </div>


                  </section>
                </div>
                <!-- CARD END -->

              </div>
              <div class="tab-pane fade" :class="{'show active': showtab === 'topUpFlowStepsPrepay' }"
                   id="topup-flow-steps-prepay"
                   role="tabpanel" aria-labelledby="topup-flow-steps-prepay-tab">
                <div class="container-fluid">

                  <!-- CARD START -->
                  <div class="card card-absolute m-t-50">
                    <div class="alert alert-warning inverse alert-dismissible fade show" role="alert"><i class="icon-bell"></i>
                      <p>The steps outlined here primarily illustrate the successful sequence of actions ('happy path') of a <b>prepay</b> top-up process. </p>
                    </div>
                    <div class="alert alert-warning inverse alert-dismissible fade show" role="alert"><i class="icon-bell"></i>
                      <p>Please note that alternative steps and screens will be presented to handle various error scenarios that might occur during the process.</p>
                    </div>
                    <section class="cd-container" id="cd-timeline">
                      <div class="cd-timeline-block">
                        <div class="cd-timeline-img cd-picture bg-success"><i class="icofont icofont-hand-drag1"></i></div>
                        <div class="cd-timeline-content">
                          <h4>1. Map View</h4>
                          <p class="m-0">Select a gas station from the map to start the top-up process. This step is optional as the top-up flow can also be initiated without using the map.</p>
                          <img class="img-fluid p-t-20" src="@/assets/images/topup-flow-steps/prepay/01.png" alt="" >
                          <span class="cd-date">Prepay Step 1</span>
                        </div>
                      </div>

                      <div class="cd-timeline-block">
                        <div class="cd-timeline-img cd-picture bg-success"><i class="icofont icofont-hand-drag1"></i></div>
                        <div class="cd-timeline-content">
                          <h4>2. Pump Selection</h4>
                          <p class="m-0">Select a pump</p>
                          <img class="img-fluid p-t-20" src="@/assets/images/topup-flow-steps/prepay/02.png" alt="">
                          <span class="cd-date">Prepay Step 2</span>
                        </div>
                      </div>

                      <div class="cd-timeline-block">
                        <div class="cd-timeline-img cd-picture bg-success"><i class="icofont icofont-hand-drag1"></i></div>
                        <div class="cd-timeline-content">
                          <h4>3. Fuel type Selection</h4>
                          <p class="m-0">Select a fuel type/product</p>
                          <img class="img-fluid p-t-20" src="@/assets/images/topup-flow-steps/prepay/03.png" alt="">
                          <span class="cd-date">Prepay Step 3</span>
                        </div>
                      </div>

                      <div class="cd-timeline-block">
                        <div class="cd-timeline-img cd-picture bg-success"><i class="icofont icofont-hand-drag1"></i></div>
                        <div class="cd-timeline-content">
                          <h4>4. Amount Selection</h4>
                          <p class="m-0">Pre-select the maximum top-up amount </p>
                          <img class="img-fluid p-t-20" src="@/assets/images/topup-flow-steps/prepay/03.png" alt="">
                          <span class="cd-date">Prepay Step 4</span>
                        </div>
                      </div>

                      <div class="cd-timeline-block">
                        <div class="cd-timeline-img cd-picture bg-success"><i class="icofont icofont-eye"></i></div>
                        <div class="cd-timeline-content">
                          <h4>5. Confirm Payment Details / Selections</h4>
                          <p class="m-0">This page displays the chosen fuel type and maximum top-up limit, including any applicable discounts. The final payment amount, determined by the actual volume of fuel dispensed, will not exceed the pre-selected maximum. Additionally, customers can select their preferred payment method on this page.</p>
                          <img class="img-fluid p-t-20" src="@/assets/images/topup-flow-steps/prepay/05.png" alt="">
                          <span class="cd-date">Prepay Step 5</span>
                        </div>
                      </div>

                      <div class="cd-timeline-block" v-animate-onscroll.repeat="{ down: 'animated zoomIn' }">
                        <div class="cd-timeline-img cd-picture bg-success"><i class="icofont icofont-external-link"></i></div>
                        <div class="cd-timeline-content">
                          <h4>6. Interaction with Payment Wallet/Provider</h4>
                          <p>Based on the selected payment method, the customer may be required to complete additional security steps, such as Two-Factor Authentication or Strong Customer Authentication. This ensures enhanced security and compliance with payment processing standards.</p>
                          <span class="cd-date">Prepay Step 6</span>
                        </div>
                      </div>


                      <div class="cd-timeline-block">
                        <div class="cd-timeline-img cd-picture bg-success"><i class="icofont icofont-eye"></i></div>
                        <div class="cd-timeline-content">
                          <h4>7. Pump is Ready</h4>
                          <p class="m-0">Ryd reserves the pump at the gas station's cashier system. Once the reservation is successfully confirmed, this notification page indicating that the pump is ready for use is displayed to the customer.</p>
                          <img class="img-fluid p-t-20" src="@/assets/images/topup-flow-steps/prepay/06.png" alt="">
                          <span class="cd-date">Prepay Step 7</span>
                        </div>
                      </div>

                      <div class="cd-timeline-block" v-animate-onscroll.repeat="{ down: 'animated zoomIn' }">
                        <div class="cd-timeline-img cd-picture bg-success"><i class="icofont icofont-glue-oil"></i>
                        </div>
                        <div class="cd-timeline-content">
                          <h4>8. Customer Topping Up</h4>
                          <p>Customer can begin topping up their vehicle.</p>
                          <span class="cd-date">Prepay Step 8</span>
                        </div>
                      </div>

                      <div class="cd-timeline-block">
                        <div class="cd-timeline-img cd-movie bg-success"><i class="fa fa-check-square-o"></i></div>
                        <div class="cd-timeline-content">
                          <h4>9. Transaction Outcome Confirmation</h4>
                          <p class="m-0">
                            Upon successful completion of the order, a confirmation screen is displayed, detailing all payment information. Conversely, if the transaction is unsuccessful, an error screen will be shown to inform the customer of the issue.</p>
                          <img class="img-fluid p-t-20" src="@/assets/images/topup-flow-steps/prepay/07.png" alt="">
                          <span class="cd-date">Prepay Step 9</span>
                        </div>
                      </div>


                    </section>
                  </div>
                  <!-- CARD END -->

                </div>
              </div>
            </div>
            <!-- TAB CONTENT END -->
          </div>
        </div>


      </div>
    </div>

</template>
<script>
import DashboardRequestStatsTabRequests from "@/pages/dashboard/apiRequestsAnalytics/AraApiRequestsFailureAnalytics.vue";
import DashboardRequestStatsTabDynamicDetails
  from "@/pages/dashboard/apiRequestsAnalytics/AraApiRequesSummary.vue";

import {getUserAddDataFromLocalStorage} from "@/utils/userUtils";
import {fetchPartnerNames} from "@/utils/partnerNamesUtils";

export default {
  data() {
    return {
      showtab: 'topUpFlowStepsPostpay',
    }
  },
  mounted() {
  },
  methods: {
    changetab(tab) {
      window.scrollTo({top: 0, behavior: 'smooth'})
      this.showtab = tab;
    }
  }
}

</script>

<style>
.tab-content-wrapper {
  position: relative;
  width: 100%;
  height: 90vh;
  overflow-y: auto;
}
</style>