<template>
    <Breadcrumbs title="API Changelog" main="Deeplinking Registration: " />
    <div class="container-fluid">
        <div class="row">

            <div class="col-sm-12 col-md-12">
                <div class="card">
                    <div class="card-header bg-success">
                        <h5><i class="icofont icofont-id"></i>&nbsp;Version: 1.0.1</h5>
                    </div>
                    <div class="card-body">
                        <p class="mb-0"><i class="icofont icofont-info-square"></i>: New feature for deeplink generation: Add the field 'genQrCode' with the value true to generate a QR code URL for a deeplink.</p>
                    </div>
                    <div class="card-footer">
                        <h6 class="mb-0"><i class="icofont icofont-ui-calendar"></i>: 2023-11-14</h6>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 col-md-12">
                <div class="card">
                    <div class="card-header bg-primary">
                        <h5><i class="icofont icofont-id"></i>&nbsp;Version: 1.0.0</h5>
                    </div>
                    <div class="card-body">
                        <p class="mb-0"><i class="icofont icofont-info-square"></i>: Initial release of the APIs: /customer-status and /deeplink-generation.</p>
                    </div>
                    <div class="card-footer">
                        <h6 class="mb-0"><i class="icofont icofont-ui-calendar"></i>: 2023-11-05</h6>
                    </div>
                </div>
            </div>

        </div>
</div></template>