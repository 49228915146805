<template>
  <Breadcrumbs main="Guardian System and Alerts" title="View Cashier System Configuration"/>

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div v-if="isLoading">
          <h6 class="sub-title mb-0 text-center">Loading...</h6>
          <div class="loader-box">
            <div class="loader-19"></div>
          </div>
        </div>

        <div class="col-sm-12" v-if="!isLoading">
          <div class="card">
            <div class="card-header bg-primary">
              <h5>View A Cashier System Configuration</h5>
            </div>
            <div class="card-body">
              <div class="form theme-form">

                <!-- Email Configurations -->
                <div class="mb-3">
                  <label class="fw-bold">Email Configurations</label>
                  <table class="table table-striped">
                    <thead class="bg-primary text-white">
                    <tr>
                      <th>Email</th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Department</th>
                      <th>Note</th>
                      <th>Alert Type</th>
                      <th>Active</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(email, index) in configuration.emailConfigurations" :key="index">
                      <td>{{ email.email }}</td>
                      <td>{{ email.firstName }}</td>
                      <td>{{ email.lastName }}</td>
                      <td>{{ email.department }}</td>
                      <td>{{ email.note }}</td>
                      <td>{{ email.alertType }}</td>
                      <td>{{ email.isActive ? 'Yes' : 'No' }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>

                <!-- Links Array -->
                <div class="mb-3">
                  <label class="fw-bold">Links</label>
                  <table class="table table-striped">
                    <thead class="bg-primary text-white">
                    <tr>
                      <th>Description</th>
                      <th>Link Type</th>
                      <th>Link URL</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(link, index) in configuration.links" :key="index">
                      <td>{{ link.description }}</td>
                      <td>{{ link.linkType }}</td>
                      <td>{{ link.link }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>

                <!-- Downtimes Array -->
                <div class="mb-3">
                  <label class="fw-bold">Downtimes</label>
                  <table class="table table-striped">
                    <thead class="bg-primary text-white">
                    <tr>
                      <th>Start Date Time</th>
                      <th>End Date Time</th>
                      <th>Note</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(downtime, index) in configuration.downtimes" :key="index">
                      <td>{{ downtime.startDate }}</td>
                      <td>{{ downtime.endDate }}</td>
                      <td>{{ downtime.note }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>

                <!-- Alert Disabled Until -->
                <div class="mb-3">
                  <label class="fw-bold">Alert Disabled Until Date</label>
                  <p>{{ configuration.alertDisabledUntil }}</p>
                </div>

                <!-- Email Language -->
                <div class="mb-3">
                  <label class="fw-bold">Email Language</label>
                  <p>{{ configuration.emailLanguage }}</p>
                </div>

              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {getUserAddDataFromLocalStorage} from "@/utils/userUtils";
import axios from 'axios';

export default {
  name: 'ViewCashierSystemConfiguration',
  data() {
    return {
      configurationId: null,
      configuration: null,
      isLoading: true,
      error: null,
      loggedInUserId: '',
      loggedInUserGroup: '',
      partnerMetricsFor: '',
    };
  },
  mounted() {
    const {uid, isUserGroupRyd, userGroup} = getUserAddDataFromLocalStorage();
    this.loggedInUserId = uid;
    this.loggedInUserGroup = userGroup;
    if (isUserGroupRyd) {
      this.partnerMetricsFor = 'all';
    } else {
      this.partnerMetricsFor = userGroup;
    }
    this.configurationId = this.$route.query.id;
    this.fetchConfiguration();
  },
  methods: {
    async fetchConfiguration() {
      this.isLoading = true;
      try {
        const response = await axios.get(`${PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_URL}/cashier-system-configurations/${this.configurationId}`, {
          headers: {
            'x-txn-auth-token': PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_KAY,
            'uid': this.loggedInUserId
          }
        });
        this.configuration = response.data;
        console.log("response3333", response.data)
      } catch (error) {
        this.error = "Failed to load configuration: " + (error.response ? error.response.data.message : error.message);
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>
