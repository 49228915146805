<template>
  <Breadcrumbs main="rydpay APIs" title="Getting Started"/>

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">

        <div class="card">
          <div class="card-header bg-primary">
            <h5>Basic setup instructions for new partners</h5>
          </div>
          <div class="card-body">

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Step 1: Contact Ryd for Account
              Creation</h5>
            <ul class="list-group">
              <li class="list-group-item"><b>Initial Contact:</b> Reach out to Ryd to express your interest in
                becoming a partner. You can do this via email and phone.
              </li>
              <li class="list-group-item"><b>Account Creation:</b> A member of the Ryd team will assist you in
                setting up a user account on our partner portal. This account will be your gateway to
                accessing the Ryd APIs and resources.
              </li>
            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Step 2: Accessing API Keys/Tokens
            </h5>
            <ul class="list-group">
              <li class="list-group-item"><b>Navigating to Account Settings:</b> Log in to the Ryd Partner
                Portal. Once logged in, hover over your name in the top right corner and click on the
                "ACCOUNT SETTINGS" menu.
              </li>
              <li class="list-group-item"><b>API Keys/Tokens:</b> In the Account Settings, you will find your
                API keys/Tokens. It's important to note that Ryd provides separate API keys/Tokens for the
                staging and production environments.
              </li>
              <li class="list-group-item"><b>Staging vs. Production:</b></li>
              <ul class="list-group">
                <li class="list-group-item"><b>Staging Environment:</b> Use the staging API keys for testing
                  and development purposes. This environment allows you to simulate API interactions
                  without affecting live data.
                </li>
                <li class="list-group-item"><b>Production Environment:</b> The production API keys are for
                  your live environment. Switch to these keys once you have completed testing and are
                  ready to go live.
                </li>
              </ul>
            </ul>
            <br/>


            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Step 3: Understanding the API
              Documentation</h5>
            <ul class="list-group">
              <li class="list-group-item"><b>Documentation Access: </b> Access our comprehensive API
                documentation through the Partner Portal.
              </li>
              <li class="list-group-item"><b>Familiarization:</b> Familiarize yourself with the available
                endpoints, request/response formats, and error handling procedures.
              </li>
              <li class="list-group-item"><b>Interactive Exploration:</b> Utilize <a
                  href="/integration-guide/rydpay-apis/api-reference">Swagger UI</a> provided by ryd for
                interactive exploration of the API.
              </li>
            </ul>
            <br/>


            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Step 4: Setting Up Your Development
              Environment</h5>
            <ul class="list-group">
              <li class="list-group-item"><b>Environment Setup:</b> Set up a development environment that
                mirrors your production setup as closely as possible.
              </li>
              <li class="list-group-item"><b>Software Installation:</b> Install any necessary software or
                tools, such as Postman, for testing API requests.
              </li>
              <li class="list-group-item"><b>Postman Collection:</b> Import the Ryd Postman collection for
                pre-configured API request templates.
              </li>
            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Step 5: Testing and Simulation</h5>
            <ul class="list-group">
              <li class="list-group-item"><b>Staging Environment:</b> Begin by testing in the Ryd staging
                environment to simulate real-world scenarios without affecting live data.
              </li>
              <li class="list-group-item"><b>Test Scenarios:</b> Run through common test scenarios, including
                both successful and error responses, to ensure your integration handles all cases.
              </li>
            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Step 6: Going Live</h5>
            <ul class="list-group">
              <li class="list-group-item"><b>Final Checks:</b> Before going live, ensure all functionalities
                are working as expected in the staging environment. Ryd will help you with acceptance and
                field testing, i.e. ryd will help you test in a real petrol station and analyse all requests
                and responses.
              </li>
              <li class="list-group-item"><b>Production Keys: </b> Your production API key will be generated
                and available on this portal once the contract details have been finalised between you and
                ryd.
              </li>
              <li class="list-group-item"><b>Deployment: </b> Transition your integration from the staging to
                the production environment using the production API keys.
              </li>
            </ul>
            <br/>


            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Step 7: Post-Deployment</h5>
            <ul class="list-group">
              <li class="list-group-item"><b>Monitoring:</b> Continuously monitor the integration for any
                issues or irregularities. Ryd offers an extensive monitoring solution that encompasses all
                your requests, responses, and performance metrics, ensuring thorough oversight and analysis.
              </li>
              <li class="list-group-item"><b>Updates and Maintenance: </b> Regularly check for updates in the
                API documentation and make necessary adjustments to your integration.
              </li>

            </ul>
            <br/>

            <div class="alert alert-primary outline alert-dismissible fade show d-flex" role="alert"><i
                data-feather="help-circle">
              <vue-feather type="help-circle"></vue-feather>
            </i>
              <p class="mx-2"><b>Support and Assistance</b> If you encounter any issues or have any questions
                during the setup process and your implementation, please reach out to our support team.</p>
            </div>


          </div>
        </div>


        <div class="card">
          <div class="card-header bg-success">
            <h5>Links to download necessary tools and pages</h5>
          </div>
          <div class="card-body">
            <h6>Here, we provide an overview of essential resources and guides to help you begin your journey with our
              APIs. Explore each topic to understand how to effectively use and integrate our APIs.</h6>
            <br/>

            <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>General Introduction</h5>
            <ul class="list-group">
              <li class="list-group-item">
                Discover the fundamentals of Rydpay APIs, their purpose, and how they can benefit your business.
                <a href="/integration-guide/rydpay-apis/introduction">Link to Introduction</a>
              </li>
            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Guides</h5>
            <ul class="list-group">
              <li class="list-group-item">
                Delve into our comprehensive guides which cover basic concepts such as understanding order states, the state machine, and the differences between prepay and postpay models. These guides are crucial for grasping the operational logic of our APIs.
                <a href="/integration-guide/rydpay-apis/guides">Link to Guides</a>
              </li>
            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Tutorials</h5>
            <ul class="list-group">
              <li class="list-group-item">
                cess a collection of best practices and step-by-step tutorials for using the Rydpay APIs. These practical tutorials are geared towards providing hands-on experience and insights on effective API usage..
                <a href="/integration-guide/rydpay-apis/tutorials">Link to Tutorials</a>
              </li>
            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>API Reference Documentation (Swagger UI)</h5>
            <ul class="list-group">
              <li class="list-group-item">
                Our API reference is presented in an interactive Swagger UI format, offering detailed documentation of all endpoints, parameters, and responses. This interactive platform allows you to test API endpoints directly within the documentation.
                <a href="/integration-guide/rydpay-apis/api-reference#/production">Link to API Reference in Swagger UI</a>
              </li>
            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Webhooks</h5>
            <ul class="list-group">
              <li class="list-group-item">
                Learn how to configure and use webhooks to receive real-time updates and notifications.
                <a href="/integration-guide/rydpay-apis/webhooks">Link to Webhooks</a>
              </li>
            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Testing and Simulation</h5>
            <ul class="list-group">
              <li class="list-group-item">
                Understand how to use the simulated gas stations on the staging environment.
                <a href="/integration-guide/rydpay-apis/testing-and-simulation">Link to Testing and Simulation</a>
              </li>
            </ul>
            <br/>


            <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Test Scenarios</h5>
            <ul class="list-group">
              <li class="list-group-item">
                Check out various test scenarios to validate the robustness and functionality of your integration.
                <a href="/integration-guide/rydpay-apis/test-scenarios">Link to Test Scenarios</a>
              </li>
            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Troubleshooting and Support</h5>
            <ul class="list-group">
              <li class="list-group-item">
                Find solutions for common issues and information on how to access additional support.
                <a href="/integration-guide/rydpay-apis/troubleshooting-and-support">Link to Troubleshooting and Support</a>
              </li>
            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-success">&nbsp;</i>Frequently Asked Questions (FAQs)</h5>
            <ul class="list-group">
              <li class="list-group-item">
                Browse through the FAQs for quick answers to commonly asked questions about our APIs
                <a href="/integration-guide/rydpay-apis/faqs">Link to FAQs</a>
              </li>
            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-success"> </i>Release Notes and Updates</h5>
            <ul class="list-group">
              <li class="list-group-item">
                Keep up with the latest updates, new features, and improvements in our APIs
                <a href="/integration-guide/rydpay-apis/release-notes-and-updates">Link to Release Notes and Updates</a>
              </li>
            </ul>
            <br/>

          </div>
        </div>


      </div>
    </div>
  </div>
</template>
<script setup>
</script>