<template>
  <Breadcrumbs main="Dashboard" title="Order Insights & Analytics"/>

  <div class="container-fluid">
    <div class="row">
      <div class="card">
        <div class="card-header">
          <h6 class="pull-right"></h6>
        </div>
        <div class="card-body">
          <div class="tabbed-card">
            <ul class="pull-right nav nav-pills nav-primary justify-content-around" id="pills-clrtab" role="tablist">
              <li class="nav-item" v-on:click="changetab('overviewAndGuide')"><a class="nav-link"
                                                                              :class="{ 'show active': showtab === 'overviewAndGuide' }"
                                                                              id="overview-and-guide-tab"
                                                                              data-bs-toggle="pill"
                                                                              href="#overview-and-guide" role="tab"
                                                                              aria-controls="overview-and-guide"
                                                                              aria-selected="true">
                <i class="fa icofont icofont-info-circle"></i>Overview & Guide</a>
              </li>
              <li class="nav-item" v-on:click="changetab('orderVolumeAnalysis')"><a class="nav-link"
                                                                              :class="{ 'show active': showtab === 'orderVolumeAnalysis' }"
                                                                              id="order-volume-analysis-tab"
                                                                              data-bs-toggle="pill"
                                                                              href="#order-volume-analysis" role="tab"
                                                                              aria-controls="order-volume-analysis"
                                                                              aria-selected="true"><i
                  class="fa icofont icofont-numbered"></i>Order Volume Analysis</a>
              </li>
              <li class="nav-item" v-on:click="changetab('orderCreationTrends')"><a class="nav-link"
                                                                                      :class="{ 'show active': showtab === 'orderCreationTrends' }"
                                                                                      id="order-creation-trends-tab"
                                                                                      data-bs-toggle="pill"
                                                                                      href="#order-creation-trends"
                                                                                      role="tab"
                                                                                      aria-controls="order-creation-trends"
                                                                                      aria-selected="false"><i
                  class="fa icofont icofont-chart-histogram"></i>Order Creation Trends</a>
              </li>
              <li class="nav-item" v-on:click="changetab('orderFailureInsights')"><a class="nav-link"
                                                                              :class="{ 'show active': showtab === 'orderFailureInsights' }"
                                                                              id="order-failure-insights-tab"
                                                                              data-bs-toggle="pill"
                                                                              href="#order-failure-insights"
                                                                              role="tab"
                                                                              aria-controls="order-failure-insights"
                                                                              aria-selected="true"><i
                  class="fa icofont icofont-table"></i>Order Failure Insights</a>
              </li>
              <li class="nav-item" v-on:click="changetab('stateErrorAnalysis')"><a class="nav-link"
                                                                                    :class="{ 'show active': showtab === 'stateErrorAnalysis' }"
                                                                                    id="state-error-analysis-tab"
                                                                                    data-bs-toggle="pill"
                                                                                    href="#state-error-analysis"
                                                                                    role="tab"
                                                                                    aria-controls="state-error-analysis"
                                                                                    aria-selected="false"><i
                  class="fa icofont icofont-chart-line-alt"></i>State & Error Analysis</a>
              </li>
            </ul>
          </div>

          <!-- TAB CONTENT BEGIN -->
          <div class="tab-content" id="pills-clrtabContent">
            <div class="tab-pane fade " :class="{'show active': showtab === 'overviewAndGuide' }"
                 id="overview-and-guide"
                 role="tabpanel" aria-labelledby="overview-and-guide-tab">
              <OiaOverviewAndGuide :partnerNames="partnerNames" />
            </div>
            <div class="tab-pane fade " :class="{'show active': showtab === 'orderVolumeAnalysis' }"
                 id="order-volume-analysis"
                 role="tabpanel" aria-labelledby="order-volume-analysis-tab">
              <OiaOrderVolumeAnalysis :partnerNames="partnerNames" />
            </div>
            <div class="tab-pane fade" :class="{'show active': showtab === 'orderCreationTrends' }"
                 id="order-creation-trends"
                 role="tabpanel" aria-labelledby="order-creation-trends-tab">
              <div class="container-fluid">
                <OiaOrderCreationTrends :partnerNames="partnerNames" />
              </div>
            </div>
            <div class="tab-pane fade" :class="{'show active': showtab === 'orderFailureInsights' }"
                 id="order-failure-insights"
                 role="tabpanel" aria-labelledby="order-failure-insights-tab">
              <div class="container-fluid">
                <OiaOrderFailureInsights :partnerNames="partnerNames" />
              </div>
            </div>
            <div class="tab-pane fade" :class="{'show active': showtab === 'stateErrorAnalysis' }"
               id="state-error-analysis"
               role="tabpanel" aria-labelledby="state-error-analysis-tab">
            <div class="container-fluid">
              <OiaStateErrorAnalysis :partnerNames="partnerNames" />
            </div>
          </div>
          </div>
        </div>
          <!-- TAB CONTENT END -->

        </div>
      </div>
    </div>
</template>
<script>
import OiaOrderVolumeAnalysis from "@/pages/dashboard/orderInsightsAnalytics/OiaOrderVolumeAnalysis.vue";
import OiaOrderCreationTrends
  from "@/pages/dashboard/orderInsightsAnalytics/OiaOrderCreationTrends.vue";

import {getUserAddDataFromLocalStorage} from "@/utils/userUtils";
import OiaStateErrorAnalysis
  from "@/pages/dashboard/orderInsightsAnalytics/OiaStateErrorAnalysis.vue";
import OiaOrderFailureInsights
  from "@/pages/dashboard/orderInsightsAnalytics/OiaOrderFailureInsights.vue";
import OiaOverviewAndGuide
  from "@/pages/dashboard/orderInsightsAnalytics/OiaOverviewAndGuide.vue";
import {fetchPartnerNames} from "@/utils/partnerNamesUtils";

export default {
  components: {
    OiaOrderVolumeAnalysis,
    OiaOrderCreationTrends,
    OiaStateErrorAnalysis,
    OiaOrderFailureInsights,
    OiaOverviewAndGuide
  },
  data() {
    return {
      showtab: 'overviewAndGuide',
      loggedInUserId: '',
      loggedInUserGroup: '',
      partnerMetricsFor: '',
      partnerNames: [],
    }
  },
  mounted() {
    const {uid, isUserGroupRyd, userGroup} = getUserAddDataFromLocalStorage();
    this.loggedInUserId = uid;
    this.loggedInUserGroup = userGroup;
    if (isUserGroupRyd) {
      this.partnerMetricsFor = 'all';
      this.loadPartnerNames(this.loggedInUserId);
    } else {
      this.partnerMetricsFor = userGroup;
    }
    this.checkUrlFragment();
  },
  methods: {
    convertTimestampToISO(timestamp) {
      return new Date(timestamp).toISOString();
    },
    checkUrlFragment() {
      const hash = window.location.hash;
      if (hash) {
        // Extract tab id from URL fragment
        const [path, queryParams] = hash.slice(1).split('?'); // Remove '#' and split
        //console.log("tabId", path);
        const tabId = path;
        // Map tab id to corresponding tab name
        const tabMap = {
          'overview-and-guide': 'overviewAndGuide',
          'order-volume-analysis': 'orderVolumeAnalysis',
          'order-creation-trends': 'orderCreationTrends',
          'order-failure-insights': 'orderFailureInsights',
          'state-error-analysis': 'stateErrorAnalysis'
        };
        // Change tab if mapped value exists
        if (tabMap[tabId]) {
          this.showtab = tabMap[tabId];
        }
      }
    },
    changetab(tab) {
      window.scrollTo({top: 0, behavior: 'smooth'})
      this.showtab = tab;
    },
    async loadPartnerNames() {
      try {
        this.partnerNames = await fetchPartnerNames(this.loggedInUserId);
      } catch (error) {
        console.log("error", error);
      }
    },
  }
}

</script>


<style>
.tab-content-wrapper {
  position: relative;
  width: 100%;
  height: 90vh;
  overflow-y: auto;
}
</style>