<template>
    <Breadcrumbs title="Guides and Tutorials" main="Deeplinking Registration" />
    <div class="container-fluid">
        <div class="row">

            <div class="col-sm-12 col-md-12">
                <div class="card card-absolute">
                    <div class="card-header bg-primary">
                        <h5 class="text-white">1. Possible Integration Approaches</h5>
                    </div>
                    <div class="card-body">
                        <p>In order to create a deeplink for a specific customer, there are two primary methods available.
                            The first method involves generating the deeplink directly within your own system. This requires
                            utilizing an algorithm and a secret key, both of which are provided by ryd. Detailed
                            instructions and guidelines for this process can be found in Section 2 of this page. The second
                            method is to use an API for deeplink generation. This approach allows you to request and receive
                            a customized deeplink for each of your customers through a straightforward API call. It can be
                            found in Section 3 of this page.</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 col-md-12">
                <div class="card card-absolute">
                    <div class="card-header bg-primary">
                        <h5 class="text-white">2. Generating within your own system</h5>
                    </div>
                    <div class="card-body">
                        <h6>Step 2-1: Construct JSON Object</h6>
                        <p>
                            The following JSON structure is expected:
                        <pre>
                            <code>
                                {
                                    "customerIdAtPartner": "&lt;customer Id at ryd partner&gt;",
                                    "productIdAtPartner": "&lt;Product Id at ryd partner&gt;",
                                    "sponsorId": "&lt;id of the sponsor to be applied during registration: provided by ryd to partner&gt;",
                                    "moreOptionalParam1": "MoreValue1",
                                    "moreOptionalParam2": "MoreValue2"
                                }
                            </code>
                        </pre>
                        </p>
                        <p><i class="icofont icofont-warning"></i><b>&nbsp;Important Notes:</b></p>
                        <ul class="list-group">
                            <li class="list-group-item">Mandatory fields: <code>customerIdAtPartner</code>, <code>sponsorId</code></li>
                            <li class="list-group-item">The data type for the fields <code>customerIdAtPartner</code>,
                                <code>productIdAtPartner</code>, and sponsorId is string, which means their values must be enclosed in a
                                double quote.</li>
                            <li class="list-group-item">The <code>sponsorId</code> will be provided by ryd to the ryd partner.</li>
                            <li class="list-group-item">A ryd partner can transfer additional key-value pairs to ryd, e.g.,
                                <code>moreOptionalParam1</code> and <code>moreOptionalParam2</code> in the above example json object.</li>
                            <li class="list-group-item">Please pay attention to the naming convention of the key names: Use
                                camel case, no special characters, and no German umlauts</li>
                        </ul>

                        <br />
                        <p>
                            <i class="fa fa-code"></i> <b>&nbsp;Below is an example JSON object:</b>
                        <pre>
                            <code>
                                {
                                    "customerIdAtPartner": "123456",
                                    "productIdAtPartner": "23",
                                    "sponsorId": "6523c2b0e160f8b832b12354"
                                }
                            </code>
                        </pre>
                        </p>

                        <br />
                        <h6>Step 2-2: Stringify the JSON Object</h6>
                        <p>
                            Convert the JSON object into a string.
                            Many contemporary programming languages offer stringify capabilities.
                            For instance, in Javascript:
                        <pre>
                            <code>
                                const jsonStringStringified = JSON.stringify(yourJsonObject);
                            </code>
                        </pre>
                        </p>


                        <br />
                        <h6>Step 2-3: Encrypt stringified JSON using AES-ECB</h6>
                        <p>
                            Ryd offers AES encryption using the ECB mode, which requires the exchange of a secret key from
                            Ryd to the ryd partner for encrypting a string using AES in ECB mode.
                        </p>

                        <br />
                        <h6>Step 2-4: Hex Encode the Stringified JSON</h6>
                        <p>
                            Encode the encrypted data from step-3 into Hex format.
                        </p>

                        <br />
                        <h6>Step 2-5: Replace the placeholder from basic registration link</h6>
                        <p>
                            Ryd will furnish you with two fundamental deeplinks: one tailored for the staging/test
                            environment and another for the production system. Please ensure to substitute all instances of
                            the <code>DATA_PLACEHOLDER</code> in these basic registration links with the relevant data derived from
                            Steps 2-4.
                        </p>

                    </div>
                </div>
            </div>


            <div class="col-sm-12 col-md-12">
                <div class="card card-absolute">
                    <div class="card-header bg-primary">
                        <h5 class="text-white">3. Using a ryd API</h5>
                    </div>
                    <div class="card-body">
                        <p>
                           Ryd provides an API to generate a deeplink. This example demonstrates how to make a request to the deeplink-generation endpoint. This endpoint generates a deeplink for specific customer and related data.
                        </p>
                        <p>
                            <b>Path: </b> /deeplink-generation
                        </p>
                        <p>
                            <b>HTTP Method: </b> POST
                        </p>

                        <p>
                            <i class="fa fa-code"></i> <b>&nbsp;Below is an example request via cURL command:</b>
                        <pre>
                            <code>
                                curl --location 'https://gcf.rydcloud-staging.de/deeplink-generation' \
                                    --header 'x-txn-partner-name: YOUR_PARTNER_ID_AT_RYD' \
                                    --header 'x-txn-auth-token: YOUR_API_TOKEN_AT_RYD' \
                                    --header 'Content-Type: application/json' \
                                    --data '{
                                    "customerIdAtPartner":"test-202312021000",
                                    "productIdAtPartner":"202311070858",
                                    "sponsorId":"6523c2b0e160f8b832b12345",
                                    "deeplinkType": "REGISTRATION",
                                    "genQrCode": true
                                }'
                            </code>
                        </pre>
                        </p>

                        <p><i class="icofont icofont-warning"></i><b>&nbsp;Important Notes:</b></p>
                        <ul class="list-group">
                            <li class="list-group-item">Replace the <code>x-txn-auth-token</code> with your authentication token that will be provided by ryd to you.</li>
                            <li class="list-group-item">Make sure you fill the request body with the correct data specific to your use case, e.g. whether or not to generate a QR code.</li>
                        </ul>

                    </div>
                </div>
            </div>
    </div>
</div></template>