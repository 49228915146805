<template>
    <Breadcrumbs main="rydpay APIs" title="Terms & Definitions" />

    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">


                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="col-sm-12 col-md-12" v-for="card in cards" :key="card.id">
                                <a :href="card.link">
                                    <div class="card card-absolute" :id="card.id">
                                        <div class="card-header bg-primary">
                                            <h5 class="text-white">{{ card.title }}</h5>
                                        </div>
                                        <div class="card-body">
                                            <p>{{ card.description }}</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>




            </div>
        </div>
    </div>
</template>



<script>
export default {
    data() {
        return {
            cards: [
                {
                    id: 'overshoot',
                    title: 'Overshoot',
                    description: 'Overshoot occurs when a customer ends up fueling more than the pre-authorised amount because the petrol station s cashier system fails to halt the fuel dispensing at the authorized limit. As a result, the total billed amount exceeds the initially authorized amount.',
                    link: '#overshoot' // This could be a URL or an anchor
                },
                {
                    id: 'poi',
                    title: 'POI',
                    description: '"POI" stands for "Point of Interest," which refers to a petrol station or a wash station.',
                    link: '#poi'
                },
                {
                    id: 'staging-environment',
                    title: 'Staging Environment',
                    description: 'The Staging environment is set up to closely resemble the real production environment, enabling comprehensive testing of code, builds, and updates prior to their deployment in the live application. In this simulated setting, all petrol and wash stations are replicated, allowing for testing without generating any actual transactions, payments, or invoices. This ensures a safe and controlled environment for validation and quality assurance.',
                    link: '#staging-environment'
                },

            ]
        };
    },
    // Other component options...
};
</script>
