<template>
  <!-- Full Page Loading Overlay -->
  <div v-if="isLoading" class="overlay">
    <h6 class="sub-title mb-0 text-center">Loading ...</h6>
    <div class="loader-box">
      <div class="loader-19"></div>
    </div>
  </div>

  <div class="col-sm-12" v-if="!isLoading">

    <!-- Edit user data-->
    <div class="card">
      <div class="card-header bg-primary">
        <h5>Edit User</h5>
      </div>
      <div class="card-body">
        <div class="form theme-form">

          <form @submit.prevent="updateUser">

            <div class="row">
              <p v-if="error" class="error-message">{{ error }}</p>
            </div>

            <div class="row border-bottom m-t-20">
              <div class="col">
                <div class="mb-3">
                  <label for="email" class="fw-bold">Email (cannot be edited)</label>
                  <input class="form-control" type="text" id="email" :value="editUserEmail" disabled
                         title="this field is disabled">
                </div>
              </div>
            </div>


            <div class="row border-bottom m-b-20 m-t-20">
              <div class="col">
                <div class="mb-3">
                  <label for="lastName" class="fw-bold">FirstName *</label>
                  <input class="form-control border border-primary" type="text" id="firstName"
                         v-model="newUser.firstName" required>
                </div>
              </div>
            </div>

            <div class="row border-bottom m-b-20 m-t-20">
              <div class="col">
                <div class="mb-3">
                  <label for="lastName" class="fw-bold">Lastname *</label>
                  <input class="form-control border border-primary" type="text" id="lastName"
                         v-model="newUser.lastName" required>
                </div>
              </div>
            </div>

            <div class="col border-bottom m-b-20 m-t-20" v-if="!isCurrentUser">
              <div class="form-group">
                <label for="roles" class="fw-bold">Roles *</label>
                <div v-for="(role, index) in availableRoles" :key="index">
                  <div class="checkbox checkbox-primary">
                    <input :id="`checkbox-primary-${index}`" type="checkbox" :value="role.name"
                           :checked="selectedRoles.includes(role.name)" @change="toggleRoleSelection(role.name)">
                    <label :for="`checkbox-primary-${index}`" class="fw-bold">{{ role.name }}</label>
                    <p class="checkbox-item-description">{{ role.description }}</p> <!-- Description -->
                  </div>
                </div>
              </div>
            </div>

            <div class="row m-b-30 m-t-20">
              <div class="col">
                <button class="btn btn-pill btn-primary btn-air-success active btn-lg" type="submit"
                        title="Edit the selected user">
                  <i class="fa fa-send m-r-15"></i>Edit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- Change Password -->
    <div class="card" v-if="isCurrentUser">
      <div class="card-header bg-success">
        <h5>Change Your Password</h5>
      </div>

      <div class="card-body">
        <div class="form-group">
          <label class="col-form-label"><b>New Password*:</b></label>
          <div class="form-input position-relative">

            <input
                class="form-control"
                :type="active?'password':'text'"
                v-model="password"
                required=""
                placeholder=""
            />
            <div class="show-hide" @click="toggleShowPassword"><span :class="active?'show':'hide'"> </span>
            </div>
          </div>

          <span class="validate-error m-t-15 m-b-15" v-if="passwordError">{{ passwordError }}</span>
          <br/>
          <ul class="password-hints">
            <li :class="{'text-success': password.length >= 8}">
              <i :class="['icofont icofont-hand-right', password.length >= 8 ? 'font-success' : 'font-primary']">&nbsp;</i>
              At least 8 characters long
            </li>
            <li :class="{'text-success': hasLowercase}">
              <i :class="['icofont icofont-hand-right', hasLowercase ? 'font-success' : 'font-primary']">&nbsp;</i>
              Contains at least one lowercase letter
            </li>
            <li :class="{'text-success': hasUppercase}">
              <i :class="['icofont icofont-hand-right', hasUppercase ? 'font-success' : 'font-primary']">&nbsp;</i>
              Contains at least one uppercase letter
            </li>
            <li :class="{'text-success': hasNumber}">
              <i :class="['icofont icofont-hand-right', hasNumber ? 'font-success' : 'font-primary']">&nbsp;</i>
              Contains at least one number
            </li>
            <li :class="{'text-success': hasSpecialChar}">
              <i :class="['icofont icofont-hand-right', hasSpecialChar ? 'font-success' : 'font-primary']">&nbsp;</i>
              Contains at least one special character (e.g., !@#$%^&*)
            </li>
          </ul>
        </div>

        <div class="form-group mb-0 m-t-30">
          <button
              :disabled="!isPasswordValid"
              class="btn btn-pill btn-primary btn-air-success active btn-lg"
              @click="changePassword"
              type="submit">
            Change Password
          </button>
        </div>

      </div>

    </div>


  </div>


  <div class="col-sm-12 col-md-12">
    <div class="card card-absolute">
      <div class="card-header bg-info">
        <h5 class="text-white">Important Note</h5>
      </div>
      <div class="card-body">
        <p>
          If a user's roles have been modified, they should log out and then log back into the Ryd partner portal.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import {auth, db} from '@/firebase/firebaseInit';
import {updatePassword} from "firebase/auth";
import {doc, getDoc} from 'firebase/firestore';
import axios from 'axios';
import {ROLE_DESCRIPTIONS, RPP_API_SERVER_URL, RPP_USERS_FIRESTORE_COLLECTION_NAME} from "@/constants/constants";
import {getUserAddDataFromLocalStorage} from "@/utils/userUtils";

export default {
  name: 'EditUser',
  data() {
    return {
      newUser: {
        firstName: '',
        lastName: '',
        roles: [],
        availableRoles: [],
      },
      active: true,
      error: null, // To handle and show any error messages
      isLoading: false,
      userId: null, // from query paremeter
      editUserEmail: null,
      editUserFirstName: null,
      editUserLastName: null,
      creatorId: null,
      availableRoles: [],
      selectedRoles: [], // Array to store selected roles
      isCurrentUser: false,
      password: '',
      passwordError: '',
    };
  },
  mounted() {
    this.getUserIdFromQuery();
    this.initializeForm();
    if(this.creatorId === this.userId){
      this.isCurrentUser = true;
    }
  },
  computed: {
    hasLowercase() {
      return /[a-z]/.test(this.password);
    },
    hasUppercase() {
      return /[A-Z]/.test(this.password);
    },
    hasNumber() {
      return /\d/.test(this.password);
    },
    hasSpecialChar() {
      return /[!@#$%^&*]/.test(this.password);
    },
    isPasswordValid() {
      return (
          this.password.length >= 8 &&
          this.hasLowercase &&
          this.hasUppercase &&
          this.hasNumber &&
          this.hasSpecialChar
      );
    },
  },
  methods: {
    getUserIdFromQuery() {
      this.userId = this.$route.query.uid; // Get UID from query parameter
      this.editUserEmail = this.$route.query.email;
      this.editUserFirstName = this.$route.query.firstName;
      this.newUser.firstName = this.$route.query.firstName;
      this.editUserLastName = this.$route.query.lastName;
      this.newUser.lastName = this.$route.query.lastName;
    },
    updateUser() {
      // Clear previous errors
      this.error = null;

      // Retrieve the API key from environment variables
      const apiKey = process.env.VUE_APP_RPP_USERS_CLOUD_FUNCTION_API_KEY;

      let requestData = {
        firstName: this.newUser.firstName,
        lastName: this.newUser.lastName,
        uid: this.userId,
        creatorId: this.creatorId
      };

      // Add roles to the request data if selectedRoles is not empty
      if (this.selectedRoles.length > 0) {
        requestData.roles = this.selectedRoles;
      }

      // Request to your backend to create the user
      axios.put(RPP_API_SERVER_URL, requestData, {
        headers: {
          'x-txn-auth-token': apiKey
        }
      })
          .then(() => {

            if (this.isCurrentUser) {
              this.$router.push('/users/profile')
            } else {
              this.$router.push('/users/list'); // Redirect after successful creation
            }

          })
          .catch((error) => {
            // Handle errors
            // Initialize a default error message
            let errorMessage = 'An error occurred during user edit.';

            // Check if the error response exists and has the expected format
            if (error.response && error.response.data && error.response.data.error) {
              // Extract the error message from the response
              errorMessage = error.response.data.error.message || errorMessage;
            } else if (error.request) {
              // The request was made but no response was received
              console.log('Error Request:', error.request);
              errorMessage = 'No response received from the server.';
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error Message:', error.message);
              errorMessage = error.message;
            }

            // Update the local state with the extracted error message
            this.error = errorMessage;
          });
    },
    initializeForm() {
      this.isLoading = true;

      const {uid, userAddData} = getUserAddDataFromLocalStorage();

      this.creatorId = uid;

      if (!this.editUserEmail && userAddData) {
        this.editUserEmail = userAddData.email;
      }
      if (!this.editUserFirstName && userAddData) {
        this.editUserFirstName = userAddData.firstName;
        this.newUser.firstName = userAddData.firstName;
      }
      if (!this.editUserLastName && userAddData) {
        this.editUserLastName = userAddData.lastName;
        this.newUser.lastName = userAddData.lastName;
      }
      if (!this.userId && userAddData) {
        this.userId = userAddData.uid;
      }

      const userDocRef = doc(db, RPP_USERS_FIRESTORE_COLLECTION_NAME, uid);

      getDoc(userDocRef)
          .then(userDocSnap => {
            console.log("get userDocRef for uid ", uid);

            if (userDocSnap.exists()) {
              const userDoc = userDocSnap.data();

              this.availableRoles = userDoc.roles.map(roleName => {
                return {
                  name: roleName,
                  description: ROLE_DESCRIPTIONS[roleName] || 'No description available'
                };
              });

            } else {
              console.log("No such document!");
            }
          })
          .catch(error => {
            console.error("Error getting document:", error);
          })
          .finally(() => {
            this.isLoading = false;
          });

    },
    toggleRoleSelection(role) {
      const index = this.selectedRoles.indexOf(role);
      if (index === -1) {
        this.selectedRoles.push(role);
      } else {
        this.selectedRoles.splice(index, 1);
      }
    },
    toggleShowPassword() {
      this.active = !this.active;
    },
    show() {
      this.active = !this.active
    },
    async changePassword() {
      const user = auth.currentUser;
      console.log("user", user);
      try {
        await updatePassword(user, this.password);

        // On successful password update
        this.showSuccessAlert();

      } catch (error) {
        console.error('Error changing password:', error);
        // Handle error (e.g., show error message)
      }
    },
    showSuccessAlert() {
      this.$swal({
        title: 'Password Changed',
        text: 'Your password has been successfully changed. You will be logged out now.',
        icon: 'success',
        confirmButtonText: 'OK'
      }).then(() => {
        this.logoutUser();
      });
    },
    logoutUser() {
      auth.signOut().then(() => {
        localStorage.removeItem('User');
        localStorage.removeItem('userAddData');
        this.$router.push('/auth/login?email=' + encodeURIComponent(this.editUserEmail));
      }).catch((error) => {
        // Handle logout errors
        console.error('Error logging out:', error);
      });
    }
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/components/checkbox';
</style>