<template>
  <Breadcrumbs main="Dashboard" title="Dashboard - General"/>
  <div class="container-fluid">
    <div class="row">

      <!-- card start-->
      <div class="card m-t-15">
        <div class="card-body">

          <p>The following metrics are for HTTP requests made by your account to ryd API gateway:
            <b>{{ this.loggedInUserGroup }}</b>.</p>
          <div v-if="this.loggedInUserGroup === 'ryd' ">
            <div class="row">
              <div class="col-md-4 pe-1">
                <span>Or select the partner for whom you would like to see metrics: </span>
              </div>
              <div class="col-md-3">
                <select class="form-control border border-primary" id="partnerMetricsFor" v-model="partnerMetricsFor"
                        required>
                  <option value="all">ryd</option>
                  <option value="azowo">azowo</option>
                  <option value="fuelio">fuelio</option>
                  <option value="mercedes">mercedes</option>
                  <option value="miles">miles</option>
                  <option value="parkopedia">parkopedia</option>
                  <option value="parkopedia_skoda">parkopedia_skoda</option>
                  <option value="parkopedia_bmw">parkopedia_bmw</option>
                  <option value="poppy">poppy</option>
                </select>
              </div>
            </div>
          </div>

          <div v-if="this.loggedInUserGroup === 'ryd' ">
            <i class="icofont icofont-hand-right font-success">&nbsp;</i><b>ryd</b> means HTTP requests from all
            partners and clients.
          </div>
          <div v-if="this.loggedInUserGroup === 'parkopedia' ">
            <i class="icofont icofont-hand-right font-success">&nbsp;</i><b>parkopedia</b> means HTTP requests from
            parkopedia_bmw and parkopedia_skoda.
          </div>

        </div>
      </div>
      <!-- card end-->

      <!-- Loading Indicator START -->
      <div v-if="isLoadingHttpRequestsStatisticsLast2Hours" class="loading-indicator">
        <h6 class="sub-title mb-0 text-center">Loading ... HTTP Request Metrics in the Last 2 Hours</h6>
        <div class="loader-box">
          <div class="loader-17"></div>
        </div>
      </div>
      <!-- Loading Indicator END -->

      <!--  CARD START -->
      <div class="col-sm-12 col-md-6" v-if="!isLoadingHttpRequestsStatisticsLast2Hours">
        <div class="card card-absolute">
          <div class="card-header bg-primary">
            <h5 class="text-white">HTTP Request Metrics in the Last 2 Hours</h5>
          </div>
          <div class="card-body">
            <div
                v-if="httpRequestsStatisticsLast2Hours">
              <table class="table table-bordernone table-striped display" id="basic-1">
                <tbody>
                <tr>
                  <th class="border-right-column" scope="row">From</th>
                  <td>{{ httpRequestsStatisticsLast2Hours.from }}</td>
                </tr>
                <tr>
                  <th class="border-right-column" scope="row">To</th>
                  <td>{{ httpRequestsStatisticsLast2Hours.to }}</td>
                </tr>
                <tr>
                  <th class="border-right-column" scope="row">Total Number of HTTP Requests
                    <i class="fa fa-info-circle fa-lg m-l-20" data-bs-toggle="tooltip"
                       title="Number of total HTTP requests made from your account to ryd API gateway within the chosen time range.">&nbsp;</i>
                  </th>
                  <td>{{ httpRequestsStatisticsLast2Hours.totalRequestsCount }}</td>
                </tr>
                <tr>
                  <th class="border-right-column" scope="row">Number of Failed HTTP Requests
                    <i class="fa fa-info-circle fa-lg m-l-20" data-bs-toggle="tooltip"
                       title=" Number of unsuccessful HTTP requests from your account to ryd API gateway within the chosen time range, including errors made by your account, API misuse, logic errors on the ryd side, and server errors on the ryd side.">&nbsp;</i>
                  </th>
                  <td>{{ httpRequestsStatisticsLast2Hours.totalFailedRequestsCount }}</td>
                </tr>
                <tr>
                  <th class="border-right-column" scope="row">Number of Successful HTTP Requests
                    <i class="fa fa-info-circle fa-lg m-l-20" data-bs-toggle="tooltip"
                       title="Number of successful HTTP requests from your account to ryd API gateway within the chosen time range."></i>
                  </th>
                  <td>{{ httpRequestsStatisticsLast2Hours.totalSuccessRequestsCount }}</td>
                </tr>
                <tr>
                  <th class="border-right-column" scope="row">Number of HTTP Requests due to ryd Server Failure
                    <i class="fa fa-info-circle fa-lg m-l-20" data-bs-toggle="tooltip"
                       title="Number of unsuccessful HTTP requests from your account to ryd API gateway within the chosen time range due to server errors on ryd's side, indicating that the issue lies with ryd and requires ryd's attention for resolution.">&nbsp;</i>
                  </th>
                  <td>{{ httpRequestsStatisticsLast2Hours.totalServerFailureRequestsCount }}</td>
                </tr>
                <tr>
                  <th class="border-right-column" scope="row">Error Rate
                    <i class="fa fa-info-circle fa-lg m-l-20" data-bs-toggle="tooltip"
                       title="It is a percentage that represents the number of unsuccessful HTTP requests divided by the total number of HTTP requests, rounded to two decimal places">&nbsp;</i>
                  </th>
                  <td>{{ httpRequestsStatisticsLast2Hours.errorRate }}%</td>
                </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>
      <!--  CARD END -->

      <!-- Loading Indicator START -->
      <div v-if="isLoadingHttpRequestsStatisticsLast2Days" class="loading-indicator">
        <h6 class="sub-title mb-0 text-center">Loading ... HTTP Request Metrics in the Last 2 Days</h6>
        <div class="loader-box">
          <div class="loader-18"></div>
        </div>
      </div>
      <!-- Loading Indicator END -->
      <!--  CARD START -->
      <div class="col-sm-12 col-md-6" v-if="!isLoadingHttpRequestsStatisticsLast2Days">
        <div class="card card-absolute">
          <div class="card-header bg-success">
            <h5 class="text-white">HTTP Request Metrics in the Last 2 Days</h5>
          </div>
          <div class="card-body">
            <div
                v-if="httpRequestsStatisticsLast2Days">
              <table class="table table-bordernone table-striped display" id="basic-1">
                <tbody>
                <tr>
                  <th class="border-right-column" scope="row">From</th>
                  <td>{{ httpRequestsStatisticsLast2Days.from }}</td>
                </tr>
                <tr>
                  <th class="border-right-column" scope="row">To</th>
                  <td>{{ httpRequestsStatisticsLast2Days.to }}</td>
                </tr>
                <tr>
                  <th class="border-right-column" scope="row">
                    Total Number of HTTP Requests
                    <i class="fa fa-info-circle fa-lg m-l-20" data-bs-toggle="tooltip"
                       title="Number of total HTTP requests made from your account to ryd API gateway within the chosen time range.">&nbsp;</i>
                  </th>

                  <td>{{ httpRequestsStatisticsLast2Days.totalRequestsCount }}</td>
                </tr>
                <tr>
                  <th class="border-right-column" scope="row">Number of Failed HTTP Requests
                    <i class="fa fa-info-circle fa-lg m-l-20" data-bs-toggle="tooltip"
                       title=" Number of unsuccessful HTTP requests from your account to ryd API gateway within the chosen time range, including errors made by your account, API misuse, logic errors on the ryd side, and server errors on the ryd side.">&nbsp;</i>
                  </th>
                  <td>{{ httpRequestsStatisticsLast2Days.totalFailedRequestsCount }}</td>
                </tr>
                <tr>
                  <th class="border-right-column" scope="row">Number of Successful HTTP Requests
                    <i class="fa fa-info-circle fa-lg m-l-20" data-bs-toggle="tooltip"
                       title="Number of successful HTTP requests from your account to ryd API gateway within the chosen time range."></i>
                  </th>
                  <td>{{ httpRequestsStatisticsLast2Days.totalSuccessRequestsCount }}</td>
                </tr>
                <tr>
                  <th class="border-right-column" scope="row">Number of HTTP Requests due to ryd Server Failure
                    <i class="fa fa-info-circle fa-lg m-l-20" data-bs-toggle="tooltip"
                       title="Number of unsuccessful HTTP requests from your account to ryd API gateway within the chosen time range due to server errors on ryd's side, indicating that the issue lies with ryd and requires ryd's attention for resolution.">&nbsp;</i>
                  </th>
                  <td>{{ httpRequestsStatisticsLast2Days.totalServerFailureRequestsCount }}</td>
                </tr>
                <tr>
                  <th class="border-right-column" scope="row">Error Rate
                    <i class="fa fa-info-circle fa-lg m-l-20" data-bs-toggle="tooltip"
                       title="It is a percentage that represents the number of unsuccessful HTTP requests divided by the total number of HTTP requests, rounded to two decimal places">&nbsp;</i>
                  </th>
                  <td>{{ httpRequestsStatisticsLast2Days.errorRate }}%</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!--  CARD END -->

      <!-- card 2 start-->
      <div class="card card-absolute m-t-15">
        <div class="card-header bg-primary">
          <h5 class="text-white">Important Notes</h5>
        </div>

        <div class="card-body">
          <ul class="list-group">
            <li class="list-group-item"><i class="icofont icofont-hand-right font-primary">&nbsp;</i> The dashboard
              displayed here provides a detailed overview of metrics
              specifically tailored to your account.
            </li>
            <li class="list-group-item"><i class="icofont icofont-hand-right font-primary">&nbsp;</i> It includes
              comprehensive data on orders and requests directly
              associated with the account you are currently logged in with.
            </li>
            <li class="list-group-item"><i class="icofont icofont-hand-right font-primary">&nbsp;</i> The HTTP requests
              mentioned are requests that your account sent to the ryd API gateway https://tt4.thinxcloud.de.
            </li>
          </ul>
        </div>
      </div>
      <!-- card 2 end-->

    </div>
  </div>
</template>
<script>
import axios from 'axios';
import {PARTNER_ANALYTICS_API_KEY, PARTNER_ANALYTICS_SERVER_URL, RPP_API_SERVER_URL} from "@/constants/constants";

export default {
  data() {
    return {
      httpRequestsStatisticsLast2Hours: {},
      httpRequestsStatisticsLast2Days: {},
      loggedInUserId: '',
      loggedInUserGroup: '',
      partnerMetricsFor: '',
      isLoadingHttpRequestsStatisticsLast2Hours: false,
      isLoadingHttpRequestsStatisticsLast2Days: false,
    }
  },
  mounted() {
    const userAddDataAsStr = localStorage.getItem('userAddData');
    const userAddData = userAddDataAsStr ? JSON.parse(userAddDataAsStr) : null;
    this.loggedInUserId = userAddData ? userAddData.uid : null;
    const userGroup = userAddData ? userAddData.userGroup : null;
    this.loggedInUserGroup = userGroup;
    if (userGroup === 'ryd') {
      this.partnerMetricsFor = 'all';
    }
    this.initDataFetch();
  },
  watch: {
    partnerMetricsFor(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fetchHttpRequestsStatisticsLast2Hours();
        this.fetchHttpRequestsStatisticsLast2Days();
      }
    },
  },
  methods: {
    async fetchHttpRequestsStatisticsLast2Hours(orderUuid, fromTimestamp, toTimestamp) {
      this.isLoadingHttpRequestsStatisticsLast2Hours = true;
      try {
        const response = await axios.get(`${PARTNER_ANALYTICS_SERVER_URL}/metrics-nr-of-http-requests`, {
          params: {
            to: new Date().toISOString(),
            from: new Date(new Date().getTime() - 2 * 60 * 60 * 1000).toISOString(),
            partnerMetricsFor: this.partnerMetricsFor
          },
          headers: {
            'x-txn-auth-token': PARTNER_ANALYTICS_API_KEY,
            'uid': this.loggedInUserId
          }
        });

        this.httpRequestsStatisticsLast2Hours = response.data;
        //console.log(response.data);

      } catch (error) {
        console.error('Error fetching API Gateway HTTP Requests:', error);
        // Handle error appropriately
      }finally {
        this.isLoadingHttpRequestsStatisticsLast2Hours = false;
      }
    },
    async fetchHttpRequestsStatisticsLast2Days(orderUuid, fromTimestamp, toTimestamp) {
      this.isLoadingHttpRequestsStatisticsLast2Days = true;
      try {
        const response = await axios.get(`${PARTNER_ANALYTICS_SERVER_URL}/metrics-nr-of-http-requests`, {
          params: {
            to: new Date().toISOString(),
            from: new Date(new Date().getTime() - 2 * 24 * 60 * 60 * 1000).toISOString(),
            partnerMetricsFor: this.partnerMetricsFor
          },
          headers: {
            'x-txn-auth-token': PARTNER_ANALYTICS_API_KEY,
            'uid': this.loggedInUserId
          }
        });

        this.httpRequestsStatisticsLast2Days = response.data;
        //console.log(response.data);

      } catch (error) {
        console.error('Error fetching API Gateway HTTP Requests:', error);
        // Handle error appropriately
      }finally {
        this.isLoadingHttpRequestsStatisticsLast2Days = false;
      }
    },
    convertTimestampToISO(timestamp) {
      return new Date(timestamp).toISOString();
    },
    async initDataFetch() {
      await this.fetchHttpRequestsStatisticsLast2Hours();
      await this.fetchHttpRequestsStatisticsLast2Days();
    },
    async fetchPartnerNames() {
      try {
        const response = await axios.get(`${RPP_API_SERVER_URL}/partner-names`, {
          params: {
            page: 0,
            size: 50
          },
          headers: {
            'x-txn-auth-token': process.env.VUE_APP_RPP_USERS_CLOUD_FUNCTION_API_KEY,
            'uid': this.creatorId
          }
        });
        this.partnerNames = response.data;
        //console.log("partnerNames", partnerNames);
      } catch (error) {
        console.error('Error fetching partner names:', error);
      }
    },
  }
}

</script>

<style scoped>
.border-right-column {
  border-right: 1px solid #dee2e6; /* Adjust color as needed */
}
</style>