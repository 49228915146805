<template>
  <div>
    <div class="container-fluid p-0">
      <div class="row m-0">
        <div class="col-12 p-0">
          <div class="login-card">
            <div>
              <div>
                <a class="logo" href="https://partner-portal.rydcloud.de/">
                  <svg width="94" height="40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M16.5 22.589 9.874 31.08l3.483 2.984 7.164-9.18 5.428 9.776 3.888-2.37-4.293-7.732 7.215 1.572.916-4.615-8.995-1.96 6.577-8.427-3.483-2.984-6.628 8.493-5.53-9.956-3.888 2.37 4.853 8.74-10.593-2.308-.916 4.615 11.43 2.49ZM19.33.663l13.667 5.711 5.661 13.789-5.661 13.788-13.667 5.712L5.66 33.95 0 20.163 5.661 6.374 19.33.663ZM57.21 18.247a3.973 3.973 0 0 0-.674-.127 6.72 6.72 0 0 0-.641-.031c-.688 0-1.267.126-1.737.379-.469.252-.844.557-1.126.915a3.48 3.48 0 0 0-.61 1.168c-.125.421-.188.79-.188 1.105v8.081h-5.131v-15.72h4.943v2.273h.063a5.09 5.09 0 0 1 1.69-1.958c.73-.505 1.575-.757 2.534-.757.209 0 .412.01.61.031.198.021.36.053.485.095l-.219 4.546ZM69.586 32.105a13.79 13.79 0 0 1-1.048 2.194 6.591 6.591 0 0 1-1.377 1.64 5.424 5.424 0 0 1-1.877 1.027c-.72.231-1.57.347-2.55.347-.48 0-.975-.032-1.486-.095a9.13 9.13 0 0 1-1.33-.252l.563-4.356c.25.084.527.152.83.205.302.052.578.079.828.079.793 0 1.367-.184 1.721-.553.355-.368.657-.889.908-1.562l.5-1.294-6.508-15.468h5.695l3.473 10.101h.094l3.097-10.101h5.445l-6.978 18.088ZM89.1 29.737v-2.052h-.063c-.5.8-1.215 1.415-2.143 1.847a6.932 6.932 0 0 1-2.957.647c-1.168 0-2.211-.232-3.129-.694a7.2 7.2 0 0 1-2.331-1.847 8.136 8.136 0 0 1-1.455-2.668 9.994 9.994 0 0 1-.5-3.156c0-1.095.171-2.142.516-3.141a8.21 8.21 0 0 1 1.47-2.636 6.975 6.975 0 0 1 2.316-1.8c.907-.441 1.924-.662 3.05-.662 1.148 0 2.118.22 2.91.663.793.442 1.408.926 1.846 1.452h.063V5.873h5.131v23.864H89.1Zm-.188-7.892c0-.505-.089-1-.266-1.483a4.2 4.2 0 0 0-.751-1.295 3.737 3.737 0 0 0-1.173-.915c-.46-.232-.98-.347-1.565-.347-.605 0-1.137.116-1.596.347a3.66 3.66 0 0 0-1.157.9 3.717 3.717 0 0 0-.704 1.278 4.797 4.797 0 0 0-.235 1.484c0 .505.078 1.005.235 1.5.156.494.39.93.704 1.31.313.378.699.683 1.157.915.46.231.991.347 1.596.347.584 0 1.106-.116 1.565-.347.459-.232.85-.537 1.173-.916.323-.379.574-.81.751-1.294.177-.484.266-.979.266-1.484Z"
                          fill="#232D74"/>
                  </svg>
                  <img
                      class="img-fluid for-dark"
                      src="../assets/images/logo/logo_dark.png"
                      alt="looginpage"
                  />
                </a>
              </div>
              <div class="login-main">


                <form class="theme-form" @submit.prevent="validatePassword">
                  <h6 class="font-primary">Welcome to Ryd Partner Portal!</h6>
                  <hr/>

                  <p v-if="setType === 'SET'">
                    <h4>Set Your Password</h4>
                    <p>Set Your Password to Complete Your Registration</p>
                  </p>
                  <p v-if="setType === 'RESET'">
                    <h4>Reset Your Password</h4>
                    <p>Enter your new password below to regain access to your account.</p>
                  </p>
                  <hr/>


                  <!-- Loading Indicator START -->
                  <div v-if="validateTokenIsActive" class="loading-indicator">
                    <span class="mb-0 text-center font-primary">
                      We are currently verifying the link for security purposes. This includes checking for expiration and prior usage. Please wait a moment.</span>
                    <div class="loader-box">
                      <div class="loader-17"></div>
                    </div>
                    <hr/>
                  </div>
                  <!-- Loading Indicator END -->

                  <div v-if="linkBroken" class="alert alert-danger validate-error" role="alert">
                    The link is broken or missing parameters.
                  </div>
                  <div class="row fw-bolder f-w-900">
                    <span v-if="error" class="error-message m-b-25 validate-error">{{ error }}</span>
                  </div>


                  <div v-if="!validateTokenIsActive && !tokenInvalid" class="form-group">
                    <label class="col-form-label"><b>Email:</b></label>
                    <div class="form-input position-relative m-b-20">
                      {{ email }}
                    </div>

                    <label class="col-form-label"><b>Password*:</b></label>
                    <div class="form-input position-relative">

                      <input
                          class="form-control"
                          :type="active?'password':'text'"
                          v-model="password"
                          required=""
                          placeholder=""
                      />
                      <div class="show-hide" @click="toggleShowPassword"><span :class="active?'show':'hide'"> </span>
                      </div>
                    </div>
                    <span class="validate-error m-t-15 m-b-15" v-if="passwordError">{{ passwordError }}</span>
                    <br/>
                    <ul class="password-hints">
                      <li :class="{'text-success': password.length >= 8}">
                        <i :class="['icofont icofont-hand-right', password.length >= 8 ? 'font-success' : 'font-primary']">&nbsp;</i>
                        At least 8 characters long
                      </li>
                      <li :class="{'text-success': hasLowercase}">
                        <i :class="['icofont icofont-hand-right', hasLowercase ? 'font-success' : 'font-primary']">&nbsp;</i>
                        Contains at least one lowercase letter
                      </li>
                      <li :class="{'text-success': hasUppercase}">
                        <i :class="['icofont icofont-hand-right', hasUppercase ? 'font-success' : 'font-primary']">&nbsp;</i>
                        Contains at least one uppercase letter
                      </li>
                      <li :class="{'text-success': hasNumber}">
                        <i :class="['icofont icofont-hand-right', hasNumber ? 'font-success' : 'font-primary']">&nbsp;</i>
                        Contains at least one number
                      </li>
                      <li :class="{'text-success': hasSpecialChar}">
                        <i :class="['icofont icofont-hand-right', hasSpecialChar ? 'font-success' : 'font-primary']">&nbsp;</i>
                        Contains at least one special character (e.g., !@#$%^&*)
                      </li>
                    </ul>

                  </div>
                  <div v-if="!validateTokenIsActive && !tokenInvalid" class="form-group mb-0 m-t-30">
                    <button
                        :disabled="isButtonDisabled || validateTokenIsActive"
                        :class="['btn btn-block w-100', isButtonDisabled || validateTokenIsActive ? 'btn-primary' : 'btn-success']"
                        type="submit">
                      {{ buttonLabel }}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {RPP_API_SERVER_URL} from "@/constants/constants";
import axios from "axios";

export default {
  data() {
    return {
      active: true,
      password: '',
      passwordError: '',
      linkBroken: false,
      tokenInvalid: false,
      token: '',
      uid: '',
      email: '',
      setType: 'SET',
      error: null, // To handle and show any error messages
      validateTokenIsActive: false,
      tokenValidationSuccessResultMessage: null
    }
  },
  created() {
    // Parse query parameters from the URL
    this.token = this.$route.query.token;
    this.uid = this.$route.query.uid;
    this.email = this.$route.query.email;
    this.setType = this.$route.query.setType || 'SET';


    // Check if any of the parameters are missing
    if (!this.token || !this.uid || !this.email || !this.setType) {
      this.linkBroken = true;
    } else {
      this.validateTokenIsActive = true;
      this.validateToken();
    }
  },
  computed: {
    hasLowercase() {
      return /[a-z]/.test(this.password);
    },
    hasUppercase() {
      return /[A-Z]/.test(this.password);
    },
    hasNumber() {
      return /\d/.test(this.password);
    },
    hasSpecialChar() {
      return /[!@#$%^&*]/.test(this.password);
    },
    isPasswordValid() {
      return (
          this.password.length >= 8 &&
          this.hasLowercase &&
          this.hasUppercase &&
          this.hasNumber &&
          this.hasSpecialChar
      );
    },
    isButtonDisabled() {
      const disabled = this.linkBroken || this.tokenInvalid || !this.isPasswordValid;
      //console.log('Button disabled:', disabled);
      return disabled;
    },
    buttonLabel() {
      // The button label changes based on setType
      return this.setType === 'SET' ? 'Set Password' : 'Reset Password';
    },
  },
  methods: {
    show() {
      this.active = !this.active
    },
    toggleShowPassword() {
      this.active = !this.active;
    },
    validatePassword() {
      // Reset the error message each time we validate
      this.passwordError = '';

      // Define the password rules
      const minLength = 8;
      const lowercase = /[a-z]/.test(this.password);
      const uppercase = /[A-Z]/.test(this.password);
      const number = /\d/.test(this.password);
      const specialChar = /[!@#$%^&*]/.test(this.password);

      // Check each rule and set the error message if not met
      if (this.password.length < minLength) {
        this.passwordError = `Password must be at least ${minLength} characters long.`;
      } else if (!lowercase) {
        this.passwordError = 'Password must contain at least one lowercase letter.';
      } else if (!uppercase) {
        this.passwordError = 'Password must contain at least one uppercase letter.';
      } else if (!number) {
        this.passwordError = 'Password must contain at least one number.';
      } else if (!specialChar) {
        this.passwordError = 'Password must contain at least one special character.';
      }

      // If there's an error, don't submit and show the error message
      if (this.passwordError) {
        // Handle the error, e.g., show it to the user
      } else {
        this.handleLoadingAndDoSetPassword();
      }
    },
    validateToken() {
      const apiKey = process.env.VUE_APP_RPP_USERS_CLOUD_FUNCTION_API_KEY;

      // Replace with your actual endpoint and include necessary headers or credentials
      axios.get(`${RPP_API_SERVER_URL}/token-validation?token=${encodeURIComponent(this.token)}&setType=${this.setType}`, {
        headers: {
          'x-txn-auth-token': apiKey,
          'uid': this.uid
        }
      }).then(response => {
        this.validateTokenIsActive = false;
        if (response.data && response.data.valid) {
          this.tokenInvalid = false;
          this.tokenValidationSuccessResultMessage = 'Your link is valid and secure. Please proceed to set or reset your password for access to the Ryd Partner Portal.';
        } else if (response.data && !response.data.valid && response.data.errorDetails) {
          this.tokenInvalid = true;
          this.error = response.data.errorDetails;
        }
      }).catch(error => {
        this.validateTokenIsActive = false;
        let errorMessage = 'An error occurred.';
        console.error('Error during token validation:', error);
        if (error.response && error.response.data && error.response.data.errorDetails) {
          this.error = error.response.data.errorDetails || errorMessage;
        } else if (error.response && error.response.data && error.response.data.errorMessage) {
          this.error = error.response.data.errorMessage || errorMessage;
        } else {
          this.error = error.message;
        }
        this.tokenInvalid = true;
      });
    },
    validateTokenAndSetPassword() {
      // Clear previous errors
      this.error = null;

      // Retrieve the API key from environment variables
      const apiKey = process.env.VUE_APP_RPP_USERS_CLOUD_FUNCTION_API_KEY;

      // Request to your backend to create the user
      axios.post(RPP_API_SERVER_URL + "/token-validation-and-password-setup", {
        token: this.token,
        newPassword: this.password
        // other data...
      }, {
        headers: {
          'x-txn-auth-token': apiKey,
          'uid': this.uid
        }
      }).then(() => {
        this.$router.push('/auth/login?email=' + encodeURIComponent(this.email)); // Redirect after successful creation
      }).catch((error) => {


        // Initialize a default error message
        let errorMessage = 'An error occurred.';

        // Check if the error response exists and has the expected format
        if (error.response && error.response.data && error.response.data.errorDetails) {
          // Extract the error message from the response
          errorMessage = error.response.data.errorDetails || errorMessage;
        } else if (error.response && error.response.data && error.response.data.errorMessage) {
          // Extract the error message from the response
          errorMessage = error.response.data.errorMessage || errorMessage;
        } else {
          errorMessage = error.message;
        }

        // Update the local state with the extracted error message
        this.error = errorMessage;
      });
    },
    async handleLoadingAndDoSetPassword() {
      try {
        // Show loading alert
        this.$swal({
          title: 'Processing...',
          text: 'Please wait while we reset your password.',
          icon: 'info',
          allowOutsideClick: false,
          showConfirmButton: false,
          timer: 5000, // in milliseconds
          willOpen: () => {
            this.$swal.showLoading();
          }
        });

        setTimeout(() => {
          this.validateTokenAndSetPassword();
        }, 2000);



        // Redirect to login page or other actions
      } catch (error) {
        // Close the loading alert and show error message
        this.$swal.close();
        this.$swal({
          title: 'Error',
          text: 'There was an issue resetting your password.',
          icon: 'error',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK'
        });
      }
    },
  }
}
</script>
<style>
.password-hints {
  list-style: none;
  padding: 0;
  margin-top: 10px;
}

.password-hints li {
  font-size: 0.8rem;
  color: #6c757d; /* default color for hint text */
}

.password-hints li.text-success {
  color: #28a745; /* success color when rule is met */
}
</style>