<template>
  <Breadcrumbs title="Getting Started" main="Deeplinking Registration" />
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">

        <div class="card">
          <div class="card-header bg-primary">
            <h5>1. Registration Process Description</h5>
          </div>
          <div class="card-body">
            <p>When a user enters into a contract with a ryd partner and chooses the additional refuelling
              discount option,
              they have the flexibility to do so through the ryd partner's web application, Android app or iOS
              app.</p>
            <br />
            <p>
              When a user clicks on a link provided in an email or on a Ryd partner's customer portal or
              native app, they are seamlessly redirected to Ryd to complete the registration process. During
              this registration process, Ryd's system collects important information, including the user's
              origin/partner ID, the user's customer ID with the Ryd partner and the specific Ryd partner
              product ID related to the selected fuel discount option. These details play a crucial role in
              accurately tracking and associating the user's registration within the Ryd platform.
            </p>
          </div>
        </div>

        <div class="card">
          <div class="card-header bg-primary">
            <h5>2. Registration Deeplinking</h5>
          </div>
          <div class="card-body">
            <p>Ryd uses adjust-based deeplinking to give a ryd partner's customers a smooth, seamless
              registration experience with automatic access to exclusive discounts.</p>
            <br />
            <p>
              Adjust Deeplinking provides a unified approach to handle incoming links across multiple
              platforms such as Android, iOS, and web applications. The primary advantage of using Adjust
              Deeplinking is its intelligence to determine the device and platform type and take the most
              appropriate action based on the context. In the context of the ryd platform, it offers a
              streamlined experience for users who click on a deeplink, ensuring they land on the correct page
              or native applications, whether they're on a smartphone or a desktop PC.
            </p>
            <p>Click on the diagram below to understand the process.</p>

            <div class="container">
              <div class="row justify-content-center">
                <div class="col-md-6">
                  <div class="row my-gallery gallery" id="aniimated-thumbnials" itemscope="" data-pswp-uid="1">
                    <figure class="col-md-3 col-6 img-hover hover-15" v-for="(n, index) in gallery1" :key="index"
                      @click="() => showImg(index)" itemprop="associatedMedia" itemscope="">
                      <div>
                        <img :src="require('../../../assets/images/deeplinking/' + n.image)" itemprop="thumbnail"
                          alt="Image description" class="img-fluid">
                      </div>
                    </figure>
                  </div>
                  <vue-easy-lightbox :visible="visible" :index="index" :imgs="lightBoxImages" @hide="handleHide">
                  </vue-easy-lightbox>
                </div>
              </div>
            </div>


          </div>
        </div>


        <div class="card">
          <div class="card-header bg-primary">
            <h5>3. Related Resource</h5>
          </div>
          <div class="card-body">
            <p>This comprehensive resource provides you with all the necessary tools and information for seamless
              integration and interaction with our services.
            </p>
            <ul class="list-group">
              <li class="list-group-item"><b>API Documentation:</b> Explore our detailed OpenAPI documentation presented
                through Swagger UI for a clear understanding of our API structure and capabilities. You can access <a
                  href="/integration-guide/deeplinking-registration/api-reference">this link</a>.</li>
              <li class="list-group-item"><b>Guides and Tutorials:</b> Learn how to generate a deeplink either directly
                within your own system or via the ryd API. These practical, step-by-step guides are available at <a
                  href="/integration-guide/deeplinking-registration/guides-and-tutorials">this link</a>.
              </li>
              <li class="list-group-item"><b>Changelog:</b> Stay updated with the latest changes and enhancements to our
                API. The changelog is regularly maintained and can be found at
                <a href="/integration-guide/deeplinking-registration/changelog">this link</a>.
              </li>
              <li class="list-group-item"><b>FAQs:</b> For quick answers to common queries and clarifications, visit our
                FAQ section at
                <a href="/integration-guide/deeplinking-registration/faqs">this link</a>.
              </li>
            </ul>
          </div>
        </div>


      </div>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      lightBoxImages: [],
      visible: false,
      gallery1: [
        {
          image: '01.png'
        },
      ],
    }
  },
  methods: {
    showImg(index) {
      this.index = index
      this.visible = true
    },
    handleHide() {
      this.visible = false;
      this.$nextTick(() => {
        document.body.style.overflow = '';
      });
    },
  },
  mounted() {
    this.gallery1.forEach(item => {
      this.lightBoxImages.push(require('@/assets/images/deeplinking/' + item.image))
    })
  }
}
</script>