<template>
  <Breadcrumbs title="FAQs" main="Deeplinking Registration"/>
  <div class="container-fluid">
    <div class="row">

      <div class="col-sm-12 col-md-12">
        <div class="card card-absolute">
          <div class="card-header bg-primary">
            <h5 class="text-white">How can I test a deep link on the ryd staging system?</h5>
          </div>
          <div class="card-body">
            <p>To test a deep link on the ryd staging/test system, follow these steps outlined in <a
                href="/integration-guide/deeplinking-registration/guides-and-tutorials">Guides and
              Tutorials</a> to generate a deeplink and test it on a desktop pc.
            </p>
            <p>
              However, please note that testing the deep linking handling of the ryd Android app and ryd iOS
              app is limited due to restrictions imposed by Apple and Google on native app testing. Ryd does
              provide staging versions of the ryd Android and iOS apps, but they can only be downloaded and
              tested on registered devices. If you wish to test deep link handling on the ryd Android app and
              ryd iOS app within the ryd staging system, please reach out to ryd for assistance in configuring
              the necessary settings.
            </p>
          </div>
        </div>
      </div>


      <div class="col-sm-12 col-md-12">
        <div class="card card-absolute">
          <div class="card-header bg-primary">
            <h5 class="text-white">How many times can a customer register?</h5>
          </div>
          <div class="card-body">
            <p>
              In the ryd user management system, each user's email address is uniquely identified. This means
              that an email address can only be associated with one registration. Additionally, when a ryd
              partner submits a customer ID (identified by the field customerIdAtPartner) to ryd, we conduct a
              thorough uniqueness check based on both the customerIdAtPartner value and the partner
              identifier.
            </p>
            <p>
              It's important to note that if a customer has already completed a registration using a specific
              <code>customerIdAtPartner</code> with a particular <code>partner</code>, any further registration attempts
              using the same
              <code>customerIdAtPartner</code> and <code>partner</code> combination will not be processed. While these
              subsequent
              attempts will technically be successful, they will not result in the storage of a new
              <code>customerIdAtPartner</code> nor in the application of any additional discounts or benefits. This
              ensures
              the integrity and uniqueness of each registered customer within our system.
            </p>
          </div>
        </div>
      </div>


    </div>
  </div>
</template>