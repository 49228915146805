<template>
  <Breadcrumbs title="Add Partner" main="Partner Administration"/>

  <div v-if="isLoading">
    <h6 class="sub-title mb-0 text-center">Creating the expected partner ...</h6>
    <div class="loader-box">
      <div class="loader-19"></div>
    </div>
  </div>

  <div class="col-sm-12" v-if="!isLoading">
    <div class="card card-absolute">
      <div class="card-header bg-primary">
        <h5>Create a new Partner</h5>
      </div>
      <div class="card-body">
        <div class="form theme-form">

          <div class="row fw-bolder f-w-900">
            <p v-if="error" class="error-message m-b-25">{{ error }}</p>
          </div>

          <div class="row border-bottom m-b-20">
            <div class="col">
              <div class="mb-3">
                <label for="partnerName" class="fw-bold">Partner Name *</label>
                <input class="form-control border border-primary" type="text" id="partnerName"
                       v-model="partnerName" required placeholder="the new partner's name">
              </div>
            </div>
          </div>

          <div class="row ">
            <div class="col">
              <button class="btn btn-pill btn-success btn-air-success active btn-lg" @click="createPartner"
                      title="Create a new user">
                <i class="fa fa-send m-r-15"></i>Create
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="col-sm-12 col-md-12">
    <div class="card card-absolute">
      <div class="card-header bg-info">
        <h5 class="text-white">Important Note</h5>
      </div>
      <div class="card-body">

        <ul class="list-group">
          <li class="list-group-item">
            <i class="icofont icofont-hand-right font-primary">&nbsp;</i><b>Naming Convention for Partner Names:</b>
            Please ensure that the name of the partner is formatted in lowercase letters, utilizing underscores (_) as separators. The name should consist only of English letters, digits, and underscores. Refrain from using any special characters, spaces, or German umlauts.
            For example, use 'example_partner' instead of
            'Example-Partner' or 'ExämplePärtner'.
          </li>
          <li class="list-group-item">
            <i class="icofont icofont-hand-right font-primary">&nbsp;</i><b>Length of Partner Names:</b>
            The partner name must not exceed 50 characters in length.
          </li>
          <li class="list-group-item"><i class="icofont icofont-hand-right font-primary">&nbsp;</i><b>Uniqueness of
            Partner Names:</b>
            Each partner name must be distinct and unique across all partner accounts.
            It is crucial to verify that the chosen name has not been previously used or registered within our system to
            maintain uniqueness and avoid duplication.
          </li>
        </ul>

      </div>
    </div>
  </div>

</template>

<script>
import axios from 'axios';
import {RPP_API_SERVER_URL} from "@/constants/constants";
import {getUserAddDataFromLocalStorage} from "@/utils/userUtils";


export default {
  name: 'CreatePartner',
  data() {
    return {
      error: null,
      isLoading: false,
      creatorId: null,
      partnerName: null,
      isUserGroupRyd: false
    };
  },
  mounted() {
    this.initializeForm();
  },
  methods: {
    createPartner() {
      // Clear previous errors
      this.error = null;

      if(!this.partnerName){
        this.error = "Please enter a valid partner name.";
        return;
      }


      if(this.partnerName.length > 50){
        this.error = "The partner name must not exceed 50 characters in length.";
        return;
      }

      // validate partner name
      const regex = /^[a-z_]+$/;
      const valid = regex.test(this.partnerName);

      if(!valid){
        this.error = "The given partner name is NOT valid. The name should consist only of English letters, digits, and underscores.";
        return;
      }


      this.isLoading = true;

      // Retrieve the API key from environment variables
      const apiKey = process.env.VUE_APP_RPP_USERS_CLOUD_FUNCTION_API_KEY;

      // Request to your backend to create the user
      axios.post(RPP_API_SERVER_URL + "/partner-administration", {
        partnerName: this.partnerName,
      }, {
        headers: {
          'x-txn-auth-token': apiKey,
          'uid': this.creatorId
        }
      })
          .then(() => {
            this.isLoading = false;
            this.$swal({
              title: "Success",
              text: "Partner successfully created.",
              icon: "success",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK"
            });
          })
          .catch((error) => {
            this.isLoading = false;
            if (error.response && error.response.data) {
              // Adjust 'errorMessage' or 'message' depending on your API's error structure
              this.error = error.response.data.errorMessage || error.response.data.message || 'An error occurred during partner creation.';
            } else {
              // Generic error message if the response is not available
              this.error = 'An error occurred during partner creation.';
            }
          });
    },
    initializeForm() {
      const {uid, isUserGroupRyd} = getUserAddDataFromLocalStorage();
      this.creatorId = uid;
      this.isUserGroupRyd = isUserGroupRyd;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/components/checkbox';
</style>