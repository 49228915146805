import {Popover} from 'bootstrap/dist/js/bootstrap.esm.min';

let enablePopovers = true;

export function hideAllPopovers() {
    if (!enablePopovers) {
        return; // Exit the function if popovers are disabled
    }

    const popoverElements = document.querySelectorAll('[data-bs-toggle="popover"]');
    popoverElements.forEach(el => {
        const popoverInstance = Popover.getInstance(el);
        if (popoverInstance) {
            popoverInstance.hide();
        }
    });
}


export function  initializePopovers() {
    if (!enablePopovers) {
        return; // Exit the function if popovers should not be initialized
    }

    const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]');
    popoverTriggerList.forEach(popoverTriggerEl => {
        new Popover(popoverTriggerEl, {
            trigger: 'focus hover'
        });
    });
}

export function  initializePopoversInChildComponent(element) {
    if (!enablePopovers) {
        return; // Exit the function if popovers should not be initialized
    }

    const popoverTriggerList = element.querySelectorAll('[data-bs-toggle="popover"]');
    popoverTriggerList.forEach(popoverTriggerEl => {
        new Popover(popoverTriggerEl, {
            trigger: 'focus hover'
        });
    });
}