
// Returns the date part in 'YYYY-MM-DD' format from a date string in ISO format
export function parseDateStringYyyyMmDd(dateString) {
    return dateString.split('T')[0];
}

export function convertDateToISOString(date) {
    return new Date(date).toISOString();
}

export function parseDateStringYyyyMmDdNoBinder(dateString) {
    const yyyyMmDd = dateString.split('T')[0];
    return yyyyMmDd.replace(/-/g, ''); // Remove hyphens
}