<template>
  <div class="col-sm-12 col-xl-12 box-col-12">
    <div class="card">
      <div class="card-header pb-0">
        <h3>Mixed Chart 2</h3>
      </div>
      <div class="card-body">
        <div id="mixedchart">
          <apexchart height="350" type="line" :options="chartOptions" :series="series"></apexchart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
var primary = localStorage.getItem('primary_color') || '#24695c';
var secondary = localStorage.getItem('secondary_color') || '#ba895d';
export default {
  data() {
    return {
      series: [
        {
          name: 'Error Rate',
          type: 'area',
          data: [5.04, 0.12, 1.92, 3.91, 4.87]
        }
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: 'line',
          stacked: false,
          toolbar: {
            show: false
          }
        },
        stroke: {
          width: [0, 2, 5],
          curve: 'smooth'
        },
        plotOptions: {
          bar: {
            columnWidth: '50%'
          }
        },
        // colors: ['#3A5794', '#A5C351', '#E14A84'],
        fill: {
          opacity: [0.85, 0.25, 1],
          gradient: {
            inverseColors: false,
            shade: 'light',
            type: "vertical",
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100, 100, 100]
          }
        },
        labels: ['2023-12-12', '2023-12-13', '2023-12-14', '2023-12-15', '2023-12-16'],
        markers: {
          size: 0
        },
        xaxis: {
          type: 'datetime'
        },
        yaxis: {
          min: 0
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: function (y) {
              return y;
            }
          }
        },
        legend: {
          labels: {
            useSeriesColors: true
          },
        },
        colors: [primary, secondary, '#222222']
      }
    }
  }
}
</script>
