<template>
  <Breadcrumbs main="Accounting" title="Q8 Accumulated Invoices"/>

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">

        <!-- CARD 1 START -->
        <div class="card">
          <div class="card-body">
            <div class="theme-form">

              <!-- INPUT GROUP START -->
              <div class="input-group m-0 col-sm-12">
                <div class="row align-items-center m-r-30">

                  <div class="col text-center f-w-700">
                    File Name Search Item:
                  </div>
                </div>

                <input v-model="searchQuery" class="form-control form-control-plaintext ryd-search-input"
                       type="search" id="search-query"
                       placeholder="Enter a search term that is part of the file name ..">

                <button @click="fetchFiles"
                        class="btn btn-pill btn-success btn-air-success active input-group-text m-r-20">
                  <i class="fa fa-search m-r-5"></i>Search
                </button>


              </div>
            </div>
            <!-- INPUT GROUP END -->
          </div>
        </div>
        <!-- CARD 1 END -->

        <!-- CARD 2 START -->
        <div class="card" v-if="!isLoadingInvoiceFilesResponse && invoiceResponse && invoiceResponse.files && invoiceResponse.files.length > 0">
          <div class="card-body">

            <table class="table table-bordernone table-striped display" id="basic-1">
              <thead class="bg-primary text-white">
              <tr>
                <th class="bg-primary" scope="col">File Name</th>
                <th class="bg-primary" scope="col">File Creation Date Time</th>
                <th class="bg-primary" scope="col">Download</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="fileItem in invoiceResponse.files" :key="fileItem.name">
                <td>{{ fileItem.name }}</td>
                <td>{{ fileItem.timeCreated }}</td>
                <td>
                  <a href="#" @click.prevent="downloadFile(fileItem)">
                    Download
                  </a>
                </td>
              </tr>
              </tbody>
            </table>

            <nav aria-label="User list pagination" class="m-t-15">
              <ul class="pagination">

                <li class="page-item" :class="{ disabled: currentPage === 1 }">
                  <button class="page-link" @click="prevPage" :disabled="currentPage === 1">Previous Page</button>
                </li>

                <li class="page-item" :class="{ disabled: !invoiceResponse.pageToken }">
                  <button class="page-link" @click="nextPage" :disabled="!invoiceResponse.pageToken">Next Page</button>
                </li>
              </ul>
            </nav>

          </div>
        </div>


        <div class="card" v-if="!isLoadingInvoiceFilesResponse && invoiceResponse && !invoiceResponse.files">
          <div class="card-body">
            <b>No File has been found with the given search item.</b>
          </div>
        </div>

        <div v-if="isLoadingInvoiceFilesResponse">
          <div class="card">
            <div class="card-body">
              <!-- Loading Indicator START -->
              <div class="loading-indicator">
                <h6 class="sub-title mb-0 text-center">Loading ... Invoice Files</h6>
                <div class="loader-box">
                  <div class="loader-17"></div>
                </div>
              </div>
              <!-- Loading Indicator END -->
            </div>
          </div>
        </div>


      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios';
import {PARTNER_ANALYTICS_API_KEY, PARTNER_ANALYTICS_SERVER_URL} from "@/constants/constants";
import {getUserAddDataFromLocalStorage} from "@/utils/userUtils";

export default {
  data() {
    return {
      selectedDirectory: 'partner-transfer/q8/outgoing',
      invoiceResponse: {
        files: null,
        pageToken: null,
        errorMessage: null
      },
      loggedInUserId: '',
      loggedInUserIsRyd: false,
      loggedInUserGroup: null,
      searchQuery: null,
      isLoadingInvoiceFilesResponse: false,
      currentPage: 1,
      pageTokens: [], // To keep track of pageTokens for previous pages
      pageSize: 10,
      totalPages: 0,
    };
  },
  created() {
    const {uid, userGroup, isUserGroupRyd} = getUserAddDataFromLocalStorage();
    this.loggedInUserId = uid;
    this.loggedInUserIsRyd = isUserGroupRyd;
    this.loggedInUserGroup = userGroup;
    this.fetchFiles();
  },
  methods: {
    async fetchFiles(pageToken = null, nextPage = true) {
      this.isLoadingInvoiceFilesResponse = true;
      try {
        const params = {
          pageSize: this.pageSize,
          //directory: this.selectedDirectory,
          searchQuery: this.searchQuery
        };

        if (nextPage) {
          params.pageToken = pageToken || (this.invoiceResponse ? this.invoiceResponse.pageToken : null);
        } else {
          if (this.currentPage > 1) {
            this.pageTokens.pop(); // Go back to the previous token
            params.pageToken = this.pageTokens[this.pageTokens.length - 1] || null;
          }
        }

        const response = await axios.get(`${PARTNER_ANALYTICS_SERVER_URL}/accounting/partner-exchanger/invoice-files`, {
          params: params,
          headers: {
            'x-txn-auth-token': PARTNER_ANALYTICS_API_KEY,
            'uid': this.loggedInUserId,
          }
        });

        this.invoiceResponse = await response.data;

        // Update current page if navigating to the next page
        if (nextPage && this.invoiceResponse && this.invoiceResponse.pageToken) {
          this.pageTokens.push(this.invoiceResponse.pageToken); // Add new token to the array
        }

      } catch (error) {
        this.invoiceResponse = {};
        this.invoiceResponse.errorMessage = error.response?.data?.error;
        console.error('Error fetching Invoice Files:', error);
      } finally {
        this.isLoadingInvoiceFilesResponse = false;
      }
    },

    async downloadFile(file) {
      const url = `${file.url}`;

      try {
        const response = await fetch(url, {
          headers: {
            'x-txn-auth-token': PARTNER_ANALYTICS_API_KEY,
            'uid': this.loggedInUserId,
          }
        });
        if (!response.ok) {
          this.$toast.show('Failed to copy the ', {theme: 'outline', position: 'top', type: 'danger', duration: 8000});
        }

        const blob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = file.name.split('/').pop(); // Extracts file name from path
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(downloadUrl);
        a.remove();
      } catch (error) {
        console.error('Download failed:', error);
      }
    },

    nextPage() {
      this.currentPage++;
      this.fetchFiles(null, true); // nextPage is true
    },

    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetchFiles(null, false); // nextPage is false
      }
    },

  }
};
</script>