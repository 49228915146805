<template>
  <Breadcrumbs main="Guardian System and Alerts" title="Cashier System Configurations"/>

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">

        <!-- Full Page Loading Overlay -->
        <div v-if="isLoading">
          <h6 class="sub-title mb-0 text-center">Loading ...</h6>
          <div class="loader-box">
            <div class="loader-19"></div>
          </div>
        </div>

        <!-- Main form content -->
        <div class="col-sm-12" v-if="!isLoading">
          <div class="card">
            <div class="card-header bg-primary">
              <h5>List Cashier System Configuration</h5>
            </div>
            <div class="card-body">
               LIST
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_URL, RPP_API_SERVER_URL} from "@/constants/constants";

export default {
  name: 'CreateCashierSystemConfiguration',
  data() {
    return {
      newConfiguration: {
        cashierSystemName: '',
        additionalConfigurations: ''
      },
      isLoading: false,
      error: null,
    };
  },
  methods: {
    createConfiguration() {
      this.isLoading = true;
      this.error = null;

      axios.post(`${PARTNER_PERFORMANCE_GUARDIAN_API_SERVER_URL}/cashier-system-configurations`, this.newConfiguration)
          .then(() => {
            this.$router.push('/configurations/list'); // Redirect after successful creation
          })
          .catch(error => {
            this.error = error.response && error.response.data.message ? error.response.data.message : 'Failed to create configuration';
          })
          .finally(() => {
            this.isLoading = false;
          });
    }
  }
};
</script>

<style scoped>
/* Add any specific styles for your component here */
</style>