<template>
  <div class="search-links tab-pane fade" :class="{'show active': showtab == 'accountMetadata'}"
       id="account-metadata" role="tabpanel" aria-labelledby="accountMetadata">
    <div class="col-sm-12 col-md-12 justify-content-center" v-if="!isLoadingApiAccountMetadata">
      <div v-if="apiResponseApiAccountMetadata && apiResponseApiAccountMetadata.length">
        <div class="d-flex justify-content-center">
          <table class="table table-bordernone table-striped display text-center" id="basic-1">
            <tbody>
            <tr>
              <th>Mobile Platform Name</th>
              <td v-for="(metadata, index) in apiResponseApiAccountMetadata" :key="'platform-name-' + index">
                {{ metadata.mobile_platform_name }}
              </td>
            </tr>
            <tr>
              <th>Mobile Platform Version</th>
              <td v-for="(metadata, index) in apiResponseApiAccountMetadata"
                  :key="'platform-version-' + index">{{ metadata.moblile_platform_version }}
              </td>
            </tr>
            <tr>
              <th>Mobile Phone Brand</th>
              <td v-for="(metadata, index) in apiResponseApiAccountMetadata" :key="'brand-' + index">
                {{ metadata.mobile_brand_name }}
              </td>
            </tr>
            <tr>
              <th>Mobile Phone Model Id</th>
              <td v-for="(metadata, index) in apiResponseApiAccountMetadata" :key="'model-id-' + index">
                {{ metadata.mobile_model_name }}
              </td>
            </tr>
            <tr>
              <th>Mobile Phone Model Name</th>
              <td v-for="(metadata, index) in apiResponseApiAccountMetadata" :key="'model-name-' + index">
                {{ metadata.mobile_marketing_name }}
              </td>
            </tr>
            <tr>
              <th>Ad Tracking Enabled?</th>
              <td v-for="(metadata, index) in apiResponseApiAccountMetadata" :key="'ad-tracking-' + index">
                {{ metadata.is_limited_ad_tracking }}
              </td>
            </tr>
            <tr>
              <th>Version of Ryd Native App</th>
              <td v-for="(metadata, index) in apiResponseApiAccountMetadata" :key="'app-version-' + index">
                {{ metadata.ryd_native_app_version }}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-else-if="apiResponseApiAccountMetadata && apiResponseApiAccountMetadata.errorMessage">
        <div class="text-center fw-bold fs-6 font-danger">
          {{ this.apiResponseApiAccountMetadata.errorMessage }}</div>
      </div>

      <div v-if="isLoadingApiAccountMetadata" class="loading-indicator">
        <h6 class="sub-title mb-0 text-center">Loading 2... Account Metadata</h6>
        <div class="loader-box">
          <div class="loader-18"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TabAccountMetadata',
  props: {
    apiResponseApiAccountMetadata: {},
    isLoadingApiAccountMetadata: {},
    showtab: {}
  }
}
</script>
<style scoped>
.table-striped > tbody > tr:nth-child(odd) > td {
  background-color: #f2f2f2; /* Light gray for odd rows */
}

.table-striped > tbody > tr:nth-child(even) > td {
  background-color: #ffffff; /* White for even rows */
}

</style>