<template>
  <Breadcrumbs main="Troubleshooting" title="Order Details"/>

  <div class="container-fluid search-page">
    <div class="row">
      <div class="col-sm-12">

        <!-- CARD 1 START -->
        <div class="card">
          <div class="card-body">
            <div class="theme-form">

              <!-- INPUT GROUP 1 START -->
              <div class="input-group m-b-10">
                <div class="row align-items-center">
                  <div class="col text-center f-w-700">
                    Search Date Range:
                  </div>
                  <div class="col">
                    <input v-model="fromTimestamp" type="date" class="form-control"
                           placeholder="From Timestamp">
                  </div>
                  <div class="col-auto text-center"> <!-- Center the icon in a narrower column -->
                    <i class="fa fa-long-arrow-right"></i>
                  </div>
                  <div class="col">
                    <input v-model="toTimestamp" type="date" class="form-control" placeholder="To Timestamp">
                  </div>
                </div>
              </div>
              <!-- INPUT GROUP 1 END -->

              <!-- INPUT GROUP 2 START -->
              <div class="input-group m-0 col-sm-12">
                <div class="row align-items-center m-r-30">

                  <div class="col text-center f-w-700">
                    Order uuid:
                  </div>
                </div>

                <input v-model="orderUuid" class="form-control form-control-plaintext ryd-search-input"
                       type="search" id="order-details-search"
                       placeholder="Enter an order uuid from staging or production environment ..">

                <button @click="updateTabs"
                        class="btn btn-pill btn-success btn-air-success active input-group-text m-r-20">
                  <i class="fa fa-search m-r-5"></i>Search
                </button>

                <button @click="copyShareableLink" class="btn btn-info"
                        data-bs-toggle="popover"
                        data-bs-placement="left"
                        data-bs-title="Information"
                        data-bs-content="Click this button to generate a full URL, including your input fields (order UUID and search date range), making it easy to share with others.">
                  <i class="fa fa-share"></i> Share This Search
                </button>

              </div>
            </div>
            <!-- INPUT GROUP 2 END -->
          </div>
        </div>
        <!-- CARD 1 END -->


        <!-- CARD 2 START -->
        <div class="card">
          <div class="card-body">

            <div id="alertDiv1" v-if="showAlertOrderUuid" class="alert alert-secondary outline" role="alert">
              <p>Please enter a valid order uuid with at least 24 characters. </p>
            </div>
            <div id="alertDiv2" v-if="showAlertTimestamps" class="alert alert-secondary outline" role="alert">
              <p>Please Enter a Valid Search Date Range: Both 'From' and 'To' dates are required and cannot be left
                empty. Additionally, the time span between the 'From' and 'To' dates must not exceed 6 months. </p>
            </div>

            <div class="text-center" id="order-details-search-result-tabs">
              <ul class="nav nav-tabs search-list" id="top-tab" role="tablist">
                <li class="nav-item" v-on:click="changetab('apiGatewayHttpRequests')">
                  <a class="nav-link " :class="{'show active': showtab === 'apiGatewayHttpRequests' }" id="top-all"
                     data-bs-toggle="popover"
                     data-bs-placement="bottom"
                     data-bs-title="What is provided in this tab?"
                     data-bs-content="Displays requests made from a partner or client to ryd's API Gateway (tt4.thinxcloud.de), along with response status codes and request times."
                     role="tab" aria-controls="apiGatewayHttpRequests" aria-selected="false"><i class="icon-target"></i>1.
                    API Gateway HTTP Requests</a>
                </li>
                <li class="nav-item" v-on:click="changetab('orderStateHistory')">
                  <a class="nav-link" :class="{'show active': showtab === 'orderStateHistory' }" id="top-images"
                     data-bs-toggle="popover"
                     data-bs-placement="bottom"
                     data-bs-title="What is provided in this tab?"
                     data-bs-content="Provides a chronological record of the state history for the specified Order UUID."
                     role="tab" aria-controls="orderStateHistory" aria-selected="false"><i class="fa fa-history"></i>2.
                    Order State History </a>
                </li>
                <li class="nav-item" v-on:click="changetab('popEvents')">
                  <a class="nav-link" :class="{'show active': showtab === 'popEvents' }" id="top-videos"
                     data-bs-toggle="popover"
                     data-bs-placement="bottom"
                     data-bs-title="What is provided in this tab?"
                     data-bs-content="Displays log messages generated during interactions between ryd and the gas station's cashier system (POP, Point Of Purchase)."
                     role="tab" aria-controls="popEvents" aria-selected="false"><i
                      class="fa fa-exchange"></i>3. Pop Events</a>
                </li>
                <li class="nav-item" v-on:click="changetab('errorLogMessages')">
                  <a class="nav-link" :class="{'show active': showtab === 'errorLogMessages' }" id="audios-link"
                     data-bs-toggle="popover"
                     data-bs-placement="bottom"
                     data-bs-title="What is provided in this tab?"
                     data-bs-content="Displays a list of log messages categorized as Exception or Error."
                     role="tab" aria-controls="errorLogMessages" aria-selected="false"><i class="icon-close"> </i>4.
                    Error Log Messages</a>
                </li>
                <li class="nav-item" v-on:click="changetab('userAgents')" v-if="loggedInUserIsRyd">
                  <a class="nav-link" :class="{'show active': showtab === 'userAgents' }" id="audios-link"
                     data-bs-toggle="popover"
                     data-bs-placement="bottom"
                     data-bs-title="What is provided in this tab?"
                     data-bs-content="Displays a list of all the user agents utilized during the processing of the specified Order UUID."
                     role="tab" aria-controls="userAgents" aria-selected="false"><i class="icon-direction"> </i>5. User Agents</a>
                </li>
              </ul>
            </div>

            <div class="tab-content" id="top-tabContent">

              <TabApiGatewayHttpRequests :api-response-api-gateway-http-requests="apiResponseApiGatewayHttpRequests"
                                         :showtab="showtab"
                                         :isLoadingApiGatewayHttpRequests="isLoadingApiGatewayHttpRequests"
                                         :loggedInUserIsRyd="loggedInUserIsRyd"/>

              <TabApiStateEvents :api-response-api-order-state-events="apiResponseApiOrderStateEvents"
                                 :showtab="showtab"
                                 :isLoadingApiOrderStateEvents="isLoadingApiOrderStateEvents"
                                 :loggedInUserIsRyd="loggedInUserIsRyd"/>

              <TabPopEvents :api-response-pop-events="apiResponsePopEvents"
                            :showtab="showtab"
                            :isLoadingPopEvents="isLoadingPopEvents" :loggedInUserIsRyd="loggedInUserIsRyd"/>

              <TabErrorLogMessages :api-response-error-log-messages="apiResponseErrorLogMessages"
                                   :showtab="showtab"
                                   :isLoadingErrorLogMessages="isLoadingErrorLogMessages"
                                   :loggedInUserIsRyd="loggedInUserIsRyd"/>

              <TabUserAgents :apiResponseUserAgents="apiResponseUserAgents"
                             :showtab="showtab"
                             :isLoadingUserAgents="isLoadingUserAgents"
                             :loggedInUserIsRyd="loggedInUserIsRyd"/>

            </div>
          </div>
        </div>
        <!-- CARD 2 END -->


        <!-- card 3 start-->
        <div class="card card-absolute m-t-15">
          <div class="card-header bg-primary">
            <h5 class="text-white">Important Notes</h5>
          </div>

          <div class="card-body">
            <ul class="list-group">
              <li class="list-group-item">1. Please enter a valid order UUID.</li>
              <li class="list-group-item">2. The search feature on this page is designed to function with order UUIDs
                from both <b>staging and production</b> environments.
              </li>
              <li class="list-group-item">3. Ensure you specify a valid search date range where
                the interval between the 'From' and 'To' dates <b>does not exceed 6 months</b>, to maintain optimal
                search
                performance.
              </li>
            </ul>
          </div>
        </div>
        <!-- card 3 end-->

      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import TabApiGatewayHttpRequests from "@/pages/troubleshooting/orderdetails/TabApiGatewayHttpRequests.vue";
import TabApiStateEvents from "@/pages/troubleshooting/orderdetails/TabApiStateEvents.vue";
import TabPopEvents from "@/pages/troubleshooting/orderdetails/TabPopEvents.vue";
import TabErrorLogMessages from "@/pages/troubleshooting/orderdetails/TabErrorLogMessages.vue";
import TabUserAgents from "@/pages/troubleshooting/orderdetails/TabUserAgents.vue";
import {getUserAddDataFromLocalStorage} from "@/utils/userUtils";
import {PARTNER_ANALYTICS_API_KEY, PARTNER_ANALYTICS_SERVER_URL} from "@/constants/constants";
import {Popover} from 'bootstrap/dist/js/bootstrap.esm.min';

export default {
  components: {
    TabErrorLogMessages,
    TabPopEvents,
    TabApiStateEvents,
    TabApiGatewayHttpRequests,
    TabUserAgents
  },
  data() {
    return {
      showtab: 'apiGatewayHttpRequests',
      orderUuid: '',
      apiResponseApiGatewayHttpRequests: {
        totalNumberOfResults: 0,
        messages: [],
        graylogQueryUrl: '',
        errorMessage: null
      },
      apiResponseApiOrderStateEvents: {
        totalNumberOfResults: 0,
        messages: [],
        graylogQueryUrl: '',
        errorMessage: null
      },
      apiResponsePopEvents: {
        totalNumberOfResults: 0,
        messages: [],
        graylogQueryUrl: '',
        errorMessage: null
      },
      apiResponseErrorLogMessages: {
        totalNumberOfResults: 0,
        messages: [],
        graylogQueryUrl: '',
        errorMessage: null
      },
      apiResponseUserAgents: {
        totalNumberOfResults: 0,
        userAgents: [],
        graylogQueryUrl: '',
        errorMessage: null
      },
      showAlertOrderUuid: false,
      showAlertTimestamps: false,
      showShareThisSearch: false,
      fromTimestamp: '',
      toTimestamp: '',
      loggedInUserId: '',
      isLoadingApiGatewayHttpRequests: false,
      isLoadingApiOrderStateEvents: false,
      isLoadingPopEvents: false,
      isLoadingErrorLogMessages: false,
      isLoadingUserAgents: false,
      loggedInUserIsRyd: false,
    }
  },
  mounted() {
    const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]');

    const popoverList = [...popoverTriggerList].map(popoverTriggerEl =>
        new Popover(popoverTriggerEl, {
          trigger: 'focus hover'
        })
    );
  },
  created() {
    const {uid, isUserGroupRyd} = getUserAddDataFromLocalStorage();
    this.loggedInUserId = uid;
    this.loggedInUserIsRyd = isUserGroupRyd;
    this.initializeParameters();
    this.checkAndTriggerSearch();
  },
  methods: {
    changetab(tab) {
      this.showtab = tab;
    },
    async updateTabs() {

      this.apiResponseApiGatewayHttpRequests = {};
      this.apiResponseApiOrderStateEvents = {};
      this.apiResponsePopEvents = {};
      this.apiResponseErrorLogMessages = {};
      this.apiResponseUserAgents = {};

      // Check for valid Order UUID
      if (!this.orderUuid || this.orderUuid.trim().length < 23) {
        this.showAlertOrderUuid = true;
        this.showAlertTimestamps = false;
        return; // Early return if orderUuid is invalid
      }

      // Check for valid timestamps
      if (!this.validateTimestamps()) {
        this.showAlertOrderUuid = false;
        this.showAlertTimestamps = true;
        return; // Early return if timestamps are invalid
      }

      this.showtab = 'apiGatewayHttpRequests';
      this.showAlertOrderUuid = false;
      this.showAlertTimestamps = false;

      const orderUuidTrimmed = this.orderUuid.trim();
      try {
        await Promise.all([
          this.fetchApiGatewayHttpRequests(orderUuidTrimmed, this.fromTimestamp, this.toTimestamp),
          this.fetchOrderStateEvents(orderUuidTrimmed, this.fromTimestamp, this.toTimestamp),
          this.fetchPopEvents(orderUuidTrimmed, this.fromTimestamp, this.toTimestamp),
          this.fetchErrorLogMessages(orderUuidTrimmed, this.fromTimestamp, this.toTimestamp),
          this.fetchUserAgents(orderUuidTrimmed, this.fromTimestamp, this.toTimestamp)
        ]);
      } catch (error) {
        console.error("An error occurred while fetching data:", error);
        // Handle the error appropriately
      }
    },
    validateInput() {
      // Simple validation: check if the input is not empty
      return this.orderUuid && this.orderUuid.trim().length > 23;
    },
    async fetchApiGatewayHttpRequests(orderUuid, fromTimestamp, toTimestamp) {
      this.isLoadingApiGatewayHttpRequests = true;
      this.apiResponseApiGatewayHttpRequests.errorMessage = null;
      try {
        const response = await axios.get(`${PARTNER_ANALYTICS_SERVER_URL}/orders/api-gateway-http-requests`, {
          params: {
            from: this.convertDateToISOString(fromTimestamp),
            to: this.add1DayAndConvertDateToISOString(toTimestamp),
            orderUuid: orderUuid,
          },
          headers: {
            'x-txn-auth-token': PARTNER_ANALYTICS_API_KEY,
            'uid': this.loggedInUserId,
          }
        });

        let httpResponseStatus = response.status;
        //console.log("httpResponseStatus11", httpResponseStatus);
        if (httpResponseStatus > 399) {
          this.apiResponseApiGatewayHttpRequests = {};
          this.apiResponseApiGatewayHttpRequests.errorMessage = "No HTTP Request was found.";
        } else {
          this.apiResponseApiGatewayHttpRequests = response.data;
        }

        if (this.apiResponseApiGatewayHttpRequests.messages && this.apiResponseApiGatewayHttpRequests.messages.length === 0) {
          this.apiResponseApiGatewayHttpRequests.errorMessage = "No HTTP Request was found.";
        }

      } catch (error) {
        this.apiResponseApiGatewayHttpRequests = {};
        this.apiResponseApiGatewayHttpRequests.errorMessage = error.response?.data?.error;
        console.error('Error fetching API Gateway HTTP Requests:', error);
      } finally {
        this.isLoadingApiGatewayHttpRequests = false;
      }
    },
    async fetchOrderStateEvents(orderUuid, fromTimestamp, toTimestamp) {
      this.isLoadingApiOrderStateEvents = true;
      this.apiResponseApiOrderStateEvents = {};
      this.apiResponseApiOrderStateEvents.errorMessage = null;
      try {
        const response = await axios.get(`${PARTNER_ANALYTICS_SERVER_URL}/orders/order-status-events`, {
          params: {
            from: this.convertDateToISOString(fromTimestamp),
            to: this.add1DayAndConvertDateToISOString(toTimestamp),
            orderUuid: orderUuid,
          },
          headers: {
            'x-txn-auth-token': PARTNER_ANALYTICS_API_KEY,
            'uid': this.loggedInUserId,
          }
        });

        let httpResponseStatus = response.status;
        if (httpResponseStatus > 399) {
          this.apiResponseApiOrderStateEvents = {};
          this.apiResponseApiOrderStateEvents.errorMessage = "No order event was found.";
        } else {
          this.apiResponseApiOrderStateEvents = response.data;
        }

        if (this.apiResponseApiOrderStateEvents.messages && this.apiResponseApiOrderStateEvents.messages.length === 0) {
          this.apiResponseApiOrderStateEvents.errorMessage = "No order event was found.";
        }
      } catch (error) {
        this.apiResponseApiOrderStateEvents = {};
        this.apiResponseApiOrderStateEvents.errorMessage = error.response?.data?.error;
        console.error('Error fetching order state events:', error);
      } finally {
        this.isLoadingApiOrderStateEvents = false
      }
    },
    async fetchPopEvents(orderUuid, fromTimestamp, toTimestamp) {
      this.isLoadingPopEvents = true;
      this.apiResponsePopEvents.errorMessage = null;
      try {
        const response = await axios.get(`${PARTNER_ANALYTICS_SERVER_URL}/orders/pop-events`, {
          params: {
            from: this.convertDateToISOString(fromTimestamp),
            to: this.add1DayAndConvertDateToISOString(toTimestamp),
            orderUuid: orderUuid,
          },
          headers: {
            'x-txn-auth-token': PARTNER_ANALYTICS_API_KEY,
            'uid': this.loggedInUserId,
          }
        });

        let httpResponseStatus = response.status;
        if (httpResponseStatus > 399) {
          this.apiResponsePopEvents = {};
          this.apiResponsePopEvents.errorMessage = "No pop event was found.";
        } else {
          this.apiResponsePopEvents = response.data;
        }

        if (this.apiResponsePopEvents.messages && this.apiResponsePopEvents.messages.length === 0) {
          this.apiResponsePopEvents.errorMessage = "No pop event was found.";
        }
      } catch (error) {
        this.apiResponsePopEvents = {};
        this.apiResponsePopEvents.errorMessage = error.response?.data?.error;
        console.error('Error fetching pop events:', error);
      } finally {
        this.isLoadingPopEvents = false;
      }
    },
    async fetchErrorLogMessages(orderUuid, fromTimestamp, toTimestamp) {
      this.isLoadingErrorLogMessages = true;
      this.apiResponseErrorLogMessages.errorMessage = null;
      try {
        const response = await axios.get(`${PARTNER_ANALYTICS_SERVER_URL}/orders/error-log-messages`, {
          params: {
            from: this.convertDateToISOString(fromTimestamp),
            to: this.add1DayAndConvertDateToISOString(toTimestamp),
            orderUuid: orderUuid,
          },
          headers: {
            'x-txn-auth-token': PARTNER_ANALYTICS_API_KEY,
            'uid': this.loggedInUserId,
          }
        });

        let httpResponseStatus = response.status;
        if (httpResponseStatus > 399) {
          this.apiResponseErrorLogMessages = {};
          this.apiResponseErrorLogMessages.errorMessage = "No log message with the warning or error level was found.";
        } else {
          this.apiResponseErrorLogMessages = response.data;
        }

        if (this.apiResponseErrorLogMessages.messages && this.apiResponseErrorLogMessages.messages.length === 0) {
          this.apiResponseErrorLogMessages.errorMessage = "No log message with the warning or error level was found.";
        }
      } catch (error) {
        this.apiResponseErrorLogMessages = {};
        //console.error('Error fetching error messages:', error.response?.data?.error);
        this.apiResponseErrorLogMessages.errorMessage = error.response?.data?.error;
      } finally {
        this.isLoadingErrorLogMessages = false;
      }
    },
    async fetchUserAgents(orderUuid, fromTimestamp, toTimestamp) {
      this.isLoadingUserAgents = true;
      this.apiResponseUserAgents.errorMessage = null;
      try {
        const response = await axios.get(`${PARTNER_ANALYTICS_SERVER_URL}/user-agents`, {
          params: {
            from: this.convertDateToISOString(fromTimestamp),
            to: this.add1DayAndConvertDateToISOString(toTimestamp),
            orderIdOrAccountId: orderUuid,
          },
          headers: {
            'x-txn-auth-token': PARTNER_ANALYTICS_API_KEY,
            'uid': this.loggedInUserId,
          }
        });

        let httpResponseStatus = response.status;
        if (httpResponseStatus > 399) {
          this.apiResponseUserAgents = {};
          this.apiResponseUserAgents.errorMessage = "No user agent for the entered order id was found..";
        } else {
          this.apiResponseUserAgents = response.data;
        }

        if (this.apiResponseUserAgents.messages && this.apiResponseUserAgents.totalNumberOfResults === 0) {
          this.apiResponseUserAgents.errorMessage = "No user agent for the entered order id was found.";
        }
      } catch (error) {
        this.apiResponseUserAgents = {};
        //console.error('Error fetching error messages:', error.response?.data?.error);
        this.apiResponseUserAgents.errorMessage = error.response?.data?.error;
      } finally {
        this.isLoadingUserAgents = false;
      }
    },
    validateTimestamps() {
      if (!this.fromTimestamp || !this.toTimestamp) {
        this.showAlertTimestamps = true;
        return false;
      }

      const from = new Date(this.fromTimestamp);
      const to = new Date(this.toTimestamp);

      if (to < from) {
        this.showAlertTimestamps = true;
        return false;
      }

      const sixMonths = 6 * 30 * 24 * 60 * 60 * 1000; // Approximately 6 months in milliseconds
      if (to - from > sixMonths) {
        this.showAlertTimestamps = true;
        return false;
      }

      this.showAlertTimestamps = false;
      return true;
    },
    convertTimestampToISO(timestamp) {
      return new Date(timestamp).toISOString();
    },
    convertDateToISOString(date) {
      return new Date(date).toISOString();
    },
    add1DayAndConvertDateToISOString(date) {
      if (date) {
        let dateInputValue = new Date(date);
        dateInputValue.setDate(dateInputValue.getDate() + 1); // Adding 1 day
        return dateInputValue.toISOString();
      }
    },
    initializeParameters() {
      const queryParams = this.$route.query;
      this.fromTimestamp = queryParams.from || this.getDefaultFromDate();
      this.toTimestamp = queryParams.to || this.getDefaultToDate();
      this.orderUuid = queryParams.orderUuid || '';

      // Format dates to 'YYYY-MM-DD' if they are not empty
      this.fromTimestamp = this.fromTimestamp ? this.formatDate(this.fromTimestamp) : '';
      this.toTimestamp = this.toTimestamp ? this.formatDate(this.toTimestamp) : '';
    },
    getDefaultFromDate() {
      const now = new Date();
      now.setDate(now.getDate() - 5);
      return this.formatDate(now.toISOString());
    },
    getDefaultToDate() {
      const now = new Date();
      now.setDate(now.getDate() + 1);
      return this.formatDate(now.toISOString());
    },
    formatDate(dateString) {
      return dateString.split('T')[0]; // Returns the date part in 'YYYY-MM-DD' format
    },
    checkAndTriggerSearch() {
      const queryParams = this.$route.query;
      if (queryParams.from && queryParams.to && queryParams.orderUuid) {
        this.showShareThisSearch = true;
        this.updateTabs();
      }
    },
    copyShareableLink() {
      const baseUrl = window.location.origin + window.location.pathname;
      const queryParams = new URLSearchParams({
        from: this.fromTimestamp || '',
        to: this.toTimestamp || '',
        orderUuid: this.orderUuid?.trim() || ''
      }).toString();

      const shareableLink = `${baseUrl}?${queryParams}`;

      navigator.clipboard.writeText(shareableLink)
          .then(() => {
            this.$toast.show('The Order UUID and Date Range have been successfully copied to the clipboard as a complete link.', { theme: 'outline',position: 'top', type: 'success', duration: 5000 });
          })
          .catch(err => {
            this.$toast.show('Failed to copy the ', { theme: 'outline',position: 'top', type: 'danger', duration: 8000 });
          });
    },
  }
}
</script>

<style scoped>
.table-striped > tbody > tr:nth-child(odd) > td {
  background-color: #f2f2f2; /* Light gray for odd rows */
}

.table-striped > tbody > tr:nth-child(even) > td {
  background-color: #ffffff; /* White for even rows */
}

#order-details-search.ryd-search-input {
  border-radius: 0.375rem;;
}

#order-details-search-result-tabs .nav-item {
  border-right: 1px solid var(--theme-deafult);
}

#order-details-search-result-tabs .nav-item:last-child {
  border-right: none;
}


</style>
