<template>
  <div class="col-sm-12 col-xl-6 box-col-6">
    <div class="card">
      <div class="card-header pb-0">
        <h3>Basic Area Chart 2</h3>
      </div>
      <div class="card-body">
        <div id="basic-apex">
          <apexchart type="area" height="350" ref="chart" :options="chartOptions" :series="series"></apexchart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

var primary = localStorage.getItem('primary_color') || '#24695c';
const totalRequests = [1000, 2000, 1500, 1800, 1600];
const failedRequests = [50, 24, 29, 70, 78];

export default {
  data() {
    return {
      series: [{
        name: "Error Rate",
        data: [5.04, 0.12, 1.92, 3.91, 4.87],
      },],
      chartOptions: {
        chart: {
          height: 350,
          type: 'area',
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        title: {
          text: 'HTTP Requests From Partner To Ryd API Gateway',
          align: 'left'
        },
        subtitle: {
          text: 'Error Rate',
          align: 'left'
        },
        labels: ['2023-12-12', '2023-12-13', '2023-12-14', '2023-12-15', '2023-12-16'],
        xaxis: {
          type: 'datetime',
        },
        yaxis: {
          opposite: true,
          labels: {
            formatter: function (value, index) {
              return `${value}%`;
            }
          },
          legend: {
            horizontalAlign: 'left'
          },
          colors: [primary]
        },
        tooltip: {
          enabled: true,
          x: {
            show: true,
            format: 'dd MMM',
            formatter: undefined,
          },
          y: {
            formatter: function (value, {seriesIndex, dataPointIndex, w}) {
              const total = totalRequests[dataPointIndex];
              const failed = failedRequests[dataPointIndex];
              return `${value}% (Total: ${total}, Failed: ${failed})`;
            }
          },
          marker: {
            show: true,
          },
        }
      }
    }
  }
}
</script>
