<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">

        <!-- card start-->
        <div class="card">
          <div class="card-body">

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i> Introduction to Order Insights and
              Analytics</h5>
            <ul class="list-group">
              <li class="list-group-item">Welcome to the Data Insights section of ryd partner portal.<br/>
                Here, you can access a wealth of information about orders and their states, presented through
                interactive and informative tabs.<br/>
                Each tab is designed to give you specific insights into different aspects of order data.
              </li>
            </ul>
            <br/>

            <h5><i class="icofont icofont-hand-right font-primary">&nbsp;</i>Tabs Overview</h5>
            <ul class="list-group">
              <li class="list-group-item p-t-15">
                <a href="/dashboard/order-insights-analytics#order-volume-analysis">
                  <h6 class="d-inline-block align-middle">
                    <i class="icofont icofont-hand-drawn-right font-success align-middle"></i> Order Volume Analysis</h6>
                </a><br/>
                <span>
                  Discover trends in order creation with data from the last 2 hours and the last 48 hours.<br/>
                  This tab offers a quick snapshot of recent order activities, helping you gauge short-term changes and patterns in order volume.
                </span>
              </li>
              <li class="list-group-item p-t-15">
                <a href="/dashboard/order-insights-analytics#order-creation-trends">
                  <h6 class="d-inline-block align-middle">
                    <i class="icofont icofont-hand-drawn-right font-success align-middle"></i>Order Creation Trends</h6>
                </a><br/>
                <span>
                Dive into a detailed histogram showcasing the number of orders created over time. You can customize the histogram interval (day or hour) and select specific time ranges for analysis.
                  <br/>This tab is ideal for understanding longer-term trends and identifying peak order times.
                </span>
              </li>
              <li class="list-group-item p-t-15">
                <a href="/dashboard/order-insights-analytics#order-failure-insights">
                  <h6 class="d-inline-block align-middle">
                    <i class="icofont icofont-hand-drawn-right font-success align-middle"></i>Order Failure Insights</h6>
                </a><br/>
                <span>
                Analyze aggregated data on order failures, presented in an easy-to-read table format. Filter the data by time range and examine failures grouped by gas station brands.
                 <br/> This tab is important for identifying areas for operational improvement and understanding gas station-specific issues.
                </span>
              </li>
              <li class="list-group-item p-t-15">
                <a href="/dashboard/order-insights-analytics#state-error-analysis">
                  <h6 class="d-inline-block align-middle">
                    <i class="icofont icofont-hand-drawn-right font-success align-middle"></i>Order State & Error
                    Analysis</h6>
                </a><br/>
                <span>
                Explore two distinct histograms in this tab: One detailing the distribution and frequency of various order states, and the other tracking the error rate of orders over time.
                  <br/>These histograms are key to understanding the overall health of order processes and identifying recurring problems.
                </span>
              </li>


            </ul>
            <br/>


          </div>
        </div>
        <!-- card end-->


      </div>
    </div>
  </div>
</template>
<script setup>
</script>