<template>
  <Breadcrumbs main="Integration Guide" title="ryd.pay APIs"/>

  <div class="container-fluid">
    <div class="col-sm-12 col-md-12 d-flex justify-content-center">
      <select v-model="selectedApi" @change="loadSwaggerUI" class="m-r-20 form-control border border-primary min-width-max-content">
        <option value="staging">Staging Environment (Upcoming Changes)</option>
        <option value="production">Production Environment (Current Live Version)</option>
      </select>
      <button  class="btn btn-pill btn-success btn-air-success active input-group-text" @click="downloadJson">Download API Spec in json according to openAPI 3.0.0</button>
    </div>
    <div class="swagger" id="swagger"></div>
  </div>

</template>


<script>
import swaggerui from 'swagger-ui';
//import 'swagger-ui/dist/swagger-ui.css';

const apiOrigin = 'https://tt4.thinxcloud-staging.de';

export default {
  name: "swagger",
  data() {
    return {
      selectedApi: 'production',  // default selection
    }
  },
  mounted() {
    this.addNoReferrerMeta();

    // Set selectedApi based on URL fragment
    const hash = window.location.hash.replace('#/', ''); // Removing both '#' and leading '/'
    if (hash === 'production' || hash === 'staging') {
      this.selectedApi = hash;
    }

    this.loadSwaggerUI();
  },
  watch: {
    '$route' (to, from) {
      if (to.hash) {
        this.selectedApi = to.hash.replace('#/', '');
        //console.log("111", to.hash.replace('#/', ''));
        //this.selectedApi = 'staging';
        this.loadSwaggerUI();
      }
    }
  },
  methods: {
    downloadJson() {
      // Determine the file to download based on the selected API
      const fileName = this.selectedApi === 'test' ? 'staging-api-spec.json' : 'tt4-20240415.json';
      const downloadName = this.selectedApi === 'test' ? 'staging-api-spec-rydpay.json' : 'production-api-spec-rydpay.json';

      // Dynamically import the JSON file
      import(`./${fileName}`).then((data) => {
        const jsonStr = JSON.stringify(data, null, 2); // Stringify the JSON data
        const blob = new Blob([jsonStr], {type: 'application/json'});
        const url = URL.createObjectURL(blob);
x
        // Create a link and trigger the download
        const link = document.createElement('a');
        link.href = url;
        link.download = downloadName; // Use the dynamic download name
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      });
    },
    addNoReferrerMeta() {
      let meta = document.createElement('meta');
      meta.name = "referrer";
      meta.content = "no-referrer";
      document.getElementsByTagName('head')[0].appendChild(meta);
    },
    loadSwaggerUI() {
      const spec = this.selectedApi === 'staging'
          ? require('./staging-api-spec.json')  // Replace with your test API spec file
          : require('./tt4-20240415.json');  // Replace with your production API spec file

      // Update the URL hash only if it's different from the current selection
      if (window.location.hash.replace('#/', '') !== this.selectedApi) {
        window.location.hash = this.selectedApi;
      }

      swaggerui({
        spec: spec,
        dom_id: '#swagger',
        displayRequestDuration: true,
        deepLinking: true,
        persistAuthorization: true,
        showMutatedRequest: true,
        requestInterceptor: (req) => {
          req.cors = true;
          req.proxy = apiOrigin;

          if (req.method === "OPTIONS") {
            req.headers["Access-Control-Request-Headers"] = "x-txn-auth-token";
          }

          req.headers['x-txn-auth-token'] = "token";

          return req;
        }
      });
    },
  },
}
</script>

<!-- define the css styling for darkmode here, not in an external scss or css file. -->
<style>
.dark-only .swagger-ui .model,
.dark-only .swagger-ui section.models span,
.dark-only .swagger-ui .info .title,
.dark-only .swagger-ui .info p,
.dark-only .swagger-ui .info li,
.dark-only .swagger-ui .opblock-tag.no-desc span {
  color: rgba(255, 255, 255, 0.6);
}
</style>
<style scoped>
@import "swagger-ui-themes/themes/3.x/theme-material.css";
</style>
  