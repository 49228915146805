import {createRouter, createWebHistory} from "vue-router";
import Body from '../components/body';

/* Dashboards */
import Default from '../pages/dashboard/default';
import Ecommerce from '../pages/dashboard/ecommerce';
import Online from '../pages/dashboard/online'
import Crypto from '../pages/dashboard/crypto'
import Social from '../pages/dashboard/social.vue'
// Widgets
import General from '../pages/widgets/general'
import Chart from '../pages/widgets/chart'
// pageLayout
import indexBoxed from '../pages/pageLayout/boxed/indexBoxed.vue'
import indexRTL from '../pages/pageLayout/RTL/indexRTL.vue'
import indexDarklayout from '../pages/pageLayout/darklayout/indexDarklayout.vue'
import indexFooterdark from '../pages/pageLayout/footerdark/indexFooterdark.vue'
import indexFooterfixed from '../pages/pageLayout/footerfixed/indexFooterfixed.vue'
import indexFooterlight from '../pages/pageLayout/footerlight/indexFooterlight.vue'
import indexHidenavscroll from '../pages/pageLayout/hidenavscroll/indexHidenavscroll.vue'
// project
import createProject from '../pages/project/createproject/createProject.vue'
import indexList from '../pages/project/projectlist/indexList.vue'
/* File Manager  */
import FileManager from '../pages/filemanager';
// kanban
import Kanban from '../pages/kanban/indexKanban.vue'
// ecoomerce
import indexProduct from '../pages/ecommerce/product/indexProduct.vue'
import productPage from '../pages/ecommerce/productpage/productPage.vue'
import productList from '../pages/ecommerce/list/productList.vue'
import paymentDetails from '../pages/ecommerce/payment/details/paymentDetails.vue'
import orderHistory from '../pages/ecommerce/order/orderHistory.vue'
import invoiceView from '../pages/ecommerce/invoice/invoiceView.vue'
import cartView from '../pages/ecommerce/cart/cartView.vue'
import wishlistView from '../pages/ecommerce/wishlist/wishlistView.vue'
import checkoutView from '../pages/ecommerce/checkout/checkoutView.vue'
import pricingView from '../pages/ecommerce/pricing/pricingView.vue'
// mail
import readMail from '../pages/email/readMail/readMail.vue'
import composeView from '../pages/email/compose/composeView.vue'
// chat
import chatApp from '../pages/chat/chatApp/chatApp.vue'
import videoChat from '../pages/chat/videoChat/videoChat.vue'
// user
import userProfile from '../pages/users/profile/userProfile.vue'
import userEdit from '../pages/users/edit/userEdit.vue'
import userCreate from '../pages/users/create/userCreate.vue'
import userList from '../pages/users/lists/userList.vue'

import PartnerAdministrationCreate from '../pages/partnerAdministration/create/partnerAdministrationCreate.vue'
import PartnerAdministrationList from '../pages/partnerAdministration/list/partnerAdministrationList.vue'


// forms
import formValidation from "../pages/forms/formValidetion/formValidation"
import base_Input from "../pages/forms/baseInput/base_Input"
import checkbox_radio from "../pages/forms/Checkbox&Radio/checkbox_radio"
import input_groups from "../pages/forms/InputGroup/input_groups"
import megaOptions from "../pages/forms/megaOptions/megaOptions"

// form widgets
import select2 from "../pages/formWidgets/select2/select2Page.vue"
import switch_From from "../pages/formWidgets/switch/switch_From"
import touchspin_Form from "../pages/formWidgets/touchspin/touchspin_Form"
import typeahead_Form from "../pages/formWidgets/typeahead/typeahead_Form"
import clipboard_Form from "../pages/formWidgets/clipboard/clipboard_Form"
import datepicker from "../pages/formWidgets/datepicker/datePicker.vue"

// form layput
import defaultforms from '../pages/formLayout/default/defaultFotrm.vue'
import form_wizard2 from "../pages/formLayout/formWizrd2/form_wizard.vue"
import form_wizard1 from '../pages/formLayout/formWizard1/form_wizard2.vue'
import form_wizard3 from "../pages/formLayout/formWizard3/form_wizard1.vue"

// uikits
import Scrollable from "../pages/advance/scollable/scrollable_advance.vue";
import Sweetalert from "../pages/advance/sweetalert/sweetAert.vue";
import Tree from "../pages/advance/tree/treeView.vue";
import Rating from "../pages/advance/rating/ratingView.vue";
import Pagination from "../pages/advance/pagination/pagination_advance.vue";
import Ribbons from "../pages/advance/ribbons/ribbons_advance.vue";
import Tour from "../pages/advance/tour/tourView.vue";

import Breadcrumb from "../pages/advance/breadcrumbs/breadCrumb.vue";

import Sticky from "../pages/advance/sticky/stickyView.vue";

import ImageCropper from "../pages/advance/image_cropper/cropper_advance.vue";
import Notify from '../pages/advance/notify/toaster_advance.vue'
import dropzone from '../pages/advance/dropzone/dropzoneView.vue';
import animatedModal from '../pages/advance/animatedModal/animatedModal.vue'
import owlCarousel from '../pages/advance/owlCarousel/owlCarousel.vue'
import Rangeslider from '../pages/advance/range/rangeView.vue'
import BasicCard from '../pages/advance/basicCard/basicCard.vue'
import CreativeCard from '../pages/advance/creativeCard/creative_card.vue'
import DraggableCard from '../pages/advance/draggableCard/draggableCard.vue'
import TabbedCard from '../pages/advance/tabbedCard/tabbed_card.vue'
import timeLine from '../pages/advance/timeLine/timeLine.vue'
/* FAQ */
import Faq from '../pages/faq/faqIndex.vue';
// uikits
import alert from '../pages/uikits/alert';
import Helperclasses from '../pages/uikits/helper_classes';
import Avatars from '../pages/uikits/avatars';
import Grid from '../pages/uikits/grid';
import Tagpills from '../pages/uikits/tag_pills';
import Typography from '../pages/uikits/typography';
import Progressbar from '../pages/uikits/progress_bar';
import Modal from '../pages/uikits/modal';
import Popover from '../pages/uikits/popover';
import Tooltip from '../pages/uikits/tooltip';
import Spinners from '../pages/uikits/loader';
import Dropdown from '../pages/uikits/dropdown';
import Accordion from '../pages/uikits/accordion';
import Boxshadow from '../pages/uikits/box_shadow';
import Lists from '../pages/uikits/lists';
import bootstraptabPage from "../pages/uikits/tabs/bootstraptab/bootstraptabPage.vue";
import linetabPage from "../pages/uikits/tabs/linetab/linetabPage.vue";
// animation
import animate from '../pages/animation/animate/animatePage.vue'
import aos from '../pages/animation/aos/aosPage.vue'
import scroll from '../pages/animation/scroll/scrollPage.vue'
import wow from '../pages/animation/wow/wowPage.vue'
import tilt from '../pages/animation/tilt.vue'
// builder
import form1 from '../pages/builder/formBuilder1.vue'
import form2 from '../pages/builder/formBuilder2.vue'
/* icons */
import Flag from '../pages/icons/flag';
import Ico from '../pages/icons/ico_icon';
import Themify from '../pages/icons/themify_icon';
import Fontawesome from '../pages/icons/font_awesome';
import FeatherIcon from '../pages/icons/feather_icon';
import WhetherIcon from '../pages/icons/weather';
/* Buttons */
import Default_buttons from '../pages/button/Default/default_button.vue';
import Flat_buttons from '../pages/button/Flat/flat_button.vue';
import Edge_buttons from '../pages/button/Edge/edge_button.vue';
import Raised_buttons from '../pages/button/Raised/raised_button.vue';
import Group_buttons from '../pages/button/ButtonGroup/button_group.vue';
/*charts*/
import google_chart from "../pages/charts/googleChart/google_chart"
import apex_chart from "../pages/charts/ApexChart/apex_chart"
import chartist_chart from "../pages/charts/chartist/chartist_chart.vue"
// knowledge
import knowledge from '../pages/Knowledgebase/index.vue'
import category_knowledge from '../pages/Knowledgebase/categoryKnowledge.vue'
import detail_knowledge from '../pages/Knowledgebase/detailsKnowledge.vue'
// error
import Error400 from '../pages/error/error400';
import Error401 from '../pages/error/error401';
import Error403 from '../pages/error/error403';
import Error404 from '../pages/error/error404';
import Error500 from '../pages/error/error500';
import Error503 from '../pages/error/error503';

/* Support Ticket */
import Support from '../pages/support/support.vue';

/* Editor */
import SimpleEditor from '../pages/editor/simple_editor';
import CkEditor from '../pages/editor/ckeditor.vue';
/* Maps */
import GoogleMaps from '../pages/maps/google_map.vue';
import VueLeaflet from '../pages/maps/vue_leaflet.vue';
// learning
import learning_list from "../pages/learning/learninglist/learning_list"
import coursedetailed from "../pages/learning/courseDetailed"
// job Search
import job_list from "../pages/job/job-list/job_list"
import job_details from "../pages/job/job_details/job_details"
import job_apply from "../pages/job/job_apply/job_apply"
import job_card from "../pages/job/job_card/cardJob.vue"
// blog
import blog_detail from "../pages/blog/blog-details/blog_detail"
import blog_single from "../pages/blog/blog-single/blog_single"
import blog_add from "../pages/blog/blog-add/addBlog.vue"
// GALLERY
import grid_gallery from "../pages/gallery/grid_gallery"
import grid_desc from "../pages/gallery/grid_desc"
import hover_gallery from "../pages/gallery/hover-gallery/hover_gallery"
import masonry_gallery from "../pages/gallery/masonry-gallery/masonry_gallery"
import masonary_desc from "../pages/gallery/masonary_desc.vue"
/* Authentication */
import LoginOne from '../pages/authentication/login_one';
import LoginTwo from '../pages/authentication/login_two';
import LoginValidate from '../pages/authentication/login_validation.vue';
import LoginTooltip from '../pages/authentication/login_tooltip.vue'
import LoginSweetalert from '../pages/authentication/login_sweetalert.vue'

import RegisterImage from '../pages/authentication/register_image';
import RegisterImage2 from '../pages/authentication/register_image2';
import Unlock from '../pages/authentication/unlock';
import ForgetPassword from '../pages/authentication/forget_password';
import ResetPassword from '../pages/authentication/reset_password';
import Maintenance from '../pages/authentication/maintenance.vue'
/* Auth */
import login from '../auth/login';
import Register from '../auth/register';
import SetPassword from '../auth/passwordSetup.vue';
// comingsoon
import ComingsoonImage from '../pages/comingsoon/comingsoon_image';
import ComingsoonSimple from '../pages/comingsoon/comingsoon_simple';
import ComingsoonVideo from '../pages/comingsoon/comingsoon_video';
// SAMPLE PAGE
import sample_page from "../pages/sample_page"
/* bootstrap tables */
import BootstrapBasic from "../pages/tables/bootstrapTable/basicTables/basic_tables.vue";
import tableComponent from "../pages/tables/bootstrapTable/stylingtable/styling_table.vue"
import DatatableBasic from "../pages/tables/dataTable/BasicInit/basic_Init.vue"
// searchPage
import serchIndex from "../pages/search/serchIndex.vue"
// bookmark
import Bookmark from "../pages/bookmark/bookMark.vue"
// contact
import contactView from "../pages/contact/contactView.vue"
// task
import Task from "../pages/task/indexPage.vue"
// calendar
import Calendar from "../pages/calenderView.vue"
// social app
import socialPage from "../pages/socialApp/socialApp.vue"
// todo
import Todo from "../pages/todo/index.vue"

// changelog
import PartnerPortalChangelog from "../pages/portalchangelog/PartnerPortalChangelog.vue";
import CommunityAndSupport from "../pages/communityandsupport/CommunityAndSupport.vue";
import AccountSettings from "../pages/accountsettings/AccountSettings.vue";


// integration guid
import DeeplinkRegistrationApiReference from "../pages/integrationguide/deeplinkingregistration/ApiReference.vue";
import DeeplinkRegistrationApiGettingStarted
    from "../pages/integrationguide/deeplinkingregistration/GettingStarted.vue";
import DeeplinkRegistrationChangelog from "../pages/integrationguide/deeplinkingregistration/Changelog.vue";
import DeeplinkRegistrationGuidesAndTutorials
    from "../pages/integrationguide/deeplinkingregistration/GuidesAndTutorials.vue";
import DeeplinkRegistrationFaqs from "../pages/integrationguide/deeplinkingregistration/Faqs.vue";


import RydPayApiGettingStarted from "../pages/integrationguide/rydpayapis/GettingStarted.vue";
import RydPayApiIntroduction from "../pages/integrationguide/rydpayapis/Introduction.vue";
import RydPayApiGuides from "../pages/integrationguide/rydpayapis/Guides.vue";
import RydPayApiTutorials from "../pages/integrationguide/rydpayapis/Tutorials.vue";
import RydPayApiFAQs from "../pages/integrationguide/rydpayapis/Faqs.vue";
import RydPayApiTermsAndDefinitions from "../pages/integrationguide/rydpayapis/TermsAndDefinitions.vue";
import RydPayApiReleaseNotesAndUpdates from "../pages/integrationguide/rydpayapis/ReleaseNotesAndUpdates.vue";
import RydPayApiTestingAndSimulation from "../pages/integrationguide/rydpayapis/TestingAndSimulation.vue";
import RydPayApiTroubleshootingAndSupport from "../pages/integrationguide/rydpayapis/TroubleshootingAndSupport.vue";
import RydPayApiTestScenarios from "../pages/integrationguide/rydpayapis/TestScenarios.vue";
import RydPayWebhooks from "../pages/integrationguide/rydpayapis/RydPayWebhooks.vue";


import TopupApiReference from "../pages/integrationguide/rydpayapis/ApiReference.vue";

// webapp integration
import WebappIntegrationGettingStarted from "../pages/integrationguide/webappIntegration/GettingStarted.vue";
import WebappIntegrationIntroduction from "../pages/integrationguide/webappIntegration/Introduction.vue";
import WebappIntegrationGuidesAndTutorials from "../pages/integrationguide/webappIntegration/GuidesAndTutorials.vue";
import WebappIntegrationFAQs from "../pages/integrationguide/webappIntegration/Faqs.vue";
import WebappIntegrationTermsAndDefinitions from "../pages/integrationguide/webappIntegration/TermsAndDefinitions.vue";
import WebappIntegrationTesting from "../pages/integrationguide/webappIntegration/Testing.vue";
import WebappIntegrationTroubleshootingAndSupport from "../pages/integrationguide/webappIntegration/TroubleshootingAndSupport.vue";
import WebappIntegrationChangelog from "../pages/integrationguide/webappIntegration/Changelog.vue";
import WebappIntegrationTopupFlowSteps from "../pages/integrationguide/webappIntegration/TopupFlowSteps.vue";

import IntegrationOverview from "../pages/integrationguide/integrationOverview/IntegrationOverview.vue";

// troubeshooting
import TroubeshootingOrderDetails from "../pages/troubleshooting/orderdetails/OrderDetails.vue";
import AccountDetails from "../pages/troubleshooting/accountDetails/AccountDetails.vue";


import DashboardBasic from "../pages/dashboard/basic/Basic.vue";
import DashboardRequestStats from "@/pages/dashboard/apiRequestsAnalytics/ApiRequestsAnalytics.vue";
import PpTokenUsage from "../pages/troubleshooting/ppTokenUsage/PpTokenUserage.vue";
import MissingVoiceApiCalls from "../pages/troubleshooting/missingVoiceApiCalls/MissingVoiceApiCalls.vue";
import OrderStats from "@/pages/dashboard/orderInsightsAnalytics/OrderInsightsAnalytics.vue";

// platform status
import PlatformStatusCurrentStatus from "../pages/platformStatus/currentStatus/PlatformStatusCurrentStatus.vue";
import PlatformStatusPastIncidents from "../pages/platformStatus/pastIncidents/PlatformStatusPastIncidents.vue";
import PlatformStatusScheduledMaintenance
    from "../pages/platformStatus/scheduledMaintenance/PlatformStatusScheduledMaintenance.vue";
import PlatformStatusSystemMetrics from "../pages/platformStatus/systemMetrics/PlatformStatusSystemMetrics.vue";

// Guardian System and Alerts
import GuardianChecks from "../pages/mocPartnerGuardian/guardianChecks/GuardianChecks.vue";
import CashierSystemConfigurationsList from "../pages/mocPartnerGuardian/cashierSystemConfigurations/CashierSystemConfigurationsList.vue";
import CashierSystemConfigurationsCreation from "../pages/mocPartnerGuardian/cashierSystemConfigurations/CashierSystemConfigurationsCreation.vue";
import CashierSystemConfigurationsView from "../pages/mocPartnerGuardian/cashierSystemConfigurations/CashierSystemConfigurationsView.vue";

import MocPartnerConfigurationsCreation from "../pages/mocPartnerGuardian/mocPartnerConfigurations/MocPartnerConfigurationsCreation.vue";


// accounting
import PartnerExchangeFilesQ8 from "../pages/accouting/partnerexchangefiles/PartnerExchangeFilesQ8.vue";


const routes = [
    {
        path: '/',
        component: Body,

        children: [
            {
                path: '',
                name: 'defaultRoot',
                component: Default,
                meta: {
                    title: ' ryd Partner Portal',
                }
            },

        ]
    },
    {
        path: '/dashboard',
        component: Body,
        children: [
            {
                path: 'welcome',
                name: 'welcome',
                component: Default,
                meta: {
                    title: ' Welcome | ryd Partner Portal',
                }
            },
            {
                path: 'general-dashboard',
                name: 'generalDashboard',
                component: DashboardBasic,
                meta: {
                    title: 'General Dashboard | ryd Partner Portal',
                }
            },
            {
                path: 'dashboard-request-stats',
                name: 'dashboardRequestStats',
                component: DashboardRequestStats,
                meta: {
                    title: ' Dashboard Request Stats | ryd Partner Portal',
                }
            },
            {
                path: 'api-requests-analytics',
                name: 'aPIRequestsAnalytics',
                component: DashboardRequestStats,
                meta: {
                    title: ' API Requests Analytics | ryd Partner Portal',
                }
            },
            {
                path: 'order-insights-analytics',
                name: 'orderInsightsAnalytics',
                component: OrderStats,
                meta: {
                    title: ' Order Insights & Analytics | ryd Partner Portal',
                }
            },
            {
                path: 'ecommerce',
                name: 'ecommerce',
                component: Ecommerce,
                meta: {
                    title: 'Ecommerce Dashboard | ryd Partner Portal',
                }
            },
            {
                path: 'online',
                name: 'online',
                component: Online,
                meta: {
                    title: 'Online Dashboard | ryd Partner Portal',
                }
            },
            {
                path: 'crypto',
                name: 'crypto',
                component: Crypto,
                meta: {
                    title: 'Crypto Dashboard | ryd Partner Portal',
                }
            },
            {
                path: 'social',
                name: 'social',
                component: Social,
                meta: {
                    title: 'Social Dashboard | ryd Partner Portal',
                }
            }
        ]
    },
    {
        path: '/troubleshooting',
        component: Body,
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: 'order-details',
                name: 'order-details',
                component: TroubeshootingOrderDetails,
                meta: {
                    title: ' Troubleshooting Order Details | ryd Partner Portal',
                }
            },
            {
                path: 'token-usage',
                name: 'token-usage',
                component: PpTokenUsage,
                meta: {
                    title: ' Troubleshooting Token Usage | ryd Partner Portal',
                    requiredUserGroup: ["ryd", "parkopedia"]
                }
            },
            {
                path: 'missing-invoice-api-calls',
                name: 'missing-invoice-api-calls',
                component: MissingVoiceApiCalls,
                meta: {
                    title: ' Troubleshooting Missing Invoice API Calls | ryd Partner Portal',
                    requiredUserGroup: ["ryd", "parkopedia"]
                }
            },
            {
                path: 'account-activities',
                name: 'account-activities',
                component: AccountDetails,
                meta: {
                    title: ' Troubleshooting Account Activities | ryd Partner Portal',
                }
            },
        ]
    },
    {
        path: '/widgets',
        component: Body,
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: 'general',
                name: 'general',
                component: General,
                meta: {
                    title: 'general Dashboard | ryd Partner Portal',
                }
            },
            {
                path: 'chart',
                name: 'chart',
                component: Chart,
                meta: {
                    title: 'chart Dashboard | ryd Partner Portal',
                }
            },
        ]
    },
    {
        path: '/pageLayout',
        component: Body,
        children: [
            {
                path: 'boxed',
                name: 'boxed',
                component: indexBoxed,
                meta: {
                    title: 'Boxed | ryd Partner Portal',
                }
            },
            {
                path: 'darklayout',
                name: 'darklayout',
                component: indexDarklayout,
                meta: {
                    title: 'Dark Layout | ryd Partner Portal',
                }
            }
            ,
            {
                path: 'footerdark',
                name: 'footerdark',
                component: indexFooterdark,
                meta: {
                    title: 'Footer Dark | ryd Partner Portal',
                }
            }
            ,
            {
                path: 'footerlight',
                name: 'footerlight',
                component: indexFooterlight,
                meta: {
                    title: 'Footer Light | ryd Partner Portal',
                }
            }
            ,
            {
                path: 'footerfixed',
                name: 'footerfixed',
                component: indexFooterfixed,
                meta: {
                    title: 'Footer Fixed | ryd Partner Portal',
                }
            }
            ,
            {
                path: 'hidenavscroll',
                name: 'hidenavscroll',
                component: indexHidenavscroll,
                meta: {
                    title: 'Hide Nav Scroll | ryd Partner Portal',
                }
            }
            ,
            {
                path: 'RTL',
                name: 'RTL',
                component: indexRTL,
                meta: {
                    title: 'RTL | ryd Partner Portal',
                }
            }
        ]
    },
    {
        path: '/project',
        component: Body,
        children: [
            {
                path: 'projectlist',
                name: 'projectlist',
                component: indexList,
                meta: {
                    title: 'Project list | ryd Partner Portal',
                }
            },
            {
                path: 'create-project',
                name: 'createProject',
                component: createProject,
                meta: {
                    title: 'Create new | ryd Partner Portal',
                }
            }
        ]
    },
    {
        path: '/app',
        component: Body,
        children: [
            {
                path: 'socialPage',
                name: 'socialPage',
                component: socialPage,
                meta: {
                    title: 'Social App, | ryd Partner Portal',
                }
            },
            {
                path: 'todo',
                name: 'todo',
                component: Todo,
                meta: {
                    title: 'Todo | ryd Partner Portal',
                }
            },

            {
                path: 'chat',
                name: 'chat',
                component: chatApp,
                meta: {
                    title: 'Chat | ryd Partner Portal',
                }
            },
            {
                path: 'videochat',
                name: 'Video chat',
                component: videoChat,
                meta: {
                    title: 'Video Chat | ryd Partner Portal',
                }
            },

            {
                path: 'filemanager',
                name: 'File Manager',
                component: FileManager,
                meta: {
                    title: 'File Manager, | ryd Partner Portal',
                }
            },
            {
                path: 'kanban',
                name: 'Kanban Board',
                component: Kanban,
                meta: {
                    title: 'Kanban Board, | ryd Partner Portal',
                }
            },
            {
                path: 'contact',
                component: contactView,
                name: 'contactView',
                meta: {
                    title: 'Contacts | ryd Partner Portal',
                }
            },
            {
                path: 'bookmark',
                name: 'Bookmark',
                component: Bookmark,
                meta: {
                    title: 'Bookmark | ryd Partner Portal',
                }
            },
            {
                path: 'task',
                name: 'Task',
                component: Task,
                meta: {
                    title: 'Task | ryd Partner Portal',
                }
            },
            {
                path: 'calendar',
                name: 'Calendar',
                component: Calendar,
                meta: {
                    title: 'Calendar | ryd Partner Portal',
                }
            }
        ]
    },
    {
        path: "/ecommerce",
        component: Body,
        children: [
            {
                path: "product",
                name: "Product",
                component: indexProduct,
                meta: {
                    title: "Products | ryd Partner Portal",
                },
            },
            {
                path: "product/page/:id",
                name: "ProductPage",
                component: productPage,
                meta: {
                    title: "Products Page | ryd Partner Portal",
                },
            },
            {
                path: "product/list",
                name: "productList",
                component: productList,
                meta: {
                    title: "Products list | ryd Partner Portal",
                },
            },
            {
                path: "payment/details",
                name: "paymentDetails",
                component: paymentDetails,
                meta: {
                    title: "Payment Details | ryd Partner Portal",
                },
            },
            {
                path: "order/history",
                name: "Order History",
                component: orderHistory,
                meta: {
                    title: "OrderHistory | ryd Partner Portal",
                },
            },
            {
                path: "invoice",
                name: "Invoice",
                component: invoiceView,
                meta: {
                    title: "Invoice | ryd Partner Portal",
                },
            },
            {
                path: "cart",
                name: "Cart",
                component: cartView,
                meta: {
                    title: "Cart | ryd Partner Portal",
                },
            },
            {
                path: "wishlist",
                name: "Wishlist",
                component: wishlistView,
                meta: {
                    title: "Wishlist | ryd Partner Portal",
                },
            },
            {
                path: "checkout",
                name: "Checkout",
                component: checkoutView,
                meta: {
                    title: "Checkout | ryd Partner Portal",
                },
            },
            {
                path: "pricing",
                name: "pricing",
                component: pricingView,
                meta: {
                    title: "pricingView | ryd Partner Portal",
                },
            },


        ],
    },
    {
        path: '/email',
        component: Body,
        children: [
            {
                path: "readMail",
                name: "readMail",
                component: readMail,
                meta: {
                    title: "Email App | ryd Partner Portal",
                },
            },
            {
                path: "compose",
                name: "compose",
                component: composeView,
                meta: {
                    title: "Email Compose | ryd Partner Portal",
                },
            }
        ]
    },
    {
        path: '/users',
        component: Body,
        children: [
            {
                path: "profile",
                name: "usersProfile",
                component: userProfile,
                meta: {
                    title: "Users Profile | ryd Partner Portal",
                },
            },
            {
                path: "edit",
                name: "usersEdit",
                component: userEdit,
                meta: {
                    title: "Users Edit | ryd Partner Portal",
                },
            },
            {
                path: "list",
                name: "usersList",
                component: userList,
                meta: {
                    title: "User List | ryd Partner Portal",
                },
            },
            {
                path: "create",
                name: "usersCreate",
                component: userCreate,
                meta: {
                    title: ' User Creation | ryd Partner Portal'
                },
            }
        ]
    },
    {
        path: '/partner-administration',
        component: Body,
        children: [
            {
                path: "list",
                name: "partnerList",
                component: PartnerAdministrationList,
                meta: {
                    title: "Partner Administration List | ryd Partner Portal",
                },
            },
            {
                path: "create",
                name: "partnerCreate",
                component: PartnerAdministrationCreate,
                meta: {
                    title: ' Partner Creation | ryd Partner Portal'
                },
            }
        ]
    },
    {
        path: "/form",
        component: Body,
        children: [
            {
                path: "validation",
                name: "formValidation",
                component: formValidation,
                meta: {
                    title: "Form Validation | ryd Partner Portal",
                },
            },
            {
                path: "inputs",
                name: "basicInput",
                component: base_Input,
                meta: {
                    title: "Base Inputs | ryd Partner Portal",
                },
            },
            {
                path: "checkbox-radio",
                name: "checkbox & radio",
                component: checkbox_radio,
                meta: {
                    title: "Checkbox & Radio | ryd Partner Portal",
                },
            },
            {
                path: "input-groups",
                name: "input Groups",
                component: input_groups,
                meta: {
                    title: "Input Groups | ryd Partner Portal",
                },
            },
            {
                path: "mega-options",
                name: "megaOptions",
                component: megaOptions,
                meta: {
                    title: "Mega Options | ryd Partner Portal",
                },
            },
            {
                path: "select2",
                name: "select2",
                component: select2,
                meta: {
                    title: "Select2 | ryd Partner Portal",
                },
            },
            {
                path: "switch",
                name: "switch",
                component: switch_From,
                meta: {
                    title: "Switch | ryd Partner Portal",
                },
            },
            {
                path: "touchspin",
                name: "touchspin",
                component: touchspin_Form,
                meta: {
                    title: "Touchspin | ryd Partner Portal",
                },
            },
            {
                path: "typeahead",
                name: "typeahead",
                component: typeahead_Form,
                meta: {
                    title: "Typeahead | ryd Partner Portal",
                },
            },
            {
                path: "clipboard",
                name: "clipboard",
                component: clipboard_Form,
                meta: {
                    title: "Clipboard | ryd Partner Portal",
                },
            },
            {
                path: "wizard2",
                name: "form_wizard2",
                component: form_wizard2,
                meta: {
                    title: "Form Wizard2 | ryd Partner Portal",
                },
            },
            {
                path: "wizard1",
                name: "formwizard",
                component: form_wizard1,
                meta: {
                    title: "Form Wizard1 | ryd Partner Portal",
                },
            },
            {
                path: "wizard3",
                name: "formwizard3",
                component: form_wizard3,
                meta: {
                    title: "Form Wizard3 | ryd Partner Portal",
                },
            },
            {
                path: "defaultforms",
                name: "defaultforms",
                component: defaultforms,
                meta: {
                    title: "Default Forms | ryd Partner Portal",
                },
            },
            {
                path: "datepicker",
                name: "datepicker",
                component: datepicker,
                meta: {
                    title: "Datepicker | ryd Partner Portal",
                },
            },

        ]
    },
    {
        path: "/advance",
        component: Body,
        children: [
            {
                path: "scrollable",
                name: "Scrollable",
                component: Scrollable,
                meta: {
                    title: "Scrollable | ryd Partner Portal",
                },
            },
            {
                path: "treeView",
                name: "Tree",
                component: Tree,
                meta: {
                    title: "Tree | ryd Partner Portal",
                },
            },
            {
                path: "notify",
                name: "notify",
                component: Notify,
                meta: {
                    title: "Scrollable | ryd Partner Portal",
                },
            },
            {
                path: "rating",
                name: "Rating",
                component: Rating,
                meta: {
                    title: "Rating | ryd Partner Portal",
                },
            },
            {
                path: "dropzone",
                name: "dropzone",
                component: dropzone,
                meta: {
                    title: "dropzone | ryd Partner Portal",
                },
            },
            {
                path: "tour",
                name: "Tour",
                component: Tour,
                meta: {
                    title: "Tour | ryd Partner Portal",
                },
            },
            {
                path: "sweetalert",
                name: "Sweetalert",
                component: Sweetalert,
                meta: {
                    title: "Sweetalert | ryd Partner Portal",
                },
            },
            {
                path: "animatedModal",
                name: "animatedModal",
                component: animatedModal,
                meta: {
                    title: "Sweetalert | ryd Partner Portal",
                },
            },
            {
                path: "owlCarousel",
                name: "owlCarousel",
                component: owlCarousel,
                meta: {
                    title: "Sweetalert | ryd Partner Portal",
                },
            },

            {
                path: "ribbons",
                name: "Ribbons",
                component: Ribbons,
                meta: {
                    title: "Ribbons | ryd Partner Portal",
                },
            },
            {
                path: "pagination",
                name: "Pagination",
                component: Pagination,
                meta: {
                    title: "Pagination | ryd Partner Portal",
                },
            },
            {
                path: "breadcrumb",
                name: "Breadcrumb",
                component: Breadcrumb,
                meta: {
                    title: "Breadcrumb | ryd Partner Portal",
                },
            },
            {
                path: "range-slider",
                name: "Rangeslider",
                component: Rangeslider,
                meta: {
                    title: "Rangeslider | ryd Partner Portal",
                },
            },
            {
                path: "image-cropper",
                name: "ImageCropper",
                component: ImageCropper,
                meta: {
                    title: "ImageCropper | ryd Partner Portal",
                },
            },

            {
                path: "sticky",
                name: "Sticky",
                component: Sticky,
                meta: {
                    title: "Sticky | ryd Partner Portal",
                },
            },
            {
                path: "basicCard",
                name: "basicCard",
                component: BasicCard,
                meta: {
                    title: "Sticky | ryd Partner Portal",
                },
            },
            {
                path: "creativeCard",
                name: "creativeCard",
                component: CreativeCard,
                meta: {
                    title: "Sticky | ryd Partner Portal",
                },
            },
            {
                path: "TabbedCard",
                name: "TabbedCard",
                component: TabbedCard,
                meta: {
                    title: "Sticky | ryd Partner Portal",
                },
            },
            {
                path: "DraggableCard",
                name: "DraggableCard",
                component: DraggableCard,
                meta: {
                    title: "Sticky | ryd Partner Portal",
                },
            },
            {
                path: "timeline1",
                name: "timeline",
                component: timeLine,
                meta: {
                    title: "Toastr | ryd Partner Portal",
                },
            },
        ],
    },
    {
        path: "/chart",
        component: Body,
        children: [
            {
                path: "google",
                name: "googlechart",
                component: google_chart,
                meta: {
                    title: "Google Chart | ryd Partner Portal",
                },
            },
            {
                path: "apexChart",
                name: "apexchart",
                component: apex_chart,
                meta: {
                    title: "ApexChart | ryd Partner Portal",
                },
            },
            {
                path: "chartist",
                name: "chartist",
                component: chartist_chart,
                meta: {
                    title: "Chartist | ryd Partner Portal",
                },
            },

        ]
    },
    {
        path: '/pages',
        component: Body,
        children: [
            {
                path: '/pages/faq',
                name: 'Faq',
                component: Faq,
                meta: {
                    title: 'Faq | ryd Partner Portal',
                }
            },
            {
                path: '/pages/support',
                name: 'Support',
                component: Support,
                meta: {
                    title: 'Support | ryd Partner Portal',
                }
            },
            {
                path: 'sample-page',
                name: 'sample-page',
                component: sample_page,
                meta: {
                    title: 'sample-page | ryd Partner Portal',
                }
            },
            {
                path: "search",
                name: "search",
                component: serchIndex
            },
            {
                path: '/pages/ryd-partner-portal-changelog',
                name: 'partnerPortalChangelog',
                component: PartnerPortalChangelog,
                meta: {
                    title: 'Partner Portal Changelog | ryd Partner Portal',
                }
            },
            {
                path: '/pages/community-and-feedback',
                name: 'communityAndFeedback',
                component: CommunityAndSupport,
                meta: {
                    title: 'Partner Portal Community and Feedback | ryd Partner Portal',
                }
            },
            {
                path: '/pages/account-settings',
                name: 'accountSettings',
                component: AccountSettings,
                meta: {
                    title: 'Partner Portal Account Settings | ryd Partner Portal',
                }
            },
        ]
    },
    {
        path: '/uikits',
        component: Body,
        children: [
            {
                path: 'alert',
                name: 'alert',
                component: alert,
                meta: {
                    title: 'Alert | ryd Partner Portal',
                }
            },
            {
                path: 'helper-classes',
                name: 'Helperclasses',
                component: Helperclasses,
                meta: {
                    title: 'Helperclasses | ryd Partner Portal',
                }
            },
            {
                path: 'avatars',
                name: 'Avatars',
                component: Avatars,
                meta: {
                    title: 'Avatars | ryd Partner Portal',
                }
            },
            {
                path: 'grid',
                name: 'Grid',
                component: Grid,
                meta: {
                    title: 'Grid | ryd Partner Portal',
                }
            },
            {
                path: 'tag-pills',
                name: 'Tagpills',
                component: Tagpills,
                meta: {
                    title: 'Tagpills | ryd Partner Portal',
                }
            },
            {
                path: 'typography',
                name: 'Typography',
                component: Typography,
                meta: {
                    title: 'Typography | ryd Partner Portal',
                }
            },
            {
                path: 'progress-bar',
                name: 'Progressbar',
                component: Progressbar,
                meta: {
                    title: 'Progressbar | ryd Partner Portal',
                }
            },
            {
                path: 'modal',
                name: 'Modal',
                component: Modal,
                meta: {
                    title: 'Modal | ryd Partner Portal',
                }
            },
            {
                path: 'popover',
                name: 'Popover',
                component: Popover,
                meta: {
                    title: 'Popover | ryd Partner Portal',
                }
            },
            {
                path: 'tooltip',
                name: 'Tooltip',
                component: Tooltip,
                meta: {
                    title: 'Tooltip | ryd Partner Portal',
                }
            },
            {
                path: 'loader',
                name: 'Spinners',
                component: Spinners,
                meta: {
                    title: 'Spinners | ryd Partner Portal',
                }
            },
            {
                path: 'dropdown',
                name: 'Dropdown',
                component: Dropdown,
                meta: {
                    title: 'Dropdown | ryd Partner Portal',
                }
            },
            {
                path: 'accordion',
                name: 'Accordion',
                component: Accordion,
                meta: {
                    title: 'Accordion | ryd Partner Portal',
                }
            },
            {
                path: "bootstraptab",
                name: "bootstraptabPage",
                component: bootstraptabPage,
                meta: {
                    title: "tab | ryd Partner Portal",
                },
            },
            {
                path: "linetab",
                name: "linetabPage",
                component: linetabPage,
                meta: {
                    title: "Accordion | ryd Partner Portal",
                },
            },
            {
                path: 'box-shadow',
                name: 'Boxshadow',
                component: Boxshadow,
                meta: {
                    title: 'Boxshadow | ryd Partner Portal',
                }
            },
            {
                path: 'lists',
                name: 'Lists',
                component: Lists,
                meta: {
                    title: 'Lists | ryd Partner Portal',
                }
            }
        ]
    },
    {
        path: "/animation",
        component: Body,
        children: [
            {
                path: "animate",
                name: "animate",
                component: animate,
                meta: {
                    title: "animate | ryd Partner Portal",
                },
            },
            {
                path: "scroll",
                name: "scroll",
                component: scroll,
                meta: {
                    title: "scroll | ryd Partner Portal",
                },
            },
            {
                path: "AOS",
                name: "aos",
                component: aos,
                meta: {
                    title: "aos | ryd Partner Portal",
                },
            },

            {
                path: "wow",
                name: "wow",
                component: wow,
                meta: {
                    title: "wow | ryd Partner Portal",
                },
            },
            {
                path: "tilt",
                name: "tilt",
                component: tilt,
                meta: {
                    title: "tilt | ryd Partner Portal",
                },
            },
        ],
    },
    {
        path: "/builder",
        component: Body,
        children: [
            {
                path: "form1",
                name: "Form Builder1",
                component: form1,
                meta: {
                    title: "from1 | ryd Partner Portal",
                },
            },
            {
                path: "form2",
                name: "Form Builder2",
                component: form2,
                meta: {
                    title: "from2 | ryd Partner Portal",
                },
            }
        ]
    },
    {
        path: '/icons',
        component: Body,
        children: [
            {
                path: 'flag',
                name: 'Flag',
                component: Flag,
                meta: {
                    title: 'Flag | ryd Partner Portal',
                }
            },
            {
                path: 'ico',
                name: 'Ico',
                component: Ico,
                meta: {
                    title: 'Ico | ryd Partner Portal',
                }
            },
            {
                path: 'themify',
                name: 'Themify',
                component: Themify,
                meta: {
                    title: 'Themify | ryd Partner Portal',
                }
            },
            {
                path: 'fontawesome',
                name: 'Fontawesome',
                component: Fontawesome,
                meta: {
                    title: 'Fontawesome | ryd Partner Portal',
                }
            },
            {
                path: 'feather_icon',
                name: 'FeatherIcon',
                component: FeatherIcon,
                meta: {
                    title: 'FeatherIcon | ryd Partner Portal',
                }
            },
            {
                path: 'whether',
                name: 'WhetherIcon',
                component: WhetherIcon,
                meta: {
                    title: 'WhetherIcon | ryd Partner Portal',
                }
            }
        ]
    },
    {
        path: '/buttons',
        component: Body,
        children: [
            {
                path: 'default',
                name: 'default_buttons',
                component: Default_buttons,
                meta: {
                    title: 'Default_buttons | ryd Partner Portal',
                }
            },
            {
                path: 'flat',
                name: 'flat_buttons',
                component: Flat_buttons,
                meta: {
                    title: 'Flat_buttons | ryd Partner Portal',
                }
            },
            {
                path: 'edge',
                name: 'edge_buttons',
                component: Edge_buttons,
                meta: {
                    title: 'Edge_buttons | ryd Partner Portal',
                }
            },
            {
                path: 'raised',
                name: 'raised_buttons',
                component: Raised_buttons,
                meta: {
                    title: 'Raised_buttons | ryd Partner Portal',
                }
            },
            {
                path: 'group',
                name: 'group_buttons',
                component: Group_buttons,
                meta: {
                    title: 'Group_buttons | ryd Partner Portal',
                }
            }
        ]
    },
    {
        path: '/knowledgebase',
        component: Body,
        children: [
            {
                path: "knowledgebase",
                name: 'knowledge',
                component: knowledge,
                meta: {
                    title: 'Knowledgebase | ryd Partner Portal',
                }
            },
            {
                path: 'category',
                name: 'category_knowledge',
                component: category_knowledge
            },
            {
                path: 'detail',
                name: 'detail_knowledge',
                component: detail_knowledge
            }
        ]
    },
    {
        path: '/error-400',
        name: 'Error400',
        component: Error400,
        meta: {
            title: 'Error400 | ryd Partner Portal',
        }
    },
    {
        path: '/error-401',
        name: 'Error401',
        component: Error401,
        meta: {
            title: 'Error401 | ryd Partner Portal',
        }
    },
    {
        path: '/error-403',
        name: 'Error403',
        component: Error403,
        meta: {
            title: 'Error403 | ryd Partner Portal',
        }
    },
    {
        path: '/error-404',
        name: 'Error404',
        component: Error404,
        meta: {
            title: 'Error404 | ryd Partner Portal',
        }
    },
    {
        path: '/error-500',
        name: 'Error500',
        component: Error500,
        meta: {
            title: 'Error500 | ryd Partner Portal',
        }
    },
    {
        path: '/error-503',
        name: 'Error503',
        component: Error503,
        meta: {
            title: 'Error503 | ryd Partner Portal',
        }
    },
    {
        path: '/authentication/login/one',
        name: 'LoginOne',
        component: LoginOne,
        meta: {
            title: 'LoginOne | ryd Partner Portal',
        }
    },
    {
        path: '/authentication/login/two',
        name: 'LoginTwo',
        component: LoginTwo,
        meta: {
            title: 'LoginTwo | ryd Partner Portal',
        }
    },
    {
        path: '/authentication/login/validate',
        name: 'LoginValidate',
        component: LoginValidate,
        meta: {
            title: 'LoginValidate | ryd Partner Portal',
        }
    },
    {
        path: '/authentication/login/tooltip',
        name: 'LoginTooltip',
        component: LoginTooltip,
        meta: {
            title: 'LoginTooltip | ryd Partner Portal',
        }
    },
    {
        path: '/authentication/login/sweetalert',
        name: 'LoginSweetalert',
        component: LoginSweetalert,
        meta: {
            title: 'LoginTooltip | ryd Partner Portal',
        }
    },
    {
        path: '/authentication/register/image',
        name: 'RegisterImage',
        component: RegisterImage,
        meta: {
            title: 'RegisterImage | ryd Partner Portal',
        }
    },
    {
        path: '/authentication/register/image2',
        name: 'RegisterImage2',
        component: RegisterImage2,
        meta: {
            title: 'RegisterImage2 | ryd Partner Portal',
        }
    },
    {
        path: '/authentication/unlockuser',
        name: 'Unlock',
        component: Unlock,
        meta: {
            title: 'Unlock | ryd Partner Portal',
        }
    },
    {
        path: '/authentication/forgetpassword',
        name: 'ForgetPassword',
        component: ForgetPassword,
        meta: {
            title: 'ForgetPassword | ryd Partner Portal',
        }
    },
    {
        path: '/authentication/resetpassword',
        name: 'ResetPassword',
        component: ResetPassword,
        meta: {
            title: 'ResetPassword | ryd Partner Portal',
        }
    },
    {
        path: '/authentication/maintenance',
        name: 'Maintenance',
        component: Maintenance,
        meta: {
            title: 'ResetPassword | ryd Partner Portal',
        }
    },
    {
        path: '/comingsoon/comingsoon-image',
        name: 'ComingsoonImage',
        component: ComingsoonImage,
        meta: {
            title: 'ComingsoonImage | ryd Partner Portal',
        }
    },
    {
        path: '/comingsoon/comingsoon-simple',
        name: 'ComingsoonSimple',
        component: ComingsoonSimple,
        meta: {
            title: 'ComingsoonSimple | ryd Partner Portal',
        }
    },
    {
        path: '/comingsoon/comingsoon-video',
        name: 'ComingsoonVideo',
        component: ComingsoonVideo,
        meta: {
            title: 'ComingsoonVideo | ryd Partner Portal',
        }
    },

    {
        path: '/editor',
        component: Body,
        children: [
            {
                path: 'simple-editor',
                name: 'SimpleEditor',
                component: SimpleEditor,
                meta: {
                    title: 'SimpleEditor | ryd Partner Portal',
                }
            },
            {
                path: 'ck-editor',
                name: 'CkEditor',
                component: CkEditor,
                meta: {
                    title: 'CkEditor | ryd Partner Portal',
                }

            }
        ]
    },
    {
        path: '/maps',
        component: Body,
        children: [
            {
                path: 'vue-google-maps',
                name: 'GoogleMaps',
                component: GoogleMaps,
                meta: {
                    title: 'oogleMaps | ryd Partner Portal',
                }
            },
            {
                path: 'vue-leaflet-maps',
                name: 'VueLeaflet',
                component: VueLeaflet,
                meta: {
                    title: 'ueLeaflet | ryd Partner Portal',
                }
            }
        ]
    },
    {
        path: "/learning",
        component: Body,
        children: [
            {
                path: "list",
                name: "learninglist",
                component: learning_list,
                meta: {
                    title: 'Learning List | ryd Partner Portal',
                }
            },
            {
                path: "details/:id",
                name: "coursedetailed",
                component: coursedetailed,
                meta: {
                    title: 'Detailed Course | ryd Partner Portal',
                }
            }
        ]
    },
    {
        path: "/job",
        component: Body,
        children: [
            {
                path: "card",
                name: "cardview",
                component: job_card,
                meta: {
                    title: 'Card View | ryd Partner Portal',
                }
            },
            {
                path: "list",
                name: "listview",
                component: job_list,
                meta: {
                    title: 'List View | ryd Partner Portal',
                }
            },
            {
                path: "/job/details/:id",
                name: "jobdetails",
                component: job_details,
                props: true,
                meta: {
                    title: 'Job Details | ryd Partner Portal',
                }
            },
            {
                path: "apply/:id",
                name: "jobapply",
                component: job_apply,
                meta: {
                    title: 'Apply | ryd Partner Portal',
                }
            }
        ]
    },
    {
        path: "/blog",
        component: Body,
        children: [
            {
                path: "details",
                name: "blog-detail",
                component: blog_detail,
                meta: {
                    title: 'Blog Details | ryd Partner Portal',
                }
            },
            {
                path: "single",
                name: "blog_single",
                component: blog_single,
                meta: {
                    title: 'Blog Single | ryd Partner Portal',
                }
            },
            {
                path: "add",
                name: "blog_add",
                component: blog_add,
                meta: {
                    title: 'Add Blog | ryd Partner Portal',
                }
            }

        ]
    },
    {
        path: "/gallery",
        component: Body,
        children: [
            {
                path: "grid-gallery",
                name: "gridgallery",
                component: grid_gallery,
                meta: {
                    title: 'Grid Gallery | ryd Partner Portal',
                }
            },
            {
                path: "gallery-desc",
                name: "griddesc",
                component: grid_desc,
                meta: {
                    title: 'Grid Gallery With Desc | ryd Partner Portal',
                }
            },
            {
                path: "hover-effect",
                name: "hovergallery",
                component: hover_gallery,
                meta: {
                    title: 'Hover Effect | ryd Partner Portal',
                }
            },
            {
                path: "gallery-masonary",
                name: "masonry-gallery",
                component: masonry_gallery,
                meta: {
                    title: 'Masonary Gallery | ryd Partner Portal',
                }
            },
            {
                path: "gallery-masonary-desc",
                name: "masonary-desc",
                component: masonary_desc,
                meta: {
                    title: 'Masonary Gallery Desc | ryd Partner Portal',
                }
            }

        ]
    },
    {
        path: '/auth',
        children: [
            {
                path: 'login',
                name: 'Login 1',
                component: login,
                meta: {
                    title: ' login | ryd Partner Portal',
                }
            },
            {
                path: 'register',
                name: 'register 1',
                component: Register,
                meta: {
                    title: ' Register | ryd Partner Portal',
                }
            },
            {
                path: 'password-setup',
                name: 'setPassword',
                component: SetPassword,
                meta: {
                    title: ' Set Password | ryd Partner Portal',
                }
            }
        ]
    },
    {
        path: "/table",
        component: Body,
        children: [
            {
                path: "basic",
                name: "basic1",
                component: BootstrapBasic,
                meta: {
                    title: ' Basic Tables | ryd Partner Portal',
                }
            },
            {
                path: "tableComponent",
                name: "tableComponent",
                component: tableComponent,
                meta: {
                    title: ' Table Components | ryd Partner Portal',
                }
            },

            {
                path: "/datatable-basic",
                name: "datatable",
                component: DatatableBasic,
                meta: {
                    title: ' Basic Init | ryd Partner Portal',
                }
            },

        ]
    },
    {
        path: "/integration-guide/deeplinking-registration",
        component: Body,
        children: [
            {
                path: "getting-started",
                name: "deeplinkingRegistrationGettingStarted",
                component: DeeplinkRegistrationApiGettingStarted,
                meta: {
                    title: ' Integration Guide: Deeplinking Registration Getting Started | ryd Partner Portal',
                }
            },
            {
                path: "api-reference",
                name: "deeplinkingRegistrationApiReference",
                component: DeeplinkRegistrationApiReference,
                meta: {
                    title: ' Integration Guide: Deeplinking Registration API Reference | ryd Partner Portal',
                }
            },
            {
                path: "changelog",
                name: "deeplinkingRegistrationChangelog",
                component: DeeplinkRegistrationChangelog,
                meta: {
                    title: ' Integration Guide: Deeplinking Registration API Changelog | ryd Partner Portal',
                }
            },
            {
                path: "guides-and-tutorials",
                name: "deeplinkRegistrationGuidesAndTutorials",
                component: DeeplinkRegistrationGuidesAndTutorials,
                meta: {
                    title: ' Integration Guide: Deeplinking Registration Guides and tutorias | ryd Partner Portal',
                }
            },
            {
                path: "faqs",
                name: "deeplinkRegistrationFaqs",
                component: DeeplinkRegistrationFaqs,
                meta: {
                    title: ' Integration Guide: Deeplinking Registration FAQs | ryd Partner Portal',
                }
            },
        ]
    },
    {
        path: "/integration-overview",
        component: Body,
        children: [
            {
                path: "",
                name: "IntegrationOverview",
                component: IntegrationOverview,
                meta: {
                    title: ' Integration Overview | ryd Partner Portal',
                }
            }
        ]
    },
    {
        path: "/integration-guide/webapp-integration",
        component: Body,
        children: [
            {
                path: "introduction",
                name: "WebappIntegrationIntroduction",
                component: WebappIntegrationIntroduction,
                meta: {
                    title: ' Integration Guide: webapp Integration Introduction | ryd Partner Portal',
                }
            },
            {
                path: "getting-started",
                name: "WebappIntegrationGettingStarted",
                component: WebappIntegrationGettingStarted,
                meta: {
                    title: ' Integration Guide: webapp Integration Getting Started | ryd Partner Portal',
                }
            },
            {
                path: "topup-flow-steps",
                name: "WebappIntegrationTopupFlowSteps",
                component: WebappIntegrationTopupFlowSteps,
                meta: {
                    title: ' Integration Guide: Topup Flow Steps | ryd Partner Portal',
                }
            },
            {
                path: "guides-and-tutorials",
                name: "WebappIntegrationGuidesAndTutorials",
                component: WebappIntegrationGuidesAndTutorials,
                meta: {
                    title: ' Integration Guide: webapp Integration Guides & Tutorials | ryd Partner Portal',
                }
            },
            {
                path: "faqs",
                name: "WebappIntegrationFAQs",
                component: WebappIntegrationFAQs,
                meta: {
                    title: ' Integration Guide: webapp Integration FAQs | ryd Partner Portal',
                }
            },
            {
                path: "terms-and-definitions",
                name: "WebappIntegrationTermsAndDefinitions",
                component: WebappIntegrationTermsAndDefinitions,
                meta: {
                    title: ' Integration Guide: webapp Integration Terms and Definitions | ryd Partner Portal',
                }
            },
            {
                path: "testing",
                name: "WebappIntegrationTesting",
                component: WebappIntegrationTesting,
                meta: {
                    title: ' Integration Guide: webapp Integration Testing | ryd Partner Portal',
                }
            },
            {
                path: "troubleshooting-and-support",
                name: "WebappIntegrationTroubleshootingAndSupport",
                component: WebappIntegrationTroubleshootingAndSupport,
                meta: {
                    title: ' Integration Guide: webapp Integration Troubleshooting and Support | ryd Partner Portal',
                }
            },
            {
                path: "changelog",
                name: "WebappIntegrationChangelog",
                component: WebappIntegrationChangelog,
                meta: {
                    title: ' Integration Guide: webapp Integration Changelog | ryd Partner Portal',
                }
            }
        ]
    },
    {
        path: "/integration-guide/rydpay-apis",
        component: Body,
        children: [
            {
                path: "introduction",
                name: "rydpayApiIntroduction",
                component: RydPayApiIntroduction,
                meta: {
                    title: ' Integration Guide: ryd.pay APIs Introduction | ryd Partner Portal',
                }
            },
            {
                path: "getting-started",
                name: "rydpayApiGettingStarted",
                component: RydPayApiGettingStarted,
                meta: {
                    title: ' Integration Guide: ryd.pay APIs Getting Started | ryd Partner Portal',
                }
            },
            {
                path: "guides",
                name: "rydpayApiGuides",
                component: RydPayApiGuides,
                meta: {
                    title: ' Integration Guide: ryd.pay APIs Guides | ryd Partner Portal',
                }
            },
            {
                path: "Tutorials",
                name: "rydpayApiTutorials",
                component: RydPayApiTutorials,
                meta: {
                    title: ' Integration Guide: ryd.pay APIs Tutorials | ryd Partner Portal',
                }
            },
            {
                path: "api-reference",
                name: "rydpayReference",
                component: TopupApiReference,
                meta: {
                    title: ' Integration Guide: ryd.pay APIs API Reference | ryd Partner Portal',
                }
            },
            {
                path: "faqs",
                name: "rydpayApiFaqs",
                component: RydPayApiFAQs,
                meta: {
                    title: ' Integration Guide: ryd.pay APIs FAQs | ryd Partner Portal',
                }
            },
            {
                path: "webhooks",
                name: "RydPayWebhooks",
                component: RydPayWebhooks,
                meta: {
                    title: ' Integration Guide: ryd.pay Webhooks | ryd Partner Portal',
                }
            },
            {
                path: "terms-and-definitions",
                name: "rydpayApiTermsAndDefinitions",
                component: RydPayApiTermsAndDefinitions,
                meta: {
                    title: ' Integration Guide: ryd.pay APIs Terms and Definitions | ryd Partner Portal',
                }
            },
            {
                path: "testing-and-simulation",
                name: "rydpayApiTestingAndSimulation",
                component: RydPayApiTestingAndSimulation,
                meta: {
                    title: ' Integration Guide: ryd.pay APIs Testing and Simulation | ryd Partner Portal',
                }
            },
            {
                path: "test-scenarios",
                name: "rydpayApiTestScenarios",
                component: RydPayApiTestScenarios,
                meta: {
                    title: ' Integration Guide: ryd.pay APIs Test Scenarios | ryd Partner Portal',
                }
            },
            {
                path: "troubleshooting-and-support",
                name: "rydpayApiTroubleshootingAndSupport",
                component: RydPayApiTroubleshootingAndSupport,
                meta: {
                    title: ' Integration Guide: ryd.pay APIs Troubleshooting and Support | ryd Partner Portal',
                }
            },
            {
                path: "release-notes-and-updates",
                name: "rydpayApiReleaseNotesAndUpdates",
                component: RydPayApiReleaseNotesAndUpdates,
                meta: {
                    title: ' Integration Guide: ryd.pay APIs Release Notes and Updates | ryd Partner Portal',
                }
            }
        ]
    },
    {
        path: '/platform-status',
        component: Body,
        children: [
            {
                path: "current-status",
                name: "platformStatusCurrentStatus",
                component: PlatformStatusCurrentStatus,
                meta: {
                    title: "Platform Status - Current Status | ryd Partner Portal",
                },
            },
            {
                path: "past-incidents",
                name: "platformStatusPastIncidents",
                component: PlatformStatusPastIncidents,
                meta: {
                    title: "Platform Status - Past Incidents | ryd Partner Portal",
                },
            },
            {
                path: "scheduled-maintenance",
                name: "platformStatusScheduledMaintenance",
                component: PlatformStatusScheduledMaintenance,
                meta: {
                    title: "Platform Status - Scheduled Maintenance | ryd Partner Portal",
                },
            },
            {
                path: "system-metrics",
                name: "platformStatusSystemMetrics",
                component: PlatformStatusSystemMetrics,
                meta: {
                    title: ' Platform Status - System Metrics | ryd Partner Portal'
                },
            }
        ]
    },
    {
        path: '/guardian-system-and-alerts',
        component: Body,
        children: [
            {
                path: "guardian-checks",
                name: "GuardianChecks",
                component: GuardianChecks,
                meta: {
                    title: ' Guardian System and Alerts - Guardian Checks | ryd Partner Portal'
                },
            },
            {
                path: "cashier-system-configuration/create",
                name: "Cashier System Configuration Create",
                component: CashierSystemConfigurationsCreation,
                meta: {
                    title: ' Guardian System and Alerts - Cashier System Configuration Create | ryd Partner Portal'
                },
            },
            {
                path: "cashier-system-configuration/view",
                name: "Cashier System Configuration View",
                component: CashierSystemConfigurationsView,
                meta: {
                    title: ' Guardian System and Alerts - Cashier System Configuration View | ryd Partner Portal'
                },
            },
            {
                path: "cashier-system-configuration/list",
                name: "Cashier System Configuration List",
                component: CashierSystemConfigurationsList,
                meta: {
                    title: ' Guardian System and Alerts - Cashier System Configuration List| ryd Partner Portal'
                },
            },
            {
                path: "moc-partner-configuration/create",
                name: "MOC Partner Configuration Create",
                component: MocPartnerConfigurationsCreation,
                meta: {
                    title: ' Guardian System and Alerts - MOC Partner Configuration Create | ryd Partner Portal'
                },
            },
        ]
    },
    {
        path: '/accounting',
        component: Body,
        children: [
            {
                path: "partner-file-hub/q8",
                name: "partnerExchangeFiles",
                component: PartnerExchangeFilesQ8,
                meta: {
                    title: "Accounting - Partner File Hub | ryd Partner Portal",
                },
            }
        ]
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/error-404'
    }
]
const router = createRouter({
    history: createWebHistory(),
    routes,
})
router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title;
    }


    const userAddDataAsStr = localStorage.getItem('userAddData');
    const pathesNoAuthentication = ['/auth/login', '/auth/register', '/auth/password-setup'];

    if (!pathesNoAuthentication.includes(to.path) && !userAddDataAsStr) {
        next({path: '/auth/login', query: {unauthorized: 'true'}});
    }

    if (pathesNoAuthentication.includes(to.path)) {
        return next();
    }


    const userAddData = userAddDataAsStr ? JSON.parse(userAddDataAsStr) : null;
    //console.log("userAddDataRouter", userAddData);


// Helper functions to check user's permissions
    const hasRequiredRoles = (route) => {
        const requiredRoles = route.meta.requiredRoles;
        return requiredRoles.every(role => userAddData.roles.includes(role));
    };

    const isInRequiredUserGroup = (route) => {
        const requiredUserGroup = route.meta.requiredUserGroup;
        return requiredUserGroup.includes(userAddData.userGroup);
    };

    // Check for required roles and user groups
    const requiresAuthCheck = to.matched.some(route => route.meta.requiredRoles || route.meta.requiredUserGroup);

    if (requiresAuthCheck) {
        const routeWithRequirements = to.matched.find(route => route.meta.requiredRoles || route.meta.requiredUserGroup);

        if (routeWithRequirements) {
            const roleCheckPassed = !routeWithRequirements.meta.requiredRoles || hasRequiredRoles(routeWithRequirements);
            const groupCheckPassed = !routeWithRequirements.meta.requiredUserGroup || isInRequiredUserGroup(routeWithRequirements);

            if (roleCheckPassed && groupCheckPassed) {
                next(); // User satisfies both role and group requirements
            } else {
                next({path: '/unauthorized', query: {reason: 'auth'}}); // User does not meet the required criteria
            }
        } else {
            next(); // No additional auth requirements found
        }
    } else {
        next(); // No specific roles or user group required, proceed
    }

});
export default router