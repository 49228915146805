<template>
  <div class="search-links tab-pane fade" :class="{'show active': showtab == 'availableOrders'}" id="available-orders"
       role="tabpanel" aria-labelledby="availableOrders">
    <div class="col-sm-12" v-if="!isLoadingApiAvailableOrderIds">
      <div v-if="apiResponseApiAvailableOrderIds && apiResponseApiAvailableOrderIds.length">
        <table class="table table-bordernone table-striped display" id="basic-1">
          <thead class="bg-primary text-white">
          <tr>
            <th class="bg-primary" scope="col">Order UUID</th>
            <th class="bg-primary" scope="col">Creation Time (UTC)</th>
            <th class="bg-primary" scope="col">Link to Order Details</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(orderIdInfo, index) in apiResponseApiAvailableOrderIds" :key="index">
            <td>{{ orderIdInfo.orderId }}</td>
            <td>{{ orderIdInfo.created_at }}</td>
            <td><a :href="createLink(orderIdInfo)" target="_blank">Link</a></td>
          </tr>
          </tbody>
        </table>
      </div>
      <div v-else-if="apiResponseApiAvailableOrderIds && apiResponseApiAvailableOrderIds.errorMessage">
        <div class="text-center fw-bold fs-6 font-danger">
          {{ this.apiResponseApiAvailableOrderIds.errorMessage }}</div>
      </div>
    </div>

    <div v-if="isLoadingApiAvailableOrderIds" class="loading-indicator">
      <h6 class="sub-title mb-0 text-center">Loading 3... Available orders created in the selected date range.</h6>
      <div class="loader-box">
        <div class="loader-14"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TabAvailableOrders',
  props: {
    apiResponseApiAvailableOrderIds: {},
    isLoadingApiAvailableOrderIds: {},
    showtab: {}
  },
  methods: {
    formatDate(dateStr, daysToAdd = 0) {
      const date = new Date(dateStr);
      date.setDate(date.getDate() + daysToAdd);
      return date.toISOString().split('T')[0]; // Format as 'YYYY-MM-DD'
    },

    createLink(order) {
      const from = this.formatDate(order.created_at);
      const to = this.formatDate(order.created_at, 2);
      return `order-details?orderUuid=${order.orderId}&from=${from}&to=${to}`;
    }
  }
}
</script>
<style scoped>
.table-striped > tbody > tr:nth-child(odd) > td {
  background-color: #f2f2f2; /* Light gray for odd rows */
}

.table-striped > tbody > tr:nth-child(even) > td {
  background-color: #ffffff; /* White for even rows */
}

</style>