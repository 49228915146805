<template>
  <Breadcrumbs main="rydpay APIs" title="FAQs"/>

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">


        <div class="row default-according style-1 faq-accordion" id="accordionoc">
          <div class="col-xl-10 col-lg-8">

            <!-- START -->
            <div class="card">
              <div class="class-header">
                <h5 class="mb-0">
                  <button type="button"
                          class="btn btn-link"
                          :class="{collapsed: openAccordion !== 'collapseicon12'}"
                          @click="toggleAccordion('collapseicon12')"
                          :aria-expanded="openAccordion === 'collapseicon12'">
                    <vue-feather type="help-circle"></vue-feather>
                    How are POIs retrieved?
                  </button>
                </h5>
              </div>
              <div id="collapseicon12" role="tabpanel"
                   :style="{ display: openAccordion === 'collapseicon12' ? 'block' : 'none' }" class="collapse">
                <div class="card-body">

                  <b>POIs (Point of Interest, e.g. petrol stations, car washes, shops) can be retrieved in different
                    ways.</b>


                  <ul class="list-group m-t-15">
                    <li class="list-group-item">
                      <i class="fa fa-arrow-circle-right font-primary"></i>The most common way is to retrieve POIs
                      within a certain radius of the user, using the user's coordinates, a radius (in meters) and
                      specifying only ryd-enabled stations be returned. .


                      <ul class="list-group">
                        <li class="list-group-item">
                          Sample Request: <code>{baseUrl}/v4/pois?lat=53.5774664&lon=13.26917512&radiusM=5000&isRydPay=true</code>
                        </li>
                      </ul>

                    </li>

                    <li class="list-group-item">
                      <i class="fa fa-arrow-circle-right font-primary"></i>Another way would be to retrieve all POIs for
                      a country (using the countryCode parameter) and store them on a local server for easier retrieval.
                      The maximum number of POIs in a single response is 500, so the pagination object in the response
                      can be used to page through results if they exceed 500, using the relative URL in the ”next”
                      parameter.

                      <ul class="list-group">
                        <li class="list-group-item">
                          Sample Request: <code>{baseUrl}/v4/pois/?limit=500&countryCode=de&isRydPay=true</code>
                        </li>
                        <li class="list-group-item">
                          Response extract: <br/>
                          <pre><code>
                          {
                            "pagination": {
                              "links": {
                                "self": "/v4/pois?limit=500&countryCode=de&isRydPay=true&offset=0",
                                "next": "/v4/pois?limit=500&countryCode=de&isRydPay=true&offset=500",
                                "prev": null,
                                "first": "/v4/pois?limit=500&countryCode=de&isRydPay=true&offset=0",
                                "last": "/v4/pois?limit=500&countryCode=de&isRydPay=true&offset=1000"
                              },
                              "count": 500,
                              "offset": 0,
                              "limit": 500,
                              "total": 1344
                            }
                          }
                          </code></pre>
                        </li>
                      </ul>


                    </li>


                    <li class="list-group-item">
                      <i class="fa fa-arrow-circle-right font-primary"></i>To get only stations with a certain product
                      group, the URL can be supplemented with the productGroups parameter:

                      <ul class="list-group">
                        <li class="list-group-item">
                          Sample Request: <code>{baseUrl}/v4/pois/?limit=500&countryCode=de&isRydPay=true&productGroups=WASH</code>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- END -->

            <!-- START -->
            <div class="card">
              <div class="class-header">
                <h5 class="mb-0">
                  <button type="button"
                          class="btn btn-link"
                          :class="{collapsed: openAccordion !== 'collapseicon13'}"
                          @click="toggleAccordion('collapseicon13')"
                          :aria-expanded="openAccordion === 'collapseicon13'">
                    <vue-feather type="help-circle"></vue-feather>
                    How does a customer know whether a station is in Prepay or Postpay mode?
                  </button>
                </h5>
              </div>
              <div id="collapseicon13" role="tabpanel"
                   :style="{ display: openAccordion === 'collapseicon13' ? 'block' : 'none' }" class="collapse">
                <div class="card-body">

                  Customers do not need to know if a station or pump is in a particular state. For Postpay fueling, the
                  customer can start fueling at any time, and they can use ryd to pay at any point in the journey
                  (before, during or after the fueling). For Prepay stations, the customer will have to start the
                  payment journey before fueling, as the pump will be locked. Customers will tend to know what sort of a
                  station it is, as a particular flow will be predominant in a country (e.g. Germany and Poland are
                  largely Postpay, whereas Spain and Portugal and largely Prepay).

                </div>
              </div>
            </div>
            <!-- END -->


            <!-- START -->
            <div class="card">
              <div class="class-header">
                <h5 class="mb-1">
                  <button type="button"
                          class="btn btn-link"
                          :class="{collapsed: openAccordion !== 'collapseicon14'}"
                          @click="toggleAccordion('collapseicon14')"
                          :aria-expanded="openAccordion === 'collapseicon14'">
                    <vue-feather type="help-circle"></vue-feather>
                    Can a station have both Prepay and Postpay pumps?
                  </button>
                </h5>
              </div>
              <div id="collapseicon14" role="tabpanel"
                   :style="{ display: openAccordion === 'collapseicon14' ? 'block' : 'none' }" class="collapse">
                <div class="card-body">
                  A station will only be in either Postpay or Prepay mode at any one time.
                  <br/>
                  However, some stations operate in Postpay mode during the day (when there is a station attendant on
                  site) and switch to Prepay during the night and at weekends.
                  <br/>
                  It is important to inspect the automaticPump attribute in a GET /pois/{poi-id} request.
                  <br/>
                  <b>automaticPump</b>: true, indicates that the station/pumps are operating in Prepay mode, and
                  /authorize and /acquire calls must be made to unlock the pump and set the maximum fueling amount.
                  <br/>
                  <b>automaticPump</b>: false, indicates that the pump is in Postpay mode.

                </div>
              </div>
            </div>
            <!-- END -->


            <!-- START -->
            <div class="card">
              <div class="class-header">
                <h5 class="mb-1">
                  <button type="button"
                          class="btn btn-link"
                          :class="{collapsed: openAccordion !== 'collapseicon15'}"
                          @click="toggleAccordion('collapseicon15')"
                          :aria-expanded="openAccordion === 'collapseicon15'">
                    <vue-feather type="help-circle"></vue-feather>
                    What if a pump is already in use?
                  </button>
                </h5>
              </div>
              <div id="collapseicon15" role="tabpanel"
                   :style="{ display: openAccordion === 'collapseicon15' ? 'block' : 'none' }" class="collapse">
                <div class="card-body">
                  If a pump is already in use, or is taken out of service for some reason by the station (e.g. for
                  maintenance), the <code>GET /pois/{poi-id}</code> will indicate a status in the response, indicating
                  if the
                  individual pump is either AVAILABLE or UNAVAILABLE. In the latter case, the user cannot start a
                  transaction, so the pump selection should indicate this, for instance by being grayed out.
                </div>
              </div>
            </div>
            <!-- END -->


            <!-- START -->
            <div class="card">
              <div class="class-header">
                <h5 class="mb-1">
                  <button type="button"
                          class="btn btn-link"
                          :class="{collapsed: openAccordion !== 'collapseicon16'}"
                          @click="toggleAccordion('collapseicon16')"
                          :aria-expanded="openAccordion === 'collapseicon16'">
                    <vue-feather type="help-circle"></vue-feather>
                    How to identify if a station can process mobile payments?
                  </button>
                </h5>
              </div>
              <div id="collapseicon16" role="tabpanel"
                   :style="{ display: openAccordion === 'collapseicon16' ? 'block' : 'none' }" class="collapse">
                <div class="card-body">
                  All ryd-enabled stations have <b>"fastLane"</b> set as a parameter within the features object of a
                  <code>GET /pois/{POI-ID}</code> response.
                  <br/>
                  Each station must also have "status": "ONLINE" to be able to make a mobile payment. If the features
                  object has “fastLane” set but is "status": "OFFLINE", this normally indicates that the station is
                  temporarily unavailable for mobile payments due to a technical issue.

                </div>
              </div>
            </div>
            <!-- END -->


            <!-- START -->
            <div class="card">
              <div class="class-header">
                <h5 class="mb-1">
                  <button type="button"
                          class="btn btn-link"
                          :class="{collapsed: openAccordion !== 'collapseicon17'}"
                          @click="toggleAccordion('collapseicon17')"
                          :aria-expanded="openAccordion === 'collapseicon17'">
                    <vue-feather type="help-circle"></vue-feather>
                    How are petrol station fuel prices provided?
                  </button>
                </h5>
              </div>
              <div id="collapseicon17" role="tabpanel"
                   :style="{ display: openAccordion === 'collapseicon17' ? 'block' : 'none' }" class="collapse">
                <div class="card-body">
                  There are various sources that are connected and feed prices into our system. On the one hand are some
                  regulatory sources (for example in Germany, Austria or Portugal), on the other hand through our direct
                  connection to petrol stations and cash register systems. Both the source and the time of update are
                  given in the data.

                  <br/> <br/>
                  For Germany, the legislator demands that public petrol stations must provide real-time prices for
                  specific fuel types to allow customers to make informed decisions. Each petrol station in Germany has
                  an MTSK-ID (“Markttransparenzstelle für Kraftstoffe”), which allows the real-time price to be publicly
                  available. In Austria, there is a similar approach, whereby a unique “econtrolId” (“Energie-Control
                  Austria”) is assigned to individual gas stations. The intention of the E-Control regulator is to
                  enable the prices of the most important suppliers to be compared with each other, and the savings
                  potential for the cheapest supplier to be provided. In Portugal, petrol stations report their prices
                  through the Direção-Geral de Energia e Geologia (DGEG).
                  <br/> <br/>
                  The ryd APIs provide available fuel prices in the /pois/{poi-id} responses, when available. Also in
                  the API response will be the corresponding MTSK-ID, E-Control-ID or DGEG-ID (when available). Before
                  these data points are used and shared, please inform us of the purpose and reason so that certain
                  regulatory constraints can be clarified. This is not intended as legal advice, for further information
                  on fuel prices from regulatory authorities, please see the following:

                  <ul class="list-group">
                    <li class="list-group-item">
                      MTSK (Germany): <code>https://www.bundeskartellamt.de/EN/Economicsectors/MineralOil/</code>
                    </li>
                    <li class="list-group-item">
                      E-Control (Austria): <code>https://www.e-control.at/en/home_de</code>
                    </li>
                    <li class="list-group-item">
                      DGEG (Portugal): <code>https://www.dgeg.gov.pt/</code>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- END -->


            <!-- START -->
            <div class="card">
              <div class="class-header">
                <h5 class="mb-1">
                  <button type="button"
                          class="btn btn-link"
                          :class="{collapsed: openAccordion !== 'collapseicon18'}"
                          @click="toggleAccordion('collapseicon18')"
                          :aria-expanded="openAccordion === 'collapseicon18'">
                    <vue-feather type="help-circle"></vue-feather>
                    Can an ongoing fueling process be canceled?
                  </button>
                </h5>
              </div>
              <div id="collapseicon18" role="tabpanel"
                   :style="{ display: openAccordion === 'collapseicon18' ? 'block' : 'none' }" class="collapse">
                <div class="card-body">
                  For Prepay flow the ongoing fueling can be canceled only by the customer hanging the nozzle back (also
                  called "zero filling"). If the nozzle is not taken by the customer, the transaction will automatically
                  expire after some time (usually about 90 seconds), the pre-authorization will be voided, and the money
                  will be returned to the customer’s account.

                </div>
              </div>
            </div>
            <!-- END -->

          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      // This will track the currently open accordion
      openAccordion: null,
    };
  },
  methods: {
    toggleAccordion(accordionId) {
      this.openAccordion = this.openAccordion === accordionId ? null : accordionId;
    },
  },
};
</script>
