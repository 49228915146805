


/**
 * Parses parameters from a URL hash.
 *
 * @param {string} hash - The URL hash string to parse.
 * @return {object} An object representing the parsed parameters.
 */
function getParamsFromHash(hash = '') {
    const params = {};
    if (hash.includes('?')) {
        const queryString = hash.split('?')[1];
        const queryParts = queryString.split('&');

        queryParts.forEach(part => {
            const [key, value] = part.split('=');
            params[key] = decodeURIComponent(value);
        });
    }
    return params;
}

export default getParamsFromHash;