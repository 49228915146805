<template>
  <div>
    <Breadcrumbs main="Pages" title="Portal Changelog"/>

    <div class="container-fluid">
      <div class="faq-wrap">
        <div class="row">
          <div class="col-sm-12 col-md-12">
            <div class="card">
              <div class="card-body">
                <p class="mb-0">Welcome to our update center. This page is dedicated to providing you with
                  the
                  latest updates and changes made to our portal. Stay informed about new features,
                  enhancements,
                  and any modifications to ensure you are always up to date with the latest developments.
                </p>
              </div>
            </div>
          </div>

          <!-- begin -->
          <div class="col-sm-12 col-md-12">
            <div class="card">
              <div class="card-header bg-primary">
                <h5><i class="icofont icofont-id"></i>&nbsp;Version: 1.0.10</h5>
              </div>
              <div class="card-body">
                <p class="mb-0"><i class="icofont icofont-info-square"></i>: Updated rydpay API documentation
                </p>
                <br/>
                <ul class="list-group">
                  <li class="list-group-item">
                    Extended <code>GET /v4/order/{orderId}</code> API Response: <a href="/integration-guide/rydpay-apis/release-notes-and-updates">Release Notes and Updates</a>.
                  </li>
                </ul>
                <br/>

              </div>
              <div class="card-footer">
                <h6 class="mb-0"><i class="icofont icofont-ui-calendar"></i>: 2024-04-15</h6>
              </div>
            </div>
          </div>
          <!-- end -->

          <!-- begin -->
          <div class="col-sm-12 col-md-12">
            <div class="card">
              <div class="card-header bg-success">
                <h5><i class="icofont icofont-id"></i>&nbsp;Version: 1.0.9</h5>
              </div>
              <div class="card-body">
                <p class="mb-0"><i class="icofont icofont-info-square"></i>: Added "View Activity" for Specific User
                </p>
                <br/>
                <ul class="list-group">
                  <li class="list-group-item">
                    This feature is accessible to users with the <code>userManagementAdmin</code> role: <a href="/users/list">User List</a>.
                  </li>
                  <li class="list-group-item">
                    Administrators can now view detailed activity information for individual users within their company.
                  </li>
                  <li class="list-group-item">
                    What activity information is available is documented in the <code>Important Notes</code> section of this page:  <a href="/users/list">User List</a>.
                  </li>
                </ul>
                <br/>

              </div>
              <div class="card-footer">
                <h6 class="mb-0"><i class="icofont icofont-ui-calendar"></i>: 2024-04-11</h6>
              </div>
            </div>
          </div>
          <!-- end -->

          <!-- begin -->
          <div class="col-sm-12 col-md-12">
            <div class="card">
              <div class="card-header bg-primary">
                <h5><i class="icofont icofont-id"></i>&nbsp;Version: 1.0.8</h5>
              </div>
              <div class="card-body">
                <p class="mb-0"><i class="icofont icofont-info-square"></i>: Added 'Change Password' Feature for Users
                </p>
                <br/>
                <ul class="list-group">
                  <li class="list-group-item">
                    Users can now securely update their passwords within the portal, enhancing account management and security: <a href="/users/edit">User Edit</a>.
                  </li>
                </ul>
                <br/>

                <p class="mb-0"><i class="icofont icofont-info-square"></i>: Enhanced 'Password-Set' and 'Password-Reset' Email Communications
                </p>
                <br/>
                <ul class="list-group">
                  <li class="list-group-item">
                    Ryd has upgraded the email experience by implementing customized email templates and processes, moving away from the default Google Firebase emails. This update provides a more branded and user-friendly interaction for password setting and resetting.
                  </li>
                </ul>
                <br/>

              </div>
              <div class="card-footer">
                <h6 class="mb-0"><i class="icofont icofont-ui-calendar"></i>: 2024-01-26</h6>
              </div>
            </div>
          </div>
          <!-- end -->

          <!-- begin -->
          <div class="col-sm-12 col-md-12">
            <div class="card">
              <div class="card-header bg-success">
                <h5><i class="icofont icofont-id"></i>&nbsp;Version: 1.0.7</h5>
              </div>
              <div class="card-body">
                <p class="mb-0"><i class="icofont icofont-info-square"></i>: Added Swagger Document for Staging and Production
                </p>
                <br/>
                <ul class="list-group">
                  <li class="list-group-item">
                    Swagger document added for the staging environment including upcoming changes and for the production environment including the stable version. <a href="/integration-guide/rydpay-apis/api-reference#/production">ryd.pay API Reference.</a>.
                  </li>
                </ul>
                <br/>
                <p class="mb-0"><i class="icofont icofont-info-square"></i>: Added Release Notes & Updates for rydpay APIs
                </p>
                <br/>
                <ul class="list-group">
                  <li class="list-group-item">
                    API release note and update added due to the
                    Introduction of two new transitive order states: PAYMENT_AUTHORIZATION_STARTED and PRODUCT_ACQUISITION_STARTED.
                    <a href="/integration-guide/rydpay-apis/release-notes-and-updates">Release Notes & Updates</a>.
                  </li>
                </ul>
              </div>

              <div class="card-footer">
                <h6 class="mb-0"><i class="icofont icofont-ui-calendar"></i>: 2024-01-22</h6>
              </div>
            </div>
          </div>
          <!-- end -->


          <!-- begin -->
          <div class="col-sm-12 col-md-12">
            <div class="card">
              <div class="card-header bg-primary">
                <h5><i class="icofont icofont-id"></i>&nbsp;Version: 1.0.6</h5>
              </div>
              <div class="card-body">
                <p class="mb-0"><i class="icofont icofont-info-square"></i>: Added Section Webapp Integration
                </p>
                <br/>
                <ul class="list-group">
                  <li class="list-group-item">
                    Added top-up flow steps to <a href="/integration-guide/webapp-integration/topup-flow-steps">Webapp Integration</a>.
                  </li>
                </ul>
                <br/>
              </div>
              <div class="card-footer">
                <h6 class="mb-0"><i class="icofont icofont-ui-calendar"></i>: 2024-01-16</h6>
              </div>
            </div>
          </div>
          <!-- end -->

          <!-- begin -->
          <div class="col-sm-12 col-md-12">
            <div class="card">
              <div class="card-header bg-success">
                <h5><i class="icofont icofont-id"></i>&nbsp;Version: 1.0.5</h5>
              </div>
              <div class="card-body">
                <p class="mb-0"><i class="icofont icofont-info-square"></i>: Updated User Management Section
                </p>
                <br/>
                <ul class="list-group">
                  <li class="list-group-item">
                    Added a search function to the  <a href="/users/list">user list page</a>, which is only available to users with the userManagementAdmin role.
                  </li>
                </ul>
                <br/>
                <p class="mb-0"><i class="icofont icofont-info-square"></i>: New Dashboard: Order Insights & Analytics
                </p>
                <br/>
                <ul class="list-group">
                  <li class="list-group-item"><a href="/dashboard/order-insights-analytics">Order Insights & Analytics Dashboard</a> provides a wealth of information about orders and their states, presented through interactive and informative tabs..
                  </li>
                </ul>
              </div>
              <div class="card-footer">
                <h6 class="mb-0"><i class="icofont icofont-ui-calendar"></i>: 2024-01-02</h6>
              </div>
            </div>
          </div>
          <!-- end -->

          <!-- begin -->
          <div class="col-sm-12 col-md-12">
            <div class="card">
              <div class="card-header bg-primary">
                <h5><i class="icofont icofont-id"></i>&nbsp;Version: 1.0.4</h5>
              </div>
              <div class="card-body">
                <p class="mb-0"><i class="icofont icofont-info-square"></i>: Added Platform Status Section
                </p>
                <br/>
                <ul class="list-group">
                  <li class="list-group-item">Added a <a href="/platform-status/current-status">Platform Status</a> section
                    to check the latest operational status of ryd's platform, infrastructure, network, and partner gas stations. (still in progress.)
                  </li>
                </ul>
                <br/>
                <p class="mb-0"><i class="icofont icofont-info-square"></i>: New Feature: HTTP Request Error Rate Histogram
                </p>
                <br/>
                <ul class="list-group">
                  <li class="list-group-item"><a href="/dashboard/dashboard-request-stats">Platform Status</a> visualizes the error trends with our newly added histogram charting the error rates of HTTP requests.
                  </li>
                </ul>
              </div>
              <div class="card-footer">
                <h6 class="mb-0"><i class="icofont icofont-ui-calendar"></i>: 2023-12-17</h6>
              </div>
            </div>
          </div>
          <!-- end -->


          <!-- begin -->
          <div class="col-sm-12 col-md-12">
            <div class="card">
              <div class="card-header bg-success">
                <h5><i class="icofont icofont-id"></i>&nbsp;Version: 1.0.3</h5>
              </div>
              <div class="card-body">
                <p class="mb-0"><i class="icofont icofont-info-square"></i>: Updated troubleshooting section:
                </p>
                <br/>
                <ul class="list-group">
                  <li class="list-group-item">Added <a href="/troubleshooting/order-details">troubleshooting section</a>
                    to simplify its usage by employing only the date instead of date/time for the search time range.
                    Additionally, added support for the 'from', 'to', and 'orderUuid' parameters.
                  </li>
                  <li class="list-group-item">e.g.
                  <pre><code>https://partner-portal.rydcloud.de/troubleshooting/order-details?orderUuid=AddTheOrderUuIdCreatedByRyd&from=2023-12-07&to=2023-12-15</code></pre>
                  </li>
                </ul>
              </div>
              <div class="card-footer">
                <h6 class="mb-0"><i class="icofont icofont-ui-calendar"></i>: 2023-12-14</h6>
              </div>
            </div>
          </div>
          <!-- end -->

          <!-- begin -->
          <div class="col-sm-12 col-md-12">
            <div class="card">
              <div class="card-header bg-primary">
                <h5><i class="icofont icofont-id"></i>&nbsp;Version: 1.0.2</h5>
              </div>
              <div class="card-body">
                <p class="mb-0"><i class="icofont icofont-info-square"></i>: Added dashboard section
                </p>
                <br/>
                <ul class="list-group">
                  <li class="list-group-item">Added a <a href="/dashboard/general-dashboard">dashboard section</a> so
                    you can find some performance metrics for your account.
                  </li>
                </ul>
              </div>
              <div class="card-footer">
                <h6 class="mb-0"><i class="icofont icofont-ui-calendar"></i>: 2023-12-12</h6>
              </div>
            </div>
          </div>
          <!-- end -->

          <!-- begin -->
          <div class="col-sm-12 col-md-12">
            <div class="card">
              <div class="card-header bg-success">
                <h5><i class="icofont icofont-id"></i>&nbsp;Version: 1.0.1</h5>
              </div>
              <div class="card-body">
                <p class="mb-0"><i class="icofont icofont-info-square"></i>: Added troubleshooting section:
                </p>
                <br/>
                <ul class="list-group">
                  <li class="list-group-item">Added a <a href="/troubleshooting/order-details">troubleshooting
                    section</a> so you can find the most important logs for a given order uuid.
                  </li>
                </ul>
              </div>
              <div class="card-footer">
                <h6 class="mb-0"><i class="icofont icofont-ui-calendar"></i>: 2023-12-08</h6>
              </div>
            </div>
          </div>
          <!-- end -->

          <!-- begin -->
          <div class="col-sm-12 col-md-12">
            <div class="card">
              <div class="card-header bg-primary">
                <h5><i class="icofont icofont-id"></i>&nbsp;Version: 1.0.0</h5>
              </div>
              <div class="card-body">
                <p class="mb-0"><i class="icofont icofont-info-square"></i>: Initial release of ryd partner
                  portal:
                </p>
                <br/>
                <ul class="list-group">
                  <li class="list-group-item">Added use management: add a new user, edit an existing
                    user,
                    view a
                    list of users if the logged in user has the userManagementAdmin role.
                  </li>
                  <li class="list-group-item">Added documentation for ryd's topup APIs</li>
                  <li class="list-group-item">Added documentation for the integration approach:
                    deeplinking
                    registration
                  </li>
                </ul>
              </div>
              <div class="card-footer">
                <h6 class="mb-0"><i class="icofont icofont-ui-calendar"></i>: 2023-12-01</h6>
              </div>
            </div>
          </div>
          <!-- end -->

        </div>
      </div>
    </div>

  </div>
</template>
<script setup>
</script>