<template>
    <div class="col-md-12 project-list" v-if="hasUserManagementAdminRole">
        <div class="card">
            <div class="row">
                <div class="col-md-6 d-flex">
                    <div class="form-group mb-0 me-0"></div><router-link class="btn btn-primary"
                        to='/users/create'> <vue-feather class="me-1" type="plus-square"> </vue-feather>Create New
                        User</router-link>
                </div>
                <div class="col-md-6">
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {

    name: 'userListTopMenu',
    computed: {
        hasUserManagementAdminRole() {
            const userAddDataAsStr = localStorage.getItem('userAddData');
            const userAddData = userAddDataAsStr ? JSON.parse(userAddDataAsStr) : null;
            if (!userAddData || !userAddData.roles) {
                return false;
            }
            return userAddData.roles.includes("userManagementAdmin");
        },
    }

};

</script>