<template>
  <div class="search-links tab-pane fade" :class="{'show active': showtab === 'popEvents' }"
       id="all-links"
       role="tabpanel" aria-labelledby="popEvents">
    <!-- ... TABLE START... -->
    <div
        v-if="apiResponsePopEvents && apiResponsePopEvents.totalNumberOfResults && apiResponsePopEvents.totalNumberOfResults > 0">
      <div class="order-history table-responsive">
        <table class="table table-bordernone table-striped display" id="basic-4">
          <thead class="bg-primary text-white">
          <tr>
            <th class="bg-primary" scope="col">Timestamp</th>
            <th class="bg-primary" scope="col">Requested Action</th>
            <th class="bg-primary" scope="col">Level</th>
            <th class="bg-primary" scope="col">Message</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="message in paginatedMessages" :key="message._id">
            <td>{{ message[0] }}</td>
            <td>{{ message[1] }}</td>
            <td>{{ message[2] }}</td>
            <td>{{ message[3] }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <!-- ... TABLE END... -->

      <!-- Pagination Controls -->
      <nav aria-label="PopEvents list pagination" v-if="totalPages > 0" class="m-t-15">
        <ul class="pagination">
          <li class="page-item" :class="{ disabled: currentPage === 1 }">
            <button class="page-link" @click="prevPage">Previous</button>
          </li>
          <li class="page-item" v-for="n in totalPages" :key="n" :class="{ active: n === currentPage }">
            <button class="page-link" @click="goToPage(n)">{{ n }}</button>
          </li>
          <li class="page-item" :class="{ disabled: currentPage === totalPages }">
            <button class="page-link" @click="nextPage">Next</button>
          </li>
        </ul>
      </nav>

      <div v-if="this.apiResponsePopEvents.totalNumberOfResults > 0" class="total-results m-t-15 fw-bold">
        <p>Total results: {{ this.apiResponsePopEvents.totalNumberOfResults }}</p>
        <p v-if="this.apiResponsePopEvents.totalNumberOfResults === 1000"><b>Note</b>: A maximum of 1000 results are shown.</p>
      </div>

      <br/>

      <!-- BUTTON START-->

      <div class="row" v-if="loggedInUserIsRyd">
        <div class="col">
          <div
              v-if="apiResponsePopEvents && apiResponsePopEvents.graylogQueryUrl">
            <a :href="apiResponsePopEvents.graylogQueryUrl" target="_blank"
               class="btn btn-pill btn-success btn-lg" title="Go To Graylog Query">
              Go To Graylog Query
            </a>
          </div>
        </div>
      </div>

      <!-- BUTTON END-->


    </div>
    <!-- BUTTON END-->

    <div v-else>
      <!-- Loading Indicator START -->
      <div v-if="isLoadingPopEvents" class="loading-indicator">
        <h6 class="sub-title mb-0 text-center">Loading ... POP Events</h6>
        <div class="loader-box">
          <div class="loader-11"></div>
        </div>
      </div>
      <!-- Loading Indicator END -->

      <div class="text-center fw-bold fs-6 font-danger"
           v-if="apiResponsePopEvents && apiResponsePopEvents.errorMessage">
        {{ this.apiResponsePopEvents.errorMessage }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TabPopEvents',
  data() {
    return {
      currentPage: 1,
      pageSize: 50
    };
  },
  props: {
    apiResponsePopEvents: {},
    showtab: {},
    isLoadingPopEvents: {},
    loggedInUserIsRyd: {}
  },
  computed: {
    paginatedMessages() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.apiResponsePopEvents.messages.slice(start, end);
    },
    totalPages() {
      const totalPages = Math.ceil(this.apiResponsePopEvents.totalNumberOfResults / this.pageSize);
      console.log("PopEvents totalPages", totalPages);
      return totalPages;
    }
  },
  methods: {
    goToPage(page) {
      this.currentPage = page;
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    }
  },
}
</script>