<template>
  <!-- Full Page Loading Overlay -->
  <div v-if="isLoading">
    <h6 class="sub-title mb-0 text-center">Loading ...</h6>
    <div class="loader-box">
      <div class="loader-19"></div>
    </div>
  </div>

  <div class="col-sm-12" v-if="!isLoading">
    <div class="card">
      <div class="card-header bg-primary">
        <h5>Create and Invite a User</h5>
      </div>
      <div class="card-body">
        <div class="form theme-form">

          <form @submit.prevent="createUser">

            <div class="row fw-bolder f-w-900">
              <p v-if="error" class="error-message m-b-25" >{{ error }}</p>
            </div>


            <div class="row border-bottom m-b-20">
              <div class="col">
                <div class="mb-3">
                  <label for="firstName" class="fw-bold">FirstName *</label>
                  <input class="form-control border border-primary" type="text" id="firstName"
                         v-model="newUser.firstName" required placeholder="the new user's firstname">
                </div>
              </div>
            </div>

            <div class="row border-bottom m-b-20 m-t-20">
              <div class="col">
                <div class="mb-3">
                  <label for="lastName" class="fw-bold">Lastname *</label>
                  <input class="form-control border border-primary" type="text" id="lastName"
                         v-model="newUser.lastName" required placeholder="the new user's lastname">
                </div>
              </div>
            </div>

            <div class="row border-bottom m-b-20 m-t-20">
              <div class="col">
                <div class="mb-3">
                  <label for="email" class="fw-bold">Email *</label>
                  <input class="form-control border border-primary" type="text" id="email"
                         v-model="newUser.email" required
                         placeholder="the new user's email address that is used for sign in">
                </div>
              </div>
            </div>

            <div class="row border-bottom m-b-20 m-t-20" v-if="newUser.loggedInUserGroup === 'ryd'">
              <div class="col">
                <div class="mb-3">
                  <label for="userGroup" class="fw-bold">User Group *</label>
                  <select class="form-control border border-primary" id="userGroup"
                          v-model="newUser.userGroup" required>
                      <option v-for="partnerName in partnerNames" :key="partnerName" :value="partnerName">
                        {{ partnerName }}
                      </option>
                  </select>
                </div>
              </div>
            </div>

            <div class="row border-bottom m-b-20 m-t-20">
              <div class="col">
                <div class="mb-3">
                  <label for="roles" class="fw-bold">Roles *</label>
                  <div v-for="(role, index) in availableRoles" :key="index">
                    <div class="checkbox checkbox-primary">
                      <input :id="`checkbox-primary-${index}`" type="checkbox" :value="role.name"
                             :checked="selectedRoles.includes(role.name)" @change="toggleRoleSelection(role.name)">
                      <label :for="`checkbox-primary-${index}`" class="fw-bold">{{ role.name }}</label>
                      <p class="checkbox-item-description">{{ role.description }}</p> <!-- Description -->
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div class="row ">
              <div class="col">
                <button class="btn btn-pill btn-success btn-air-success active btn-lg" type="submit"
                        title="Create a new user">
                  <i class="fa fa-send m-r-15"></i>Create
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>


  <div class="col-sm-12 col-md-12">
    <div class="card card-absolute">
      <div class="card-header bg-info">
        <h5 class="text-white">Important Note</h5>
      </div>
      <div class="card-body">

        <ul class="list-group">
          <li class="list-group-item">
            <i class="icofont icofont-hand-right font-primary">&nbsp;</i>After the new user's data has been successfully
            validated and their account created, an email will be sent to the email address provided during
            registration.
          </li>
          <li class="list-group-item"><i class="icofont icofont-hand-right font-primary">&nbsp;</i>This email will
            contain instructions enabling the user to set up a password, thereby completing the
            registration process.
          </li>
          <li class="list-group-item"><i class="icofont icofont-hand-right font-primary">&nbsp;</i>The password set link
            provided in the email will expire after 24 hours. However,
            you have the option to re-initiate the password reset process for an existing account as many times as
            needed by visiting the <a href="/users/list">users list</a> page.
          </li>
          <li class="list-group-item"><i class="icofont icofont-hand-right font-primary">&nbsp;</i>If the new user does
            not find the email in their inbox, please advise them to check their spam or junk email folder.
          </li>
        </ul>

      </div>
    </div>
  </div>
</template>

<script>
import {auth, db} from '@/firebase/firebaseInit';
import {doc, getDoc} from 'firebase/firestore';
import axios from 'axios';
import {sendPasswordResetEmail} from 'firebase/auth';
import {ROLE_DESCRIPTIONS, RPP_API_SERVER_URL, RPP_USERS_FIRESTORE_COLLECTION_NAME} from "@/constants/constants";
import {getUserAddDataFromLocalStorage} from "@/utils/userUtils";
import {fetchPartnerNames} from "@/utils/partnerNamesUtils";


export default {
  name: 'CreateUser',
  data() {
    return {
      newUser: {
        firstName: '',
        lastName: '',
        email: '',
        userGroup: '',
        roles: [],
        availableRoles: [],
        loggedInUserGroup: ''
      },
      error: null, // To handle and show any error messages
      isLoading: false,
      creatorId: null,
      availableRoles: [],
      selectedRoles: [], // Array to store selected roles
      partnerNames: [],
      isUserGroupRyd: false
    };
  },
  mounted() {
    this.initializeForm();
  },
  watch: {
    loggedInUserGroup(newVal) {
      this.newUser.loggedInUserGroup = newVal;
    },
    //availableRoles(newVal) {
    //  this.newUser.roles = [...newVal];
   // }
  },
  methods: {
    createUser() {
      // Clear previous errors
      this.error = null;

      // Retrieve the API key from environment variables
      const apiKey = process.env.VUE_APP_RPP_USERS_CLOUD_FUNCTION_API_KEY;

      // Request to your backend to create the user
      axios.post(RPP_API_SERVER_URL, {
        email: this.newUser.email,
        //password: this.generateRandomPassword,
        firstName: this.newUser.firstName,
        lastName: this.newUser.lastName,
        userGroup: this.newUser.userGroup,
        roles: this.selectedRoles,
        creatorId: this.creatorId,
        // other data...
      }, {
        headers: {
          'x-txn-auth-token': apiKey
        }
      })
          .then(() => {
            //sendPasswordResetEmail(auth, this.newUser.email);
            this.$router.push('/users/list'); // Redirect after successful creation
          })
          .catch((error) => {
            // Handle errors
            //console.error("333", error);

            // Initialize a default error message
            let errorMessage = 'An error occurred during user creation.';

            // Check if the error response exists and has the expected format
            if (error.response && error.response.data && error.response.data.errorDetails) {
              // Extract the error message from the response
              errorMessage = error.response.data.errorDetails || errorMessage;
            } else if (error.response && error.response.data && error.response.data.errorMessage) {
              // Extract the error message from the response
              errorMessage = error.response.data.errorMessage || errorMessage;
            } else {
              // Something happened in setting up the request that triggered an Error
              //console.log('Error Message:', error.message);
              errorMessage = error.message;
            }

            // Update the local state with the extracted error message
            this.error = errorMessage;
          });
    },
    initializeForm() {
      this.isLoading = true;

      const { uid, isUserGroupRyd } = getUserAddDataFromLocalStorage();
      this.creatorId = uid;
      this.isUserGroupRyd = isUserGroupRyd;

      const userDocRef = doc(db, RPP_USERS_FIRESTORE_COLLECTION_NAME, uid);

      getDoc(userDocRef)
          .then(userDocSnap => {
            //console.log("get userDocRef for uid ", uid);
            if (userDocSnap.exists()) {
              const userDoc = userDocSnap.data();
              //console.log("userDoc7777", userDoc);

              this.newUser.loggedInUserGroup = userDoc.userGroup;
              this.newUser.userGroup = userDoc.userGroup;

              this.availableRoles = userDoc.roles.map(roleName => {
                return {
                  name: roleName,
                  description: ROLE_DESCRIPTIONS[roleName] || 'No description available'
                };
              });

              if(isUserGroupRyd){
                this.loadPartnerNames();
              }


            } else {
              console.log("No such document!");
            }
          })
          .catch(error => {
            console.error("Error getting document:", error);
          })
          .finally(() => {
            this.isLoading = false; // Stop loading after data is fetched
          });

    },
    generateRandomPassword(length = 8) {
      const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+~`|}{[]:;?><,./-=';
      let password = '';
      for (let i = 0; i < length; i++) {
        password += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return password;
    },
    toggleRoleSelection(role) {
      const index = this.selectedRoles.indexOf(role);
      if (index === -1) {
        this.selectedRoles.push(role);
      } else {
        this.selectedRoles.splice(index, 1);
      }
    },
    async loadPartnerNames() {
      try {
        this.partnerNames = await fetchPartnerNames(this.creatorId);
      } catch (error) {
        console.log("error", error);
      }
    }
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/components/checkbox';
</style>